import { Col, Form, RadioChangeEvent, Row } from "antd"
import { useEffect, useRef, useState } from "react"
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { groupBuyAPi } from "src/api/groupBuyApi"
// import OrderInput from "src/components/Input/OrderInput"
// import TransactionSucccess from "src/components/Modal/PaymentModal/TransactionSuccess"
import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import Selection from "src/components/Selection"
import { useAuth } from "src/customHook/useAuth"
import useIsMobile from "src/customHook/useIsMobile"
import { Voucher } from "src/models"
import { MoneyConvert } from "src/util/format"
import { sha256Hash } from "src/util/sha256Hash"
import CreatePayInicis from "./CreatePayInicis"
// import Inicis from "./Inicis"
import { useAppDispatch } from "src/app/hooks"
import EvenEnd from "src/components/Modal/PaymentModal/EventEnd"
import MaximumPurchaseLimit from "src/components/Modal/PaymentModal/MaximumPurchaseLimit"
import MaxQtyModal from "src/components/Modal/PaymentModal/MaxQtyModal"
import TransactionSucccess from "src/components/Modal/PaymentModal/TransactionSuccess"
import { closeLoading, openLoading } from "src/features/loading/loadingSlice"

import GiftDetail from "@assets/images/group_sale/gift-detail.png"

import CSInput, { default as Input } from "src/components/Input/Index"
import ModalInfoTransfer from "src/components/ModalInfoTransfer"
import { formatPrice } from "src/util/formatPrice"
import { UrlInternal } from "src/constant/endpointRoute"
import ErrorPayment from "src/components/Modal/PaymentModal/ErrorPayment"

const testURL = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js"
const releaseURL = "https://stdpay.inicis.com/stdjs/INIStdPay.js"

function OrderSummary() {
  const dispatch = useAppDispatch()
  const mobilePurchaseRef: any = useRef()
  const { isMobile } = useIsMobile(768)

  const { state } = useLocation()
  const { user } = useAuth()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(state?.quantity || 1)
  const [voucherDetail, setVoucherDetail] = useState<Voucher>()
  const [paramsInicis, setParamsInicis] = useState<any>()
  const [openEvenEnd, setOpenEvenEnd] = useState<boolean>(false)
  const [openMaximumPurchaseLimit, setOpenMaximumPurchaseLimit] =
    useState<boolean>(false)
  const [openMaxQtyModal, setOpenMaxQtyModal] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [value, setValue] = useState<any>("")
  const [paymentType, setPaymentType] = useState("CREDIT")
  // const [loading, setLoading] = useState<boolean>(false)
  const [selectionData, setSelectionData] = useState({
    seenTerms: false,
    seenInfo: false,
    seenThird: false,
    // seenVIPmember: true,
  })
  console.log("quantity", quantity)
  const ref = useRef<any>()
  const navigate = useNavigate()
  const { id } = useParams()

  const [nameGift, setNameGift] = useState<any>()
  const [phoneGift, setPhoneGift] = useState<any>()

  const typeParams = searchParams.get("type")
  const nameGiftParams = searchParams.get("nameGift")
  const phoneGiftParams = searchParams.get("phoneGift")

  console.log("phoneGiftParams", phoneGiftParams)

  const IS_GIFT_PARAMS = typeParams === "gift"
  const [form] = Form.useForm()

  //transfer payment
  const [isModalInfoTransfer, setIsModalInfoTransfer] = useState(false)
  const [infoTransfer, setInfoTransfer] = useState({
    name: "",
    // bankName: "",
    // stk: "",
  })

  // console.log("id", id)

  useEffect(() => {
    if (phoneGiftParams || nameGiftParams) {
      setNameGift(nameGiftParams)
      setPhoneGift(phoneGiftParams)
      form.setFieldsValue({
        displayName: nameGiftParams,
        phone: phoneGiftParams,
      })
    }
  }, [nameGiftParams, phoneGiftParams])

  const handlePaymentFreeMoney = async () => {
    try {
      const resp: any = await groupBuyAPi.paymentFreeMoneyApi({
        amount: Number(voucherDetail?.voucherPrice) * quantity,
        gpId: Number(id),
        quantity: quantity,
      })

      if (resp?.result) {
        const dataResult = resp.data
        console.log("sgweg", resp.data)
        setSearchParams((params) => {
          params.set("brandName", dataResult.brandName)
          params.set("endTime", dataResult.endTime)
          params.set("expiredTime", dataResult.expiredTime)
          params.set("orderId", dataResult.id)
          params.set("status", "SUCCESS")
          return params
        })
        // navigate(
        //   UrlInternal.MYPAGE_ORDER_DETAIL + `/${resp.data.id}?type=order`,
        // )
      }
    } catch (error) {}
  }

  const onFinish = () => {
    if (Number(voucherDetail?.voucherPrice) === 0) {
      handlePaymentFreeMoney()
    } else {
      if (IS_GIFT_PARAMS) {
        setSearchParams((params) => {
          params.set("nameGift", nameGift)
          params.set("phoneGift", phoneGift)
          return params
        })
      }

      // setLoading(true)

      if (voucherDetail?.isBanking) {
        setIsModalInfoTransfer(true)
      } else {
        dispatch(openLoading())
        switch (paymentType) {
          case "CREDIT":
            // handlePaymentCreditCard2()
            handlePaymentTossPay()
            break
          case "KAKAO":
            handlePaymentKakao()
            break
          case "NAVER":
            handlePaymentNaver()
            break

          default:
            break
        }
      }
    }
  }

  const getVoucherDetail = async () => {
    const res: any = await groupBuyAPi.getVoucherDetail(id as string)
    if (!res.result) {
      navigate(-1)
    } else {
      setVoucherDetail(res.data)
    }
  }

  useEffect(() => {
    if (searchParams.get("errorCode") === "NOT_ENOUGH_VOUCHER") {
      setOpenMaxQtyModal(true)
      return
    }

    if (searchParams.get("errorCode") === "ENDED_PURCHASE") {
      setOpenEvenEnd(true)
      return
    }
    if (
      searchParams.get("errorCode") === "OUT_OF_PURCHASE" ||
      searchParams.get("errorCode") === "MORE_THAN_SPECIFIED_QUANTITY"
    ) {
      setOpenMaximumPurchaseLimit(true)
      return
    }
  }, [searchParams])

  useEffect(() => {
    getVoucherDetail()
  }, [id])

  useEffect(() => {
    async function fetchParams() {
      const mid = "SIRmoafrnd"
      const signKey = "dWd6UGNvVmoyVjA1NG1INUZZSTdGQT09"
      const oid = `${id}_${quantity}_${Date.now().toString(36)}`
      const price = Number(voucherDetail?.voucherPrice) * quantity

      let hashSignature = await sha256Hash(
        `oid=${oid}&price=${price}&timestamp=${Date.now()}`,
      )
      let hashVerification = await sha256Hash(
        `oid=${oid}&price=${price}&signKey=${signKey}&timestamp=${Date.now()}`,
      )
      let hashMkey = await sha256Hash(signKey)

      const pgRequestParameters = {
        mid: mid,
        price: price,
        oid: oid,
        goodsname: voucherDetail?.voucherName,
        currency: "WON",
        buyername: user?.displayName,
        buyertel: user?.phoneNumber,
        buyeremail: user?.email,
        timestamp: Date.now(),
        signature: hashSignature,
        verification: hashVerification,
        mkey: hashMkey,
        returnUrl: "http://127.0.0.1:4000/group-buying/order/195",
        closeUrl: "http://127.0.0.1:4000/group-buying/order/195",
        gopaymethod: "Card:DirectBank:VBank:HPP",
      }
      setParamsInicis(pgRequestParameters)
      console.log("pgRequestParameters", pgRequestParameters)
    }

    if (id && quantity && voucherDetail && user) {
      fetchParams()
    }
  }, [id, quantity, voucherDetail, user])

  const onChange = (e: RadioChangeEvent) => {
    // setValue(e.target.value)
  }

  const handlePaymentKakao = async () => {
    const data: any = {
      accessToken: user?.accessToken,
      orderId: voucherDetail?.id,
      quantity: quantity,
      itemName: voucherDetail?.voucherName,
      totalAmount: 1,
      type: IS_GIFT_PARAMS ? "GIFT" : null,
      phoneNumber: IS_GIFT_PARAMS ? phoneGift : null,
    }

    console.log("data", data)

    try {
      const resp: any = await groupBuyAPi.paymentRequest(data)
      console.log("paymentRequest", resp)
      if (resp?.error?.errorCode === "ENDED_PURCHASE") {
        setOpenEvenEnd(true)
        return
      }
      if (
        resp?.error?.errorCode === "OUT_OF_PURCHASE" ||
        resp?.error?.errorCode === "MORE_THAN_SPECIFIED_QUANTITY"
      ) {
        setOpenMaximumPurchaseLimit(true)
        return
      }
      if (resp?.error?.errorCode === "NOT_ENOUGH_VOUCHER") {
        setOpenMaxQtyModal(true)
        return
      }
    } catch (error) {
      ref.current && ref.current.submit()
      console.error("Error during payment request:", error)
    }

    // setLoading(false)
    dispatch(closeLoading())

    // setOpenMaxQtyModal(true)
  }

  const handlePaymentNaver = async () => {
    // setLoading(false)
    dispatch(closeLoading())
  }

  const handleOnSelectChange = (data: Record<string, boolean>) => {
    setSelectionData((prev) => ({ ...prev, ...data }))
  }

  // const handlePaymentCreditCard2 = () => {
  //   let IMP: any = (window as { [key: string]: any })["IMP"] as string
  //   const oid = `${id}_${quantity}_${new Date().getTime()}`

  //   IMP.init("imp52068501")
  //   //결제시 전달되는 정보
  //   IMP.request_pay(
  //     {
  //       pg: "html5_inicis",
  //       pay_method: "card",
  //       merchant_uid: oid,
  //       name: paramsInicis.goodsname /*상품명*/,
  //       amount: paramsInicis.price,
  //       buyer_email: paramsInicis.buyeremail /*구매자 이메일*/,
  //       buyer_name: paramsInicis.buyername,
  //       buyer_tel: paramsInicis.buyertel /*구매자 연락처*/,
  //       buyer_addr: "서울특별시 강남구 삼성동" /*구매자 주소*/,
  //       buyer_postcode: "123-456" /*구매자 우편번호*/,
  //       m_redirect_url: paramsInicis.returnUrl,
  //     },
  //     async function (rsp: any) {
  //       // setLoading(false)
  //       dispatch(closeLoading())

  //       var result = ""
  //       console.log("respon", rsp)

  //       if (rsp.success) {
  //         var msg = "결제가 완료되었습니다."
  //         msg += "고유ID : " + rsp.imp_uid
  //         msg += "상점 거래ID : " + rsp.merchant_uid
  //         msg += "결제 금액 : " + rsp.paid_amount
  //         msg += "카드 승인번호 : " + rsp.apply_num
  //         result = "0"
  //       } else {
  //         var msg = "결제에 실패하였습니다."
  //         msg += "에러내용 : " + rsp.error_msg
  //         result = "1"
  //       }
  //       if (result == "0") {
  //         const resInicis: any = await groupBuyAPi.paymentInicisApprove({
  //           merchant_uid: oid,
  //           buyer_email: paramsInicis.buyeremail,
  //         })

  //         if (resInicis?.result) {
  //           console.log("payment success!!!")
  //         }
  //       }
  //       alert(msg)
  //     },
  //   )
  // }

  const handlePaymentTossPay = () => {
    navigate("/tosspay", {
      state: {
        quantity: quantity,
        gpId: voucherDetail?.id,
        price: Number(voucherDetail?.voucherPrice) * quantity || 0,
        receiverName: nameGift,
        phoneNumber: phoneGift,
        orderName: `${voucherDetail?.brandName}-프리미엄 멤버십: ${voucherDetail?.voucherName}`,
        // orderName: voucherDetail?.brandName + "-" + voucherDetail?.voucherName,
      },
    })
    dispatch(closeLoading())
  }

  const handlePaymentBanking = async () => {
    try {
      const resp: any = await groupBuyAPi.paymentBankingApi({
        amount: Number(voucherDetail?.voucherPrice) * quantity,
        bankAccountName: infoTransfer.name,
        // bankAccountNumber: infoTransfer.stk,
        // bankName: infoTransfer.bankName,
        id: Number(id),
        quantity: quantity,
      })

      if (resp?.result) {
        setIsModalInfoTransfer(false)
        navigate(
          UrlInternal.MYPAGE_ORDER_DETAIL + `/${resp.data.id}?type=order`,
        )
      }
    } catch (error) {
      setIsModalInfoTransfer(false)
    }
  }

  const checkDisabledBtnSubmit = () => {
    if (voucherDetail?.isBanking) {
      return (
        !selectionData?.seenTerms ||
        !selectionData?.seenInfo ||
        !selectionData?.seenThird ||
        !infoTransfer.name
        // ||
        // !infoTransfer.bankName ||
        // !infoTransfer.stk
      )
    } else if (!IS_GIFT_PARAMS) {
      return (
        !selectionData?.seenTerms ||
        !selectionData?.seenInfo ||
        !selectionData?.seenThird
      )
    } else {
      return (
        !selectionData?.seenTerms ||
        !selectionData?.seenInfo ||
        !selectionData?.seenThird ||
        !nameGift ||
        !phoneGift
      )
    }
  }

  return (
    <>
      <ErrorPayment />
      <TransactionSucccess />

      <ModalInfoTransfer
        isOpen={isModalInfoTransfer}
        handleClose={() => setIsModalInfoTransfer(false)}
        data={voucherDetail}
        price={Number(voucherDetail?.voucherPrice) * quantity}
        onSubmit={handlePaymentBanking}
      />

      <div className="md:px-0 md:mt-[24px] relative z-10">
        <Row gutter={[59, 40]}>
          <Col flex={1}>
            {IS_GIFT_PARAMS && (
              <div className="pb-[16px] border-b md:border-[#D0D0D0] border-primary">
                <div className="text-[18px] font-pretendard700 mb-[19px] xs:mt-[24px] mt-0">
                  선물 받는 분 정보
                </div>
                <div className="border border-dashed border-gray5 py-[16px] md:flex items-center justify-center gap-[13px] hidden">
                  <div>
                    <img src={GiftDetail} alt="img" />
                  </div>
                  <div>
                    <div className="font-pretendard600">
                      모아프렌즈의 선물 메시지가 카카오톡과 문자메시지로 발송될
                      예정입니다.
                    </div>
                    <div>
                      발송 후 회수가 불가능 하오니, 정확하게 기입해 주세요
                    </div>
                  </div>
                </div>
                <div className="border border-dashed border-gray5 py-[17px] px-[35px] md:hidden">
                  <div className="flex items-center justify-center gap-[13px]">
                    <div>
                      <img src={GiftDetail} alt="img" />
                    </div>
                    <div>
                      <div className="font-pretendard600 text-[12px]">
                        모아프렌즈의 선물 메시지가 <br />
                        카카오톡과 문자메시지로 발송될 예정입니다.
                      </div>
                    </div>
                  </div>
                  <div className="xs:text-center mt-[6px] text-[12px] font-pretendard400">
                    발송 후 회수가 불가능 하오니, 정확하게 기입해 주세요
                  </div>
                </div>

                <div className="max-w-[450px] mt-[19px]">
                  <Form
                    form={form}
                    // onFinish={handleSubmitLogin}
                    layout="vertical"
                    requiredMark={false}
                    initialValues={{
                      isRemember: false,
                    }}
                    className="form-gp"
                  >
                    <Input
                      name="displayName"
                      placeholder={t("선물 받는 분의 실명을 입력해주세요.")}
                      label={t("성함")}
                      rules={[
                        { required: true, message: t("Please type name!") },
                      ]}
                      inputHeight={48}
                      className="input-no-boxshadow"
                      onChange={(e) => {
                        setNameGift(e.target.value)
                      }}
                      value={nameGift}
                    />
                    <Input
                      name="phone"
                      placeholder={t("하이픈(-), 띄어쓰기 없이 입력해주세요.")}
                      label={t("연락처")}
                      rules={[
                        {
                          required: true,
                          message: t("Please type phone number!"),
                        },
                      ]}
                      inputHeight={48}
                      className="input-no-boxshadow [&::-webkit-inner-spin-button]:appearance-none"
                      onChange={(e) => setPhoneGift(e.target.value)}
                      type="text"
                      value={phoneGift}
                      onInput={(e: React.FormEvent<HTMLInputElement>) => {
                        const inputValue = e.currentTarget.value
                        const filteredValue = inputValue.replace(/[^0-9]/g, "") // Loại bỏ ký tự không phải số
                        if (filteredValue !== inputValue) {
                          e.currentTarget.value = filteredValue // Chỉ cập nhật nếu có thay đổi
                        }
                      }}
                    />
                  </Form>
                </div>
              </div>
            )}

            <p className="text-[18px] font-pretendard700 text-primary mt-[39px] mb-[32px] leading-none">
              {t("구매 정보")}
            </p>
            <div className="mb-[40px] md:mb-[32px] border-b-solid border-primary border-b-2 md:border-b md:border-gray5 flex flex-row-reverse md:flex-row justify-between	md:justify-normal">
              <div className="mr-[0] md:mr-[18px] mb-[45px]">
                <div className="border border-solid w-[64px] h-[64px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700">
                  <img
                    className="w-[64px] h-[64px] md:w-[120px] md:h-[120px] object-cover aspect-square"
                    src={voucherDetail?.voucherImage}
                    alt=""
                  />
                </div>
              </div>
              <div className="block">
                <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none mb-[8px] md:mb-[16px]">
                  {voucherDetail?.brandName}
                </p>
                <p className="text-[16px] md:text-[18px] font-pretendard700 text-primary mb-[12px] md:mb-[32px] leading-none">
                  {voucherDetail?.voucherName}
                </p>
                <div className="line-through text-[#767676] text-[13px] pb-[8px] mt-0 md:mt-[-20px]">
                  {formatPrice(Number(voucherDetail?.rootPrice))}원
                </div>
                <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                  <span className="text-primaryRed">
                    [{voucherDetail?.discountPercentage}%]{" "}
                  </span>
                  <span className="font-pretendard700">
                    {MoneyConvert(Number(voucherDetail?.voucherPrice)) || 0}
                    {t("원")}{" "}
                  </span>
                  / 수량 {MoneyConvert(quantity || 0)}개
                </span>
              </div>
            </div>
            {/* <div className="border-b-solid border-primary border-b-2 md:border-b md:border-gray5 pb-[40px]">
              <div className="flex justify-between items-baseline mb-[20px] md:mb-[32px]">
                <p className="text-[18px] font-pretendard700 text-primary leading-none">
                  {t("쿠폰 사용")}
                </p>
                <p className="text-[12px] font-pretendard400 text-primary leading-none">
                  {t("쿠폰 0장 적용")}
                </p>
              </div>

              <Radio.Group onChange={onChange} value={value} className="w-full">
                <div className="mb-[24px] flex items-baseline">
                  <Radio value={1} disabled />
                  <p className="text-primary text-[16px] font-pretendard700 w-full leading-none">
                    30,000{t("원 할인")}
                    <p className="flex flex-col md:flex-row justify-between text-primary text-[12px] md:text-[14px] font-pretendard400 leading-none mt-[12px]">
                      <span className="mb-[8px]">
                        신규회원 5% 할인 쿠폰 최대 3만원 할인
                      </span>
                      <span>2023년 12월 31일까지 | 1장</span>
                    </p>
                  </p>
                </div>

                <div className="items-baseline">
                  <Radio value={""} />
                  <span className="text-primary text-[16px] font-pretendard400 leading-none">
                    {t("적용 안 함")}
                  </span>
                </div>
              </Radio.Group>
            </div> */}
            {Number(voucherDetail?.voucherPrice) > 0 && (
              <div className="pb-[60px] md:pb-0 border-b-solid border-primary border-b-2 md:border-none md:border-gray5">
                <div className="flex mb-[22px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("결제 방법")}
                  </p>
                </div>
                {!voucherDetail?.isBanking && (
                  <div className="flex gap-[20px]">
                    <Button
                      theme="main"
                      variant={paymentType === "CREDIT" ? "primary" : "outline"}
                      labelSize={14}
                      height={44}
                      onClick={() => {
                        setPaymentType("CREDIT")
                      }}
                    >
                      {t("신용/체크카드")}
                    </Button>
                    {/* <Button
                  variant={paymentType === "NAVER" ? "primary" : "outline"}
                  theme="main"
                  labelSize={14}
                  height={44}
                  onClick={() => {
                    setPaymentType("NAVER")
                  }}
                >
                  {t("네이버페이")}
                </Button> */}
                    {/* <Button
                    variant={paymentType === "KAKAO" ? "primary" : "outline"}
                    theme="main"
                    labelSize={14}
                    height={44}
                    onClick={() => {
                      setPaymentType("KAKAO")
                    }}
                  >
                    {t("카카오페이")}
                  </Button> */}
                  </div>
                )}
                {voucherDetail?.isBanking && (
                  <>
                    <div className="pb-[30px]">
                      <Button
                        theme="main"
                        variant={"primary"}
                        labelSize={14}
                        height={44}
                        onClick={() => {
                          // setPaymentType("CREDIT")
                        }}
                      >
                        {t("계좌이체")}
                      </Button>
                    </div>

                    <div className="flex flex-col gap-[20px]">
                      <div className="flex md:items-center md:gap-[27px] gap-[12px] md:flex-row flex-col">
                        <div className="w-[64px] font-pretendard600 ">
                          입금자명<span className="text-[#C70039]">*</span>
                        </div>
                        <CSInput
                          name="namePayment"
                          placeholder="Fill in content here ..."
                          rules={[{ required: true }]}
                          inputHeight={48}
                          className="mb-0 flex-1"
                          onChange={(e) =>
                            setInfoTransfer({
                              ...infoTransfer,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                      {/* <div className="flex md:items-center md:gap-[27px] gap-[12px] md:flex-row flex-col">
                      <div className="w-[64px] font-pretendard600 ">
                        은행명<span className="text-[#C70039]">*</span>
                      </div>
                      <CSInput
                        name="bankPayment"
                        placeholder="Fill in content here ..."
                        rules={[{ required: true }]}
                        inputHeight={48}
                        className="mb-0 flex-1"
                        onChange={(e) =>
                          setInfoTransfer({
                            ...infoTransfer,
                            bankName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="flex md:items-center md:gap-[27px] gap-[12px] md:flex-row flex-col">
                      <div className="w-[64px] font-pretendard600 ">
                        계좌번호<span className="text-[#C70039]">*</span>
                      </div>
                      <CSInput
                        name="stkPayment"
                        placeholder="Fill in content here ..."
                        rules={[{ required: true }]}
                        inputHeight={48}
                        className="mb-0 flex-1"
                        type="text"
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          const inputValue = e.currentTarget.value
                          const filteredValue = inputValue.replace(
                            /[^0-9]/g,
                            "",
                          ) // Loại bỏ ký tự không phải số
                          if (filteredValue !== inputValue) {
                            e.currentTarget.value = filteredValue // Chỉ cập nhật nếu có thay đổi
                          }
                        }}
                        onChange={(e) =>
                          setInfoTransfer({
                            ...infoTransfer,
                            stk: e.target.value,
                          })
                        }
                      />
                    </div> */}
                    </div>
                  </>
                )}
              </div>
            )}
          </Col>
          <Col flex={isMobile ? 1 : "530px"}>
            <div className="w-full md:bg-gray3 md:p-[40px] md:pt-[32px]">
              <div className="flex items-center justify-between border-b border-b-solid md:border-none pb-[32px] md:pb-[0px] md:mb-[60px]">
                <p className="text-[18px] font-pretendard700 text-primary leading-none">
                  {t("결제 금액")}
                </p>

                {/* <p className="block md:hidden text-[20px] font-pretendard700 text-primary leading-none">
                  {MoneyConvert(
                    Number(voucherDetail?.voucherPrice || 0) * quantity,
                  )}
                  {t("원")}
                </p> */}
              </div>

              <div className="flex items-center mb-[8px] md:mb-[24px] justify-between mt-[24px] md:mt-[40px]">
                <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                  {t("총 상품 금액")}
                </p>
                <p className="text-[12px] md:text-[14px] font-pretendard700 text-primary leading-none">
                  {MoneyConvert(Number(voucherDetail?.rootPrice) * quantity) ||
                    0}
                  {t("원")}
                </p>
              </div>

              <div className="flex items-center pb-[16px] md:pb-[24px] justify-between border-b border-b-solid md:border-none">
                <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                  {t("할인율")}
                </p>
                <p className="text-[12px] md:text-[14px] font-pretendard700 text-primary leading-none">
                  {formatPrice(Number(voucherDetail?.discountPercentage))}
                  {t("%")}
                </p>
              </div>

              <div className="flex items-center justify-between mt-[15px] md:mt-[0] pb-[40px] border-b border-b-2 border-b-solid border-primary">
                <p className="text-[16px] font-pretendard700 text-primary leading-none">
                  {t("총 결제 금액")}
                </p>
                <p className="text-[16px] md:text-[24px] font-pretendard700 text-primary leading-none">
                  {MoneyConvert(
                    Number(voucherDetail?.voucherPrice) * quantity,
                  ) || 0}
                  {t("원")}
                </p>
              </div>

              <div className="mt-[24px] md:mt-[38px]">
                <div className="mb-[15px] pb-[19px] md:pb-[0] border-b border-b-solid md:border-none">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange({
                        seenTerms: data.already,
                        seenInfo: data.already,
                        seenThird: data.already,
                      })
                    }}
                    selectOption={[
                      {
                        label: t(
                          "주문 내용을 확인 했으며, 아래 내용에 모두 동의합니다.",
                        ),
                        name: "already",
                        defaultChecked: Object.values(selectionData).every(
                          (item) => !!item,
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="mb-1">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange(data)
                    }}
                    selectOption={[
                      {
                        label: (
                          <>
                            {t("(필수) 이용약관 동의")}{" "}
                            <Link
                              to={"/terms-of-use"}
                              className="!underline hover:font-pretendard700 hover:text-primary"
                              target={isMobile ? "_self" : "_blank"}
                            >
                              {t("보기")}
                            </Link>
                          </>
                        ),
                        name: "seenTerms",
                        defaultChecked: selectionData.seenTerms,
                      },
                    ]}
                  />
                </div>

                <div className="mb-1">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange(data)
                    }}
                    selectOption={[
                      {
                        label: (
                          <>
                            {t("(필수) 개인정보 수집/이용 동의")}{" "}
                            <Link
                              to={"/privacy-policy"}
                              className="!underline hover:font-pretendard700 hover:text-primary"
                              target={isMobile ? "_self" : "_blank"}
                            >
                              {t("보기")}
                            </Link>
                          </>
                        ),
                        name: "seenInfo",
                        defaultChecked: selectionData.seenInfo,
                      },
                    ]}
                  />
                </div>

                <div className="mb-1">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange(data)
                    }}
                    selectOption={[
                      {
                        label: (
                          <>
                            {t("(필수) 개인정보 제 3자 제공 동의")}{" "}
                            <Link
                              to={`/supplier-info/${voucherDetail?.brandName}`}
                              className="!underline hover:font-pretendard700 hover:text-primary"
                              target={isMobile ? "_self" : "_blank"}
                            >
                              {t("보기")}
                            </Link>
                          </>
                        ),
                        name: "seenThird",
                        defaultChecked: selectionData.seenThird,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex font-pretendard500 gap-x-[30px] pt-[32px] fixed left-0 bottom-0 md:relative w-full">
                <Button
                  onClick={onFinish}
                  className="flex bg-black text-white text-[20px] md:text-[18px] w-full font-pretendard700 h-[70px] md:h-[56px] px-[16px] disabled:text-gray2 disabled:bg-gray6"
                  disabled={checkDisabledBtnSubmit()}
                >
                  {t("원 결제하기", {
                    voucherPrice:
                      MoneyConvert(
                        Number(voucherDetail?.voucherPrice) * quantity,
                      ) || 0,
                  })}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <form
          ref={ref}
          action={`${import.meta.env.VITE_API_URL}/payment/request-pay`}
          method="post"
          hidden
        >
          <input type="text" name="accessToken" value={user?.accessToken} />
          <input type="text" name="orderId" value={voucherDetail?.id} />

          <input type="text" name="quantity" value={quantity} />
          <input
            type="text"
            name="itemName"
            value={voucherDetail?.voucherName}
          />
          <input type="text" name="totalAmount" value={1} />
          {IS_GIFT_PARAMS && <input type="text" name="type" value={"GIFT"} />}
          {IS_GIFT_PARAMS && (
            <input type="text" name="phoneNumber" value={phoneGift} />
          )}
          {IS_GIFT_PARAMS && (
            <input type="text" name="receiverName" value={nameGift} />
          )}
        </form>
        <CreatePayInicis params={paramsInicis} refMobile={mobilePurchaseRef} />
        {/* <MaxQtyModal
        open={openMaxQtyModal}
        handleClose={() => setOpenMaxQtyModal(false)}
      /> */}
        {/* <Inicis /> */}
        {/* {loading && <Loading />} */}
        <EvenEnd
          isOpen={openEvenEnd}
          handleClose={() => {
            setOpenEvenEnd(false)
            navigate(`/group-buying/detail/${id}`)
          }}
        />
        <MaximumPurchaseLimit
          isOpen={openMaximumPurchaseLimit}
          handleClose={() => {
            setOpenMaximumPurchaseLimit(false)
            navigate(`/group-buying/detail/${id}`)
          }}
        />
        <MaxQtyModal
          isOpen={openMaxQtyModal}
          handleClose={() => {
            setOpenMaxQtyModal(false)
            navigate(`/group-buying/detail/${id}`)
          }}
        />
      </div>
    </>
  )
}

export default OrderSummary
