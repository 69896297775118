import QrImage from "@assets/images/my_voucher/qr_default.png"
import moment from "moment"
import { formatPrice } from "src/util/formatPrice"

interface Props {
  onClickQr: (data: any) => void
  data: any
}

function CardCoupon({ onClickQr, data }: Props) {
  const date = moment(data?.expiredTime).diff(moment(), "days")

  return (
    <div className="border-[#D6D5D5] border relative leading-none">
      <div className="relative">
        <img
          src={data?.image}
          alt="img"
          className="object-cover w-full aspect-square"
        />
        <div className="flex absolute bottom-0 left-0">
          <div className="py-[5px] px-[8px] bg-[#FF31A0] text-center text-[#fff] text-[12px]">
            D-{date}
          </div>
          <div className="py-[5px] px-[8px] bg-[#000000] text-center text-[#fff] text-[12px] flex-1">
            {data?.code}
          </div>
        </div>

        <img
          src={QrImage}
          alt="qr"
          className="absolute top-0 right-0 cursor-pointer"
          onClick={() => onClickQr(data)}
        />
      </div>

      <div className="px-[12px] pt-[20px] pb-[35px]">
        <div className="text-[#666] text-[14px]">{data?.brandName}</div>
        <div className="font-pretendard700 text-[18px] mt-[6px] mb-[5px]">
          {data?.voucherName}
        </div>
        <div className="text-[#666] text-[14px]">
          {moment(data?.dateOfUse).format("YYYY-MM-DD")} ~{" "}
          {moment(data?.expiredTime).format("YYYY-MM-DD")}
        </div>
        <div className="font-pretendard700 md:mt-[48px] mt-[36px] text-[18px]">
          {formatPrice(data?.rootAmount)}원
        </div>
      </div>

      <div className="gap-[11px] absolute bottom-[-1px] left-[-3px] xl:flex hidden">
        {new Array(9).fill(0).map((_, index) => (
          <div
            className="w-[21.5px] h-[12px] rounded-t-full border-[#D6D5D5] border border-b-0 bg-[#fff]"
            key={index}
          />
        ))}
      </div>

      <div className="absolute bottom-[-1px] left-[-3px] bg-[#fff] w-[15px] h-[6px] xl:block hidden"></div>
      <div className="absolute bottom-[-1px] right-[-3px] bg-[#fff] w-[15px] h-[6px] xl:block hidden"></div>
    </div>
  )
}

export default CardCoupon
