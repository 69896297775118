import brandImg from "@assets/images/brand_logo/brand4.png"
import useMessage from "antd/es/message/useMessage"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { transactionApi } from "src/api/myBalance"
import { useAppDispatch, useAppSelector } from "src/app/hooks"

import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import {
  completeTransaction,
  expiredTransaction,
  successTransaction,
} from "src/features/confirmTransaction/confirmTransactionSlice"
import { MoneyConvert } from "src/util/format"

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import "leaflet/dist/leaflet.css"
import { useCallback, useEffect, useMemo } from "react"

import MarkerIcon from "@assets/images/my_page/marker-icon-2x.png"

import { Icon } from "leaflet"
const myIcon = new Icon({
  iconUrl: MarkerIcon,
  iconSize: [32, 32],
})

function ConfirmModal() {
  const { t } = useTranslation()

  const { status, data } = useAppSelector((state) => state.confirmModal)
  const { detailData } = useAppSelector((state) => state.brandData)

  console.log("data", data)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [messageApi, context] = useMessage()
  const handleAcceptPayment = async () => {
    try {
      const res: any = await transactionApi.acceptPayment({
        trxUID: data?.trxUID as string,
      })
      if (res?.result && res.code === 200) {
        const dataPayment = res.data
        console.log("dataPayment", dataPayment)

        dispatch(
          successTransaction({
            sender: dataPayment.sender,
            time: dataPayment.time,
            receiver: dataPayment.receiver,
            trxUID: dataPayment.trxUID,
            message: dataPayment.message,
            brandId: dataPayment?.brandId,
            brandName: dataPayment?.brandName,
            name: dataPayment?.branchInformation?.name,
            branchInformation: {
              ...data?.branchInformation,
              address: dataPayment.address,
            },
          }),
        )
      } else {
        const error = res.error
        if (error?.code === 403) {
          return
        }
        if (error?.errorCode === "payment_001") {
          dispatch(expiredTransaction())
          return
        }

        messageApi.open({
          type: "error",
          content: error?.message || "Something went wrong! Please try again.",
        })
        dispatch(completeTransaction())
      }
    } catch (error) {
      dispatch(completeTransaction())
    }
  }
  // console.log('data', data);

  const handleRejectPayment = async () => {
    try {
      await transactionApi.rejecpayment({
        trxUID: data?.trxUID as string,
      })
      // console.log(res)
    } catch (error) {
      console.log("error", error)
    }
  }

  // const handleOpenDeactiveModal = async () => {

  //   try {
  //     const resp: any = await userApi.getOtp()
  //     if (resp.result) {
  //       setTimeOtp(60)
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  console.log("data :>> ", data)

  useEffect(() => {
    if (status === "VERIFY") {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"))
      }, 100)
    }
  }, [status])

  const mapEl = useMemo(() => {
    if (data?.branchInformation?.lat && data?.branchInformation?.long) {
      return (
        <MapContainer
          // center={[51.505, -0.09]}
          center={[
            parseFloat(data?.branchInformation?.lat ?? "0"),
            parseFloat(data?.branchInformation?.long ?? "0"),
          ]}
          zoom={13}
          style={{ height: "175px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            // position={[51.505, -0.09]}
            position={[
              parseFloat(data?.branchInformation?.lat ?? "0"),
              parseFloat(data?.branchInformation?.long ?? "0"),
            ]}
            icon={myIcon}
          >
            {/* <Popup>Brand</Popup> */}
          </Marker>
        </MapContainer>
      )
    }
  }, [data?.branchInformation?.lat, data?.branchInformation?.long])

  return (
    <div>
      {context}
      <CommonModal
        isOpen={status === "VERIFY"}
        // isOpen={true}
        // handleClose={() => {
        //   dispatch(completeTransaction())
        //   handleRejectPayment()
        // }}
        handleCloseOnlyClickButton={() => {
          dispatch(completeTransaction())
          handleRejectPayment()
        }}
        imageTop={
          <img
            className="object-cover"
            src={detailData?.logoImage}
            alt="brand-logo"
          />
        }
      >
        <p className="font-pretendard700 text-[20px] text-primary leading-normal">
          {t("Transaction certification")}
        </p>
        <div className="mt-[20px]">
          <p className="text-[18px] font-pretendard700 leading-normal mb-[2px]">
            사용 브랜드 : {data?.branchInformation?.brandName}
          </p>
          <p className="text-[18px] font-pretendard700 text-[#C70039] leading-normal mb-[2px]">
            결제 금액: {MoneyConvert(data?.amount)} {t("Won")}
          </p>
          <p className="text-black text-[14px] leading-[20px]">
            {t("사용 지점:")} {data?.branchInformation?.address} (
            {data?.branchInformation?.name})
          </p>
          <div className="mt-[24px]">
            {mapEl}

            {/* <iframe
              title="map-brand"
              src={`https://www.google.com/maps/embed?q=${data?.branchInformation?.lat},${data?.branchInformation?.long}`}
              width="100%"
              height="175"
            ></iframe> */}
          </div>
          <div className="mt-3 pb-[28px] flex gap-[10px] border-b-[1px] border-solid border-[#D9D9D9]">
            <Button
              theme="main"
              variant="outline"
              onClick={() => {
                dispatch(completeTransaction())
                handleRejectPayment()
              }}
            >
              {t("결제 취소")}
            </Button>
            <Button
              theme="main"
              onClick={async () => {
                dispatch(completeTransaction())
                handleAcceptPayment()
              }}
            >
              {t("결제 확인")}
            </Button>
          </div>
          <div className="mt-[24px] flex items-center flex-col">
            <p className="capitalize text-[13px] font-pretendard400 text-center leading-[18.2px]">
              {t("Or This Is Not You?")}
            </p>
            <p
              className="mt-[5px] capitalize text-[#0091EA] text-[13px] font-pretendard700 underline-offset-2 underline cursor-pointer leading-[18.2px]"
              onClick={() => {
                dispatch(completeTransaction())
                handleRejectPayment()
                navigate(
                  `/my-page/my-change-info?status=deActive&trxUID=${data?.trxUID}`,
                )
              }}
            >
              {t("Deactivate Account Now")}
            </p>
          </div>
        </div>
      </CommonModal>
    </div>
  )
}

export default ConfirmModal
