import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import ImgUpload from "@assets/images/my_page/add_photo_alternate.png"
import { Spin, Upload, UploadFile, UploadProps } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

interface Props {
  isOpen: boolean
  handleClose: () => void
  onUploadBill: (file: any) => void
  isLoading?: boolean
  data?: any
}

function ModalUploadBill({
  handleClose,
  isOpen,
  onUploadBill,
  isLoading = false,
  data,
}: Props) {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewImage, setPreviewImage] = useState<any>({})
  const { t } = useTranslation()
  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    return file.url || (file.preview as string)
  }

  const onChangeAntd: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    const itemPreview = await handlePreview(newFileList[0])

    setFileList(newFileList)
    setPreviewImage({
      idPreview: Date.now(),
      src: itemPreview,
    })
  }

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseOutside
      className="modal-transfer-payment"
    >
      <Spin spinning={isLoading}>
        <div className="text-[20px] font-pretendard500 text-[#000] text-center">
          {t("Upload bill")}
        </div>

        <Upload
          // fileList={[]}
          customRequest={dummyRequest}
          onChange={onChangeAntd}
          // multiple
          maxCount={1}
          showUploadList={false}
          accept="image/*"
        >
          <div className="w-full aspect-square border border-[#E5E3E3] bg-[#FCFCFC] mt-[19px] mb-[26px] flex items-center justify-center cursor-pointer">
            {previewImage?.src || data?.bill ? (
              <img
                src={previewImage?.src || data?.bill}
                alt="icon"
                className="w-full h-full object-cover"
              />
            ) : (
              <img src={ImgUpload} alt="icon" />
            )}
          </div>
        </Upload>

        <Button
          theme="main"
          labelSize={16}
          height={56}
          onClick={() => onUploadBill(fileList)}
          disabled={fileList.length === 0}
        >
          Upload
        </Button>
      </Spin>
    </CommonModal>
  )
}

export default ModalUploadBill
