import { useNavigate } from "react-router-dom"
import { MoneyConvert } from "src/util/format"
import Button from "../../Button"
import { useTranslation } from "react-i18next"
import CommonModal from ".."
import moment from "moment"
import { UrlInternal } from "src/constant/endpointRoute"
type Props = {}

function TransactionSucccess({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const status = urlParams.get("status")
  const amount = urlParams.get("amount")
  const brandName = urlParams.get("brandName")
  const endTime = urlParams.get("endTime")
  const expiredTime = urlParams.get("expiredTime")
  const orderId = urlParams.get("orderId")
  const type = urlParams.get("type")

  const handleClose = () => {
    // navigate("/my-page/order-history")
    navigate(`/my-page/order-detail/${orderId}?type=order`)
    // navigate(
    //   `/my-page/order-detail/${orderId}?type=${
    //     activeTab === tabType[0].value ? "order" : "normalMembership"
    //   }`,
    // )
  }

  return (
    <CommonModal
      isOpen={status === "SUCCESS"}
      // isOpen={true}
      handleClose={handleClose}
    >
      <div className="w-full h-full bg-primaryWhite">
        {type === "gift" ? (
          <>
            <p className="text-primary text-[18px] font-pretendard500 leading-normal text-center">
              {"입력하신 정보로 선물 전송이 완료했습니다."}
            </p>
          </>
        ) : (
          <>
            <p className="text-primary text-[20px] font-pretendard600 leading-normal mb-1">
              {t("Your payment has been completed.")}
            </p>
            <p className="text-primary text-[20px] font-pretendard600 leading-none">
              {t("Enjoy the VIP benefits of [brand name]", {
                brandName,
              })}
            </p>
            <p className="text-gray1 text-[14px] sm:font-pretendard400 font-pretendard400 capitalize mt-[10px]">
              {t("VIP Membership Validity")} <br />
              {moment(endTime).format("YYYY-MM-DD")} ~{" "}
              {moment(expiredTime).format("YYYY-MM-DD")}
            </p>
          </>
        )}
        <div className="flex justify-between gap-4 sm:mt-[30px] mt-[30px]">
          <Button theme="main" onClick={handleClose}>
            {t("View order detail")}
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default TransactionSucccess
