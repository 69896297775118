import { Spin, Upload, UploadFile, UploadProps } from "antd"
import { useState } from "react"
import CommonModal from "src/components/Modal"
import close from "@assets/images/brand_detail/close.png"
import upload from "@assets/images/brand_detail/upload.png"
import Button from "src/components/Button"
import VideoJS from "src/components/VideoJS"
import { formatPrice } from "src/util/formatPrice"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import PreviewVideo from "@assets/images/history_order/preview_video.png"
import { useTranslation } from "react-i18next"

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleOk: (e: any) => void
  orderDetail: any
}
const generateVideoThumbnail = (file: File) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas")
    const video = document.createElement("video")

    // this is important
    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      let ctx: any = canvas.getContext("2d")

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL("image/png"))
    }
  })
}

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

function PopupRefund({ handleClose, handleOk, isOpen, orderDetail }: Props) {
  const [previewImage, setPreviewImage] = useState<object[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [count, setCount] = useState(0)
  const [content, setContent] = useState("")
  const [isModalVideo, setIsModalVideo] = useState({
    isOpen: false,
    url: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  console.log("previewImage", previewImage)
  console.log("fileList", fileList)

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    return file.url || (file.preview as string)
  }

  console.log("previewImage", previewImage)

  const onChangeAntd: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (previewImage.length < 8) {
      const dtPreviewImage: any = previewImage.concat(newFileList).slice(0, 8)
      const dtFileList: any = fileList.concat(newFileList).slice(0, 8)

      console.log("dtFileList", dtFileList)
      console.log("dtPreviewImage", dtPreviewImage)

      const dataPreview = []
      for (let i = 0; i < dtPreviewImage.length; i++) {
        if (dtFileList[i].type === "video/mp4") {
          const thumbnail = await generateVideoThumbnail(
            dtFileList[i].originFileObj,
          )
          dataPreview.push({
            idPreview: Date.now(),
            src: thumbnail,
            type: "video",
          })
          // const dataVideo: any = previewImage[i]
          // if (dataVideo && dataVideo.type === "video/mp4") {
          //   dataPreview.push({
          //     idPreview: Date.now(),
          //     src: dataVideo.src,
          //     type: "video/mp4",
          //   })
          // } else {
          //   const formData = new FormData()
          //   formData.append("file", dtPreviewImage[i].originFileObj)
          //   const respUpload: any = await uploadSingleFile(formData)
          //   if (respUpload) {
          //     dataPreview.push({
          //       idPreview: Date.now(),
          //       src: respUpload.imageUrl,
          //       type: "video/mp4",
          //     })
          //   }
          // }
        } else {
          const itemPreview = await handlePreview(dtFileList[i])
          dataPreview.push({
            idPreview: Date.now(),
            src: itemPreview,
            type: "image",
          })
        }
      }

      setFileList(dtFileList)
      setPreviewImage(dataPreview)
    }
  }

  const handlePreviewVideo = async (file: any) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append("file", file.originFileObj)
      const respUpload: any = await uploadSingleFile(formData)
      if (respUpload) {
        setIsModalVideo({
          isOpen: true,
          url: respUpload.imageUrl,
        })
        setIsLoading(false)
      }
    } catch (error) {}
  }

  return (
    <>
      {isModalVideo.isOpen && (
        <CommonModal
          onCancel={() =>
            setIsModalVideo({
              isOpen: false,
              url: "",
            })
          }
          className="modal-membership text-[#000] w-[100vh] h-[100vh] modal-fullpage font-pretendard400"
          isOpen={isModalVideo.isOpen}
          isCloseOutside
          // width={"100vh"}
        >
          <div className="max-h-[80vh]">
            {
              <VideoJS
                url={isModalVideo?.url}
                // thumbnail={data?.videoThumbnail}
              />
            }
          </div>
        </CommonModal>
      )}

      <CommonModal
        onCancel={handleClose}
        className="modal-membership text-[#000] w-[100vh] h-[100vh] modal-fullpage font-pretendard400"
        isOpen={isOpen}
        // isOpen
        isCloseOutside
      >
        <div className="px-[16px] mt-[28px]">
          <div className="text-[20px] font-pretendard700">환불 요청</div>
          <div className="text-[18px] font-pretendard700 mt-[30px] mb-[22px]">
            상품 정보
          </div>

          <div className="flex gap-[13px] mb-[36px]">
            <div className="w-[88px] h-[88px]">
              <img
                src={orderDetail?.voucherImage}
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1 flex flex-col gap-[10px] leading-none">
              <div>{orderDetail?.brandName}</div>
              <div className="font-pretendard700 text-[16px] line-clamp-1">
                {orderDetail?.voucherName}
              </div>
              <div className="line-through text-[#767676] text-[13px]">
                {formatPrice(
                  Number(orderDetail?.voucherPrice) * orderDetail?.quantity,
                )}
                원
              </div>
              <div>
                <span className="text-[#D32F2F]">
                  [
                  {formatPrice(
                    100 -
                      ((Number(orderDetail?.totalPayment) -
                        Number(orderDetail?.shippingFee || 0) -
                        Number(orderDetail?.costsForJeju || 0)) /
                        orderDetail?.quantity /
                        Number(orderDetail?.voucherPrice)) *
                        100,
                  )}
                  %]
                </span>{" "}
                <span className="font-pretendard700">
                  {formatPrice(
                    Number(
                      orderDetail?.totalPayment -
                        Number(orderDetail?.shippingFee || 0) -
                        Number(orderDetail?.costsForJeju || 0),
                    ) / orderDetail?.quantity,
                  ) || 0}
                  원
                </span>{" "}
                <span>/ 수량 {orderDetail?.quantity}개</span>
              </div>
            </div>
          </div>

          <div className="py-[36px] border-y-[16px] border-[#F5F5F5] mx-[-40px] px-[40px]">
            <div className="flex justify-between">
              <div className="text-[#757575]">{t("Refund Method")}</div>
              <div className="font-pretendard700">
                {orderDetail?.refundMethod}
              </div>
            </div>
            <div className="flex justify-between mt-[16px]">
              <div className="text-[18px] font-pretendard700">
                {t("Refund Amount")}
              </div>
              <div className="font-pretendard700 text-[18px] text-[#539165]">
                {formatPrice(orderDetail?.totalPayment)}원
              </div>
            </div>
          </div>

          <textarea
            rows={6}
            className="w-full mt-[24px] border-0 border-b border-solid border-b-[#BDBDBD] focus:outline-none placeholder:text-[#A3A3A3] text-[16px]"
            onChange={(e) => {
              setCount(e.target.value.length)
              setContent(e.target.value)
            }}
            maxLength={2000}
            style={{ resize: "none" }}
            placeholder={t("Write your reason...")}
            value={content}
          />
          <div className="text-right mt-[8px] mb-[16px] text-[#A3A3A3] text-[14px]">
            {count}/2000
          </div>

          <div className="flex max-w-[380px] gap-[8px] flex-wrap">
            {previewImage?.length > 0 &&
              previewImage?.map((dt: any, index) => (
                <div className="relative" key={index}>
                  <div
                    className="absolute right-[5px] top-[5px] rounded-[50%] bg-[#fff] p-[4px] cursor-pointer z-10"
                    onClick={() => {
                      const dtPreview = [...previewImage]
                      const result = dtPreview.splice(index, 1)
                      setPreviewImage(dtPreview)

                      const dtFileList = [...fileList]
                      const resultFileList = dtFileList.splice(index, 1)
                      setFileList(dtFileList)
                    }}
                  >
                    <img src={close} alt="close" />
                  </div>
                  {/* {dt?.type === "video/mp4" ? (
                  <img
                    src={dt.src}
                    alt="img upload"
                    className="w-[74px] h-[74px] aspect-square object-cover"
                  />
                ) : (
                  <div className="w-[74px] h-[74px] aspect-square">
                    <VideoJS
                      url={dt.src}
                      // thumbnail={data?.videoThumbnail}
                    />
                  </div> */}
                  <div className="relative">
                    {dt?.type === "video" && (
                      <div className="absolute inset-0 flex justify-center items-center">
                        <Spin spinning={isLoading}>
                          <div
                            className="w-[20px] h-[20px] rounded-full bg-[#0000004D] flex items-center justify-center cursor-pointer"
                            onClick={() => handlePreviewVideo(fileList[index])}
                          >
                            <img src={PreviewVideo} alt="thumbnail" />
                          </div>
                        </Spin>
                      </div>
                    )}

                    <img
                      src={dt.src}
                      alt="img upload"
                      className="w-[74px] h-[74px] aspect-square object-cover"
                    />
                  </div>
                  {/* )} */}
                </div>
              ))}
            {previewImage.length < 8 && (
              <div
                className={`flex flex-col justify-between ${
                  fileList.length > 0 && "w-[74px] h-[74px]"
                }`}
              >
                {previewImage.length > 0 && (
                  <div className="text-[#A3A3A3] text-[13px]">
                    ({previewImage.length}/8)
                  </div>
                )}
                <div className="cursor-pointer max-h-[30px]">
                  <Upload
                    fileList={[]}
                    customRequest={dummyRequest}
                    onChange={onChangeAntd}
                    multiple
                    maxCount={8}
                    showUploadList={false}
                    // accept="image/*"
                    accept="image/*,video/*"
                  >
                    <img src={upload} alt="icon" />
                  </Upload>
                </div>
              </div>
            )}
          </div>

          <div className="mt-[40px] flex gap-[10px]">
            <Button
              onClick={handleClose}
              className="flex justify-center border border-solid border-primary text-primary text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
            >
              취소
            </Button>
            <Button
              onClick={() => {
                handleOk({
                  content: content,
                  fileList: fileList,
                })
                handleClose()
              }}
              disabled={fileList.length <= 0 || content.length <= 0}
              className="flex justify-center bg-[#000] text-[#fff] text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
            >
              환불 요청
            </Button>
          </div>
        </div>
      </CommonModal>
    </>
  )
}

export default PopupRefund
