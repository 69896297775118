import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import { getBrandFilter } from "src/services/Brand"
import { formatPrice } from "src/util/formatPrice"

import SortIcon from "@assets/images/history_order/sort.png"
import { UrlInternal } from "src/constant/endpointRoute"
import {
  convertStatus,
  convertStatusBg,
  convertStatusPayment,
  convertStatusTextColor,
} from "src/util/convertStatusPayment"
import "./style.scss"
import { getDataMyOrderHistory } from "src/api/myOrderHistoryApi"
import ConvertStatusPaymentHtml from "src/components/ConvertStatusPaymentHtml"
import Button from "src/components/Button"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

const tabType = [
  {
    label: "Voucher",
    value: "VOUCHER",
  },
  {
    label: "E-content",
    value: "E_CONTENT",
  },
  {
    label: "Product",
    value: "PRODUCT",
  },
]

function MyOrderHistory() {
  const { state } = useLocation()
  const { t } = useTranslation()

  const [pageable, setPageable] = useState<any>({})
  const { isMobile } = useIsMobile(768)
  const [transactionHistory, setTransactionHistory] = useState<any>([])

  const [dataListBrand, setDataListBrand] = useState<any>([])

  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const query: any = {
    page: Number(searchParams.get("page")) || 1,
    size: 8,
    type: searchParams.get("type") || "VOUCHER",
    brandId: searchParams.get("brandId") || "",
    timeFilter: searchParams.get("timeFilter") || "",
    voucherType: searchParams.get("voucherType") || "",
    totalValueSort: searchParams.get("totalValueSort") || "",
    orderProductStatus: searchParams.get("orderProductStatus") || "",
  }

  const getDataBrand = async () => {
    try {
      const { data }: any = await getBrandFilter({
        querySearch: "",
        filter: [],
        page: 1,
        size: 999999,
        productType: query.type,
      })

      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.datas.length; i++) {
          dataResp.push({
            value: data.datas[i].id,
            label: data.datas[i].brandName,
          })
        }

        setDataListBrand(dataResp)
      } else {
        setDataListBrand([])
      }
    } catch (error) {
      setDataListBrand([])
    }
  }

  useEffect(() => {
    getDataBrand()
  }, [searchParams])

  const handleGetTransactionHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      const { data }: any = await getDataMyOrderHistory({
        ...query,
        page: page ?? query.page,
      })

      if (data) {
        let dataResp = data.datas
        if (isLoadmore) {
          setTransactionHistory([...transactionHistory, ...dataResp])
        } else {
          setTransactionHistory(dataResp)
        }
        setPageable(data.pageable)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionHistory()
  }, [searchParams])

  const allTime = [
    { value: "", label: t("All") },
    { value: "1M", label: t("1개월") },
    { value: "3M", label: t("3개월") },
    { value: "6M", label: t("6개월") },
    { value: "1Y", label: t("1년") },
  ]

  const handleColumns = () => {
    switch (query.type) {
      case tabType[0].value:
        return columns1
      case tabType[1].value:
        return columns2
      case tabType[2].value:
        return columns3
      default:
        return columns1
    }
  }

  const columns1: ColumnsType<any> = [
    {
      title: t("Purchase date"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className={`text-primary font-pretendard400 `}>
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
    {
      title: t("Brand name"),
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "10%",
    },
    {
      title: t("Product's name"),
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "18%",
    },
    {
      title: t("Type"),
      dataIndex: "voucherType",
      key: "voucherType",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {text === "VOUCHER_NEW" ? t("Voucher") : t("Coupon")}
          </p>
        )
      },
      width: "7%",
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "5%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>{t("Total")}</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setSearchParams((params) => {
                    params.set(
                      "totalValueSort",
                      searchParams.get("totalValueSort") === "desc"
                        ? "asc"
                        : "desc",
                    )
                    return params
                  })
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "totalValue",
      key: "totalValue",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {formatPrice(text)}원
          </p>
        )
      },
      width: "10%",
    },
    {
      title: t("Expiry"),
      dataIndex: "Expiry",
      key: "Expiry",
      align: "center",
      render: (text, record) => {
        return (
          <p className={`text-primary font-pretendard400`}>
            {moment(record?.dateOfUse).format("YYYY.MM.DD")} ~{" "}
            {moment(record?.expiredTime).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "17%",
    },
    {
      title: t("Payment status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            } ${convertStatusTextColor(text)}`}
          >
            {t(convertStatus(text))}
          </p>
        )
      },
      width: "10%",
    },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: t("Purchase date"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className={` text-primary font-pretendard400`}>
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
    {
      title: t("Brand name"),
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "15%",
    },
    {
      title: t("Product's name"),
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "25%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>{t("Total")}</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setSearchParams((params) => {
                    params.set(
                      "totalValueSort",
                      searchParams.get("totalValueSort") === "desc"
                        ? "asc"
                        : "desc",
                    )
                    return params
                  })
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "totalValue",
      key: "totalValue",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {formatPrice(text)}원
          </p>
        )
      },
      width: "20%",
    },

    {
      title: "결제상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <div className={`${convertStatusTextColor(text)} font-pretendard700`}>
          {t(convertStatus(text))}
        </div>
      ),
      width: "10%",
    },
  ]

  const columns3: ColumnsType<any> = [
    {
      title: t("Purchase date"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className={` text-primary font-pretendard400`}>
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
    {
      title: t("Order status"),
      dataIndex: "orderProductStatus",
      key: "orderProductStatus",
      align: "center",
      render: (text, record) => {
        return (
          <div className="inline-block">
            <ConvertStatusPaymentHtml status={text} />
          </div>
        )
      },
      width: "20%",
    },
    {
      title: t("Brand name"),
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "10%",
    },
    {
      title: t("Product's name"),
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "25%",
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "10%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>{t("Total")}</div>
            <div className="cursor-pointer">
              <img src={SortIcon} alt="sort" onClick={() => {}} />
            </div>
          </div>
        )
      },
      dataIndex: "totalValue",
      key: "totalValue",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {formatPrice(text)}원
          </p>
        )
      },
      width: "10%",
    },

    {
      title: "결제상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <div className={`${convertStatusTextColor(text)} font-pretendard700`}>
          {t(convertStatus(text))}
        </div>
      ),
      width: "10%",
    },
  ]

  const getDetailOrderHistory = async (id: number) => {
    try {
      if (query.type === tabType[0].value) {
        navigate(`${UrlInternal.ORDER_VOUCHER_DETAIL}/${id}`)
      }
      if (query.type === tabType[1].value) {
        navigate(`${UrlInternal.ORDER_ECONTENT_DETAIL}/${id}`)
      }
      if (query.type === tabType[2].value) {
        navigate(`${UrlInternal.ORDER_PRODUCT_DETAIL}/${id}`)
      }
    } catch (error) {}
  }

  return (
    <>
      {!isMobile ? (
        <>
          <div className="md:container mt-[63px]">
            <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary">
              {t("My order history2")}
            </div>

            <div className="flex mt-[40px] mb-[39px]">
              {tabType.map((item, index) => (
                <div
                  className={`text-[#000] text-[18px] leading-[18px] cursor-pointer ${
                    index !== tabType.length - 1 &&
                    "pr-[16px] border-r border-r-[#D4D4D4]"
                  } ${index !== 0 && "pl-[16px]"} ${
                    (item.value === query.type ||
                      (!query.type && index === 0)) &&
                    "font-pretendard700"
                  }`}
                  key={index}
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set("type", item.value)
                      params.delete("brandId")
                      params.delete("timeFilter")
                      params.delete("totalValueSort")
                      params.delete("voucherType")
                      params.delete("orderProductStatus")
                      return params
                    })
                  }}
                >
                  {t(item.label)}
                </div>
              ))}
            </div>

            <div className="flex justify-between items-end  mb-[17px]">
              <div className="flex gap-[22px]">
                <div className="flex gap-[8px] items-center">
                  <div className="text-[14px]">브랜드</div>
                  <SelectCustom
                    placeholder={t("All")}
                    value={query.brandId}
                    onChange={(e) => {
                      setSearchParams((params) => {
                        params.set("brandId", e)
                        return params
                      })
                    }}
                    options={dataListBrand}
                    height={40}
                    width={155}
                  />
                </div>
                {query.type !== tabType[2].value && (
                  <div className="flex gap-[8px] items-center">
                    <div className="text-[14px]">기간</div>
                    <SelectCustom
                      placeholder={t("All")}
                      value={query.timeFilter}
                      onChange={(e) => {
                        setSearchParams((params) => {
                          params.set("timeFilter", e)
                          return params
                        })
                      }}
                      options={allTime}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
                {query.type === tabType[2].value && (
                  <div className="flex gap-[8px] items-center">
                    <div className="text-[14px]">Order status</div>
                    <SelectCustom
                      placeholder={t("All")}
                      value={query.orderProductStatus}
                      onChange={(e) => {
                        setSearchParams((params) => {
                          params.set("orderProductStatus", e)
                          return params
                        })
                      }}
                      options={[
                        { value: "", label: t("All") },
                        {
                          value: "WAITING_FOR_CONFIRMATION",
                          label: t("Wait for confirmation"),
                        },
                        {
                          value: "WAITING_FOR_DELIVERY",
                          label: t("Waiting for delivery"),
                        },
                        { value: "DELIVERING", label: t("Delivering") },
                        { value: "DELIVERED", label: t("Delivered") },
                        { value: "REFUND_REQUEST", label: t("Refund request") },
                        {
                          value: "REFUND_APPROVED",
                          label: t("Refund approved"),
                        },
                        {
                          value: "REFUND_REJECTED",
                          label: t("Refund rejected"),
                        },
                        { value: "CANCEL_ORDER", label: t("Cancel order") },
                      ]}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
                {query.type === tabType[0].value && (
                  <div className="flex gap-[8px] items-center">
                    <div className="text-[14px]">Type</div>
                    <SelectCustom
                      placeholder={t("All")}
                      value={query.voucherType}
                      onChange={(e) => {
                        setSearchParams((params) => {
                          params.set("voucherType", e)
                          return params
                        })
                      }}
                      options={[
                        { value: "", label: t("All") },
                        { value: "VOUCHER_NEW", label: t("Voucher") },
                        { value: "COUPON", label: t("Coupon") },
                      ]}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
              </div>
              <div className="flex gap-[28px]">
                <div className="text-[14px]">
                  {t("총 N건", {
                    number: pageable?.totalElement ?? 0,
                  })}
                </div>
                <div className="text-[14px]">
                  {!query.timeFilter ? (
                    t("All")
                  ) : (
                    <>
                      {dayjs()
                        .subtract(TIME_NUMBER[query.timeFilter] || 12, "month")
                        .format("YYYY.MM.DD")}{" "}
                      ~ {dayjs().format("YYYY.MM.DD")}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px]">
              <CsTable
                className="mb-[34px] table-history-order"
                columns={handleColumns()}
                dataSource={transactionHistory}
                // showHeader={false}
                onRow={(record, rowIndex) => {
                  console.log("record", record)
                  return {
                    onClick: () => {
                      getDetailOrderHistory(record?.id)
                    },
                  }
                }}
              />
              {transactionHistory &&
                transactionHistory?.length > 0 &&
                pageable?.totalElement > 8 && (
                  <Pagination
                    showSizeChanger={false}
                    rootClassName="flex justify-center"
                    className="custom-pagination"
                    total={pageable?.totalElement}
                    pageSize={query?.size}
                    current={query?.page}
                    onChange={(page) => {
                      setSearchParams((params) => {
                        params.set("page", String(page))
                        return params
                      })
                    }}
                  />
                )}
            </div>
          </div>
        </>
      ) : (
        <>
          <NavBarMb
            pageName="My order history2"
            // csGoback={() => navigate("/my-page")}
          />
          <div className="flex mt-[17px]">
            {tabType.map((item, index) => (
              <div
                className={`text-[#000] text-[16px] flex-1 text-center border-b border-b-[#E5E5E5] pb-[12px] ${
                  (item.value === query.type || (!query.type && index === 0)) &&
                  "font-pretendard700 border-b-[3px] !border-b-[#000000]"
                }`}
                key={index}
                onClick={() => {
                  setSearchParams((params) => {
                    params.set("type", item.value)
                    params.delete("brandId")
                    params.delete("timeFilter")
                    params.delete("totalValueSort")
                    params.delete("voucherType")
                    params.delete("orderProductStatus")
                    return params
                  })
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          <div className="px-[24px] mt-[20px] mb-[9px]">
            <div className="flex gap-[16px]">
              <div className="flex-1">
                <div className="pb-[10px] text-[14px]">브랜드</div>
                <SelectCustom
                  placeholder={t("All")}
                  value={query.brandId}
                  onChange={(e) => {
                    setSearchParams((params) => {
                      params.set("brandId", e)
                      return params
                    })
                  }}
                  options={dataListBrand}
                  height={40}
                />
              </div>
              {query.type !== tabType[2].value && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">기간</div>
                  <SelectCustom
                    placeholder={t("All")}
                    value={query.timeFilter}
                    onChange={(e) => {
                      setSearchParams((params) => {
                        params.set("timeFilter", e)
                        return params
                      })
                    }}
                    options={allTime}
                    height={40}
                  />
                </div>
              )}
              {query.type === tabType[2].value && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">Order status</div>
                  <SelectCustom
                    placeholder={t("All")}
                    value={query.orderProductStatus}
                    onChange={(e) => {
                      setSearchParams((params) => {
                        params.set("orderProductStatus", e)
                        return params
                      })
                    }}
                    options={[
                      { value: "", label: t("All") },
                      {
                        value: "WAITING_FOR_CONFIRMATION",
                        label: t("Wait for confirmation"),
                      },
                      {
                        value: "WAITING_FOR_DELIVERY",
                        label: t("Waiting for delivery"),
                      },
                      { value: "DELIVERING", label: t("Delivering") },
                      { value: "DELIVERED", label: t("Delivered") },
                      { value: "REFUND_REQUEST", label: t("Refund request") },
                      {
                        value: "REFUND_APPROVED",
                        label: t("Refund approved"),
                      },
                      {
                        value: "REFUND_REJECTED",
                        label: t("Refund rejected"),
                      },
                      { value: "CANCEL_ORDER", label: t("Cancel order") },
                    ]}
                    height={40}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-[16px] mt-[20px]">
              {query.type === tabType[0].value && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">Type</div>
                  <SelectCustom
                    placeholder={t("All")}
                    value={query.voucherType}
                    onChange={(e) => {
                      setSearchParams((params) => {
                        params.set("voucherType", e)
                        return params
                      })
                    }}
                    options={[
                      { value: "", label: t("All") },
                      { value: "VOUCHER_NEW", label: t("Voucher") },
                      { value: "COUPON", label: t("Coupon") },
                    ]}
                    height={40}
                  />
                </div>
              )}
            </div>

            <div className="flex justify-between mt-[39px]">
              <div className="text-[13px] text-[#444444]">
                {t("총 N건", {
                  number: pageable?.totalElement ?? 0,
                })}
              </div>
              <div className="text-[13px] text-[#444444]">
                {!query.timeFilter ? (
                  t("All")
                ) : (
                  <>
                    {dayjs()
                      .subtract(TIME_NUMBER[query.timeFilter] || 12, "month")
                      .format("YYYY.MM.DD")}{" "}
                    ~ {dayjs().format("YYYY.MM.DD")}
                  </>
                )}
              </div>
            </div>
          </div>

          {transactionHistory &&
            transactionHistory?.length > 0 &&
            transactionHistory?.map((item: any, i: number) => (
              <div className="pb-[45px]" key={i}>
                <div
                  className="px-[24px] py-[17px] bg-[#F5F5F5] mb-[14px] flex justify-between items-center"
                  onClick={() => {
                    getDetailOrderHistory(item?.id)
                  }}
                >
                  <div className="font-pretendard700">{item?.brandName}</div>

                  <div className="flex">
                    <div className="inline-block text-[13px]">
                      <ConvertStatusPaymentHtml
                        status={item?.orderProductStatus}
                      />
                    </div>
                    <div className="bg-black  h-[24px]">
                      <Button
                        onClick={() => {}}
                        className={`flex justify-center ${convertStatusBg(
                          item?.status,
                        )} text-white text-[10px] w-full font-pretendard700 h-full px-[5px] disabled:text-gray2 cursor-default`}
                      >
                        {t(item?.status)}
                      </Button>
                    </div>
                  </div>
                </div>
                {query.type !== tabType[2].value && (
                  <div className="flex gap-[35px] px-[24px] leading-none">
                    <div className="flex flex-col gap-[15px]">
                      {["제품 이름", "결제 일시", "결제 금액", "결제 상태"].map(
                        (itemChild, index) => (
                          <div
                            className="text-[14px] text-[#666666]"
                            key={index}
                          >
                            {t(itemChild)}
                          </div>
                        ),
                      )}
                    </div>
                    <div className="flex flex-col gap-[15px]">
                      {[
                        item?.voucherName,
                        moment(item?.createdTime).format("YYYY.MM.DD"),
                        `${formatPrice(item?.totalValue)}원`,
                        convertStatus(item?.status),
                      ].map((itemChild, index) => (
                        <div
                          className="font-pretendard500 text-[14px] min-h-[14px]"
                          key={index}
                        >
                          {itemChild}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {query.type === tabType[2].value && (
                  <div className="flex gap-[35px] px-[24px] leading-none">
                    <div className="flex flex-col gap-[15px]">
                      {["상품명", "구매일", "Quantity", "결제 금액"].map(
                        (itemChild, index) => (
                          <div
                            className="text-[14px] text-[#666666]"
                            key={index}
                          >
                            {t(itemChild)}
                          </div>
                        ),
                      )}
                    </div>
                    <div className="flex flex-col gap-[15px]">
                      {[
                        item?.voucherName,
                        moment(item?.createdTime).format("YYYY.MM.DD"),
                        item?.quantity,
                        `${formatPrice(item?.totalValue)}원`,
                      ].map((itemChild, index) => (
                        <div
                          className="font-pretendard500 text-[14px] min-h-[14px]"
                          key={index}
                        >
                          {itemChild}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}

          {transactionHistory?.length >= 0 && pageable?.hasNext && (
            <div className="w-full bg-[#fff]">
              <div
                className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff] mx-[24px]"
                onClick={() => {
                  if (pageable?.hasNext) {
                    handleGetTransactionHistory(true, Number(pageable.page) + 1)
                  }
                }}
              >
                더 보기
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MyOrderHistory
