import { useEffect, useRef, useState } from "react"
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
// import OrderInput from "src/components/Input/OrderInput"
// import TransactionSucccess from "src/components/Modal/PaymentModal/TransactionSuccess"
import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import Selection from "src/components/Selection"
import { useAuth } from "src/customHook/useAuth"
import useIsMobile from "src/customHook/useIsMobile"
import { Voucher } from "src/models"
import { MoneyConvert } from "src/util/format"
import { sha256Hash } from "src/util/sha256Hash"
import CreatePayInicis from "./CreatePayInicis"
// import Inicis from "./Inicis"
import { useAppDispatch } from "src/app/hooks"
import EvenEnd from "src/components/Modal/PaymentModal/EventEnd"
import MaximumPurchaseLimit from "src/components/Modal/PaymentModal/MaximumPurchaseLimit"
import MaxQtyModal from "src/components/Modal/PaymentModal/MaxQtyModal"
import { closeLoading, openLoading } from "src/features/loading/loadingSlice"

import { formatPrice } from "src/util/formatPrice"

import TextareaAutosize from "react-textarea-autosize"
import { getDataDetailShop, paymentKakao } from "src/api/shopApi"
import TransactionShopSuccess from "src/components/Modal/PaymentModal/TransactionShopSuccess"
import { UrlInternal } from "src/constant/endpointRoute"
import ModalChangeAddress from "./ModalChangeAddress"
import CSInput from "src/components/Input/Index"

const testURL = "https://stgstdpay.inicis.com/stdjs/INIStdPay.js"
const releaseURL = "https://stdpay.inicis.com/stdjs/INIStdPay.js"

function BrandShopOrder() {
  const dispatch = useAppDispatch()
  const mobilePurchaseRef: any = useRef()
  const { isMobile } = useIsMobile(768)

  const { state } = useLocation()
  const { user } = useAuth()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(state?.quantity || 1)
  const [voucherDetail, setVoucherDetail] = useState<Voucher>()
  const [paramsInicis, setParamsInicis] = useState<any>()
  const [openEvenEnd, setOpenEvenEnd] = useState<boolean>(false)
  const [openMaximumPurchaseLimit, setOpenMaximumPurchaseLimit] =
    useState<boolean>(false)
  const [openMaxQtyModal, setOpenMaxQtyModal] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [paymentType, setPaymentType] = useState("CREDIT")
  // const [loading, setLoading] = useState<boolean>(false)
  const [selectionData, setSelectionData] = useState({
    seenTerms: false,
    seenInfo: false,
    // seenVIPmember: true,
  })
  const ref = useRef<any>()
  const navigate = useNavigate()
  const { id } = useParams()

  const [isModalChangeAddress, setIsModalChangeAddress] = useState(false)
  const [dataModalChangeAddress, setdataModalChangeAddress] = useState<any>({})
  const [contentNoteSeller, setContentNoteSeller] = useState("")
  const ADDRESS_JEJU = dataModalChangeAddress?.sidoEnglish === "Jeju-do"
  const PRICE_END =
    Number(voucherDetail?.price) * quantity +
    (ADDRESS_JEJU ? Number(voucherDetail?.costsForJeju || 0) : 0) +
    Number(voucherDetail?.shippingCost || 0)

  console.log("user", user)

  const onFinish = () => {
    // setLoading(true)
    dispatch(openLoading())

    switch (paymentType) {
      case "CREDIT":
        // handlePaymentCreditCard2()
        handlePaymentTossPay()
        break
      case "KAKAO":
        handlePaymentKakao()
        break
      case "NAVER":
        handlePaymentNaver()
        break
      default:
        break
    }
  }

  const getVoucherDetail = async () => {
    const res: any = await getDataDetailShop({
      id: Number(id),
    })
    if (!res.result) {
      navigate(-1)
    } else {
      setVoucherDetail(res.data)
    }
  }

  useEffect(() => {
    if (searchParams.get("errorCode") === "NOT_ENOUGH_VOUCHER") {
      setOpenMaxQtyModal(true)
      return
    }

    if (searchParams.get("errorCode") === "ENDED_PURCHASE") {
      setOpenEvenEnd(true)
      return
    }
    if (
      searchParams.get("errorCode") === "OUT_OF_PURCHASE" ||
      searchParams.get("errorCode") === "MORE_THAN_SPECIFIED_QUANTITY"
    ) {
      setOpenMaximumPurchaseLimit(true)
      return
    }
  }, [searchParams])

  useEffect(() => {
    getVoucherDetail()
  }, [id])

  useEffect(() => {
    async function fetchParams() {
      const mid = "SIRmoafrnd"
      const signKey = "dWd6UGNvVmoyVjA1NG1INUZZSTdGQT09"
      const oid = `${id}_${quantity}_${Date.now().toString(36)}`
      const price = Number(voucherDetail?.voucherPrice) * quantity

      let hashSignature = await sha256Hash(
        `oid=${oid}&price=${price}&timestamp=${Date.now()}`,
      )
      let hashVerification = await sha256Hash(
        `oid=${oid}&price=${price}&signKey=${signKey}&timestamp=${Date.now()}`,
      )
      let hashMkey = await sha256Hash(signKey)

      const pgRequestParameters = {
        mid: mid,
        price: price,
        oid: oid,
        goodsname: voucherDetail?.voucherName,
        currency: "WON",
        buyername: user?.displayName,
        buyertel: user?.phoneNumber,
        buyeremail: user?.email,
        timestamp: Date.now(),
        signature: hashSignature,
        verification: hashVerification,
        mkey: hashMkey,
        returnUrl: "http://127.0.0.1:4000/group-buying/order/195",
        closeUrl: "http://127.0.0.1:4000/group-buying/order/195",
        gopaymethod: "Card:DirectBank:VBank:HPP",
      }
      setParamsInicis(pgRequestParameters)
      console.log("pgRequestParameters", pgRequestParameters)
    }

    if (id && quantity && voucherDetail && user) {
      fetchParams()
    }
  }, [id, quantity, voucherDetail, user])

  const handlePaymentKakao = async () => {
    const data: any = {
      accessToken: user?.accessToken,
      orderId: voucherDetail?.id,
      quantity: quantity,
      itemName: voucherDetail?.voucherName,
      totalAmount: PRICE_END,
      phoneNumber:
        dataModalChangeAddress?.phone || voucherDetail?.userPhoneNumber || "",
      name: voucherDetail?.userName,
      addressName:
        dataModalChangeAddress?.address || voucherDetail?.address || "",
      addressDescription:
        dataModalChangeAddress?.addressDescription ||
        voucherDetail?.addressDescription ||
        "",
      addressCode: dataModalChangeAddress?.sidoEnglish,
      noteToSeller: contentNoteSeller,
      shippingFee: voucherDetail?.shippingCost || 0,
    }

    try {
      const resp: any = await paymentKakao(data)
      console.log("paymentRequest", resp)
      if (resp?.error?.errorCode === "ENDED_PURCHASE") {
        setOpenEvenEnd(true)
        return
      }
      if (
        resp?.error?.errorCode === "OUT_OF_PURCHASE" ||
        resp?.error?.errorCode === "MORE_THAN_SPECIFIED_QUANTITY"
      ) {
        setOpenMaximumPurchaseLimit(true)
        return
      }
      if (resp?.error?.errorCode === "NOT_ENOUGH_VOUCHER") {
        setOpenMaxQtyModal(true)
        return
      }
    } catch (error) {
      ref.current && ref.current.submit()
      console.error("Error during payment request:", error)
    }

    // setLoading(false)
    dispatch(closeLoading())

    // setOpenMaxQtyModal(true)
  }

  const handlePaymentNaver = async () => {
    // setLoading(false)
    dispatch(closeLoading())
  }

  const handleOnSelectChange = (data: Record<string, boolean>) => {
    setSelectionData((prev) => ({ ...prev, ...data }))
  }

  const handlePaymentTossPay = () => {
    navigate(UrlInternal.TOSS_PRODUCT + "/" + voucherDetail?.id, {
      state: {
        quantity: quantity,
        gpId: voucherDetail?.id,
        price: PRICE_END,
        shippingFee: voucherDetail?.shippingCost || 0,
        phoneNumber:
          dataModalChangeAddress?.phone || voucherDetail?.userPhoneNumber || "",
        name: voucherDetail?.userName,
        addressName:
          dataModalChangeAddress?.address || voucherDetail?.address || "",
        addressDescription:
          dataModalChangeAddress?.addressDescription ||
          voucherDetail?.addressDescription ||
          "",
        noteToSeller: contentNoteSeller,
        addressCode: dataModalChangeAddress?.sidoEnglish,
        orderName: voucherDetail?.brandName + "-" + voucherDetail?.voucherName,
      },
    })
    dispatch(closeLoading())
  }

  const checkDisabledBtnSubmit = () => {
    const addr = dataModalChangeAddress?.address || voucherDetail?.address

    return (
      !selectionData?.seenTerms ||
      !selectionData?.seenInfo ||
      (!addr && voucherDetail?.productType === "PRODUCT")
    )
  }

  const handleProductType = (data?: string) => {
    if (data === "VOUCHER") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#EEBA00] inline-block">
          VOUCHER
        </span>
      )
    }
    if (data === "COUPON") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#40C003] inline-block">
          COUPON
        </span>
      )
    }
    if (data === "PRODUCT") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#0098EE] inline-block">
          PRODUCT
        </span>
      )
    }
    if (data === "E_CONTENT") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#7B61FF] inline-block">
          E-CONTENT
        </span>
      )
    }

    return
  }

  console.log("dataModalChangeAddress", dataModalChangeAddress)

  return (
    <>
      <TransactionShopSuccess />

      <div className="text-[24px] font-pretendard700 pb-[24px] border-b-[2px] border-[#000000] leading-none">
        주문서 작성
      </div>
      <div className="mt-[24px] relative z-10">
        <div className="flex gap-[59px] md:flex-row flex-col">
          <div className="flex-1">
            <p className="text-[18px] font-pretendard700 text-primary mt-[39px] mb-[32px] leading-none">
              {t("구매 정보")}
            </p>
            <div className="mb-[40px] md:mb-[32px] border-b-solid border-primary border-b-2 md:border-b md:border-gray5 flex flex-row-reverse md:flex-row justify-between	md:justify-normal">
              <div className="mr-[0] md:mr-[18px] mb-[45px]">
                <div className="border border-solid w-[64px] h-[64px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700 relative">
                  <img
                    className="w-full h-full object-cover aspect-square"
                    src={voucherDetail?.image}
                    alt="img"
                  />

                  <div className="absolute bottom-0 right-0 leading-[12px]">
                    {handleProductType(voucherDetail?.productType)}
                  </div>
                </div>
              </div>
              <div className="block">
                <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none mb-[8px] md:mb-[16px]">
                  {voucherDetail?.brandName}
                </p>
                <div className="mb-[12px] md:mb-[32px] leading-none flex items-center">
                  <div className="text-[16px] md:text-[18px] font-pretendard700 text-primary">
                    {voucherDetail?.voucherName}
                  </div>
                </div>
                <div className="line-through text-[#767676] text-[13px] pb-[8px] mt-0 md:mt-[-20px]">
                  {formatPrice(Number(voucherDetail?.voucherPrice) * quantity)}
                  원
                </div>
                <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                  <span className="text-primaryRed">
                    [
                    {formatPrice(
                      100 -
                        (Number(voucherDetail?.price) /
                          Number(voucherDetail?.voucherPrice)) *
                          100,
                    )}
                    %]{" "}
                  </span>
                  <span className="font-pretendard700">
                    {MoneyConvert(Number(voucherDetail?.price)) || 0}
                    {t("원")}{" "}
                  </span>
                  / 수량 {MoneyConvert(quantity || 0)}개
                </span>
              </div>
            </div>
            {voucherDetail?.productType === "PRODUCT" && (
              <>
                <div className="border-b border-[#A0A0A0] pb-[38px] leading-[100%]">
                  <div className="text-[18px] font-pretendard800 mb-[34px]">
                    Shipping information
                  </div>

                  <div className="flex flex-col gap-[24px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[14px]">Shipping fee</div>
                      <div className="text-[14px] font-pretendard700">
                        {formatPrice(voucherDetail?.shippingCost ?? 0)}원
                      </div>
                    </div>
                    {ADDRESS_JEJU && (
                      <div className="flex justify-between items-center">
                        <div className="text-[14px]">
                          {t("Additional costs for Jeju Island")}
                        </div>
                        <div className="text-[14px] font-pretendard700">
                          {Number(voucherDetail?.costsForJeju || 0)}원
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border-b border-[#A0A0A0] pb-[38px] leading-[100%] mt-[36px] mb-[40px]">
                  <div className="flex justify-between items-center mb-[30px]">
                    <div className="text-[18px] font-pretendard800 ">
                      구매자 정보
                    </div>
                    <div
                      className="cursor-pointer text-center px-[10px] h-[25px] leading-[25px] border border-[#000000] text-[12px]"
                      onClick={() => setIsModalChangeAddress(true)}
                    >
                      배송지 정보 등록
                    </div>
                  </div>

                  <div className="flex flex-col gap-[24px]">
                    <div className="flex justify-between items-center">
                      <div className="text-[14px]">받는 사람</div>
                      <div className="text-[14px] font-pretendard700">
                        {voucherDetail?.userName}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="text-[14px]">연락처</div>
                      <div className="text-[14px] font-pretendard700">
                        {dataModalChangeAddress?.phone ||
                          voucherDetail?.userPhoneNumber}
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="text-[14px]">
                        배송지 주소 <span className="text-[red]">*</span>
                      </div>
                      <div className="text-[14px] font-pretendard700">
                        {/* {dataModalChangeAddress?.address} */}
                        {dataModalChangeAddress?.address ||
                          voucherDetail?.address}{" "}
                        {dataModalChangeAddress?.addressDescription ||
                          voucherDetail?.addressDescription}
                      </div>
                    </div>
                  </div>

                  <div className="mt-[26px] mb-[10px] text-[14px]">
                    배송 메모
                  </div>
                  <TextareaAutosize
                    className="w-full focus:outline-none placeholder:text-[#A3A3A3] resize-none border border-[#A0A0A0] py-[15px] px-[20px]"
                    placeholder="배송 메모가 필요한 경우 입력해 주세요"
                    onChange={(e) => setContentNoteSeller(e.target.value)}
                    value={contentNoteSeller}
                    minRows={4}
                    maxLength={2000}
                  />
                </div>
              </>
            )}

            <div className="pb-[60px] md:pb-0 border-b-solid border-primary border-b-2 md:border-none md:border-gray5">
              <div className="flex mb-[22px]">
                <p className="text-[18px] font-pretendard700 text-primary leading-none">
                  {t("결제 방법")}
                </p>
              </div>
              <div className="flex gap-[20px]">
                <Button
                  theme="main"
                  variant={paymentType === "CREDIT" ? "primary" : "outline"}
                  labelSize={14}
                  height={44}
                  onClick={() => {
                    setPaymentType("CREDIT")
                  }}
                >
                  {t("신용/체크카드")}
                </Button>

                {/* <Button
                  variant={paymentType === "KAKAO" ? "primary" : "outline"}
                  theme="main"
                  labelSize={14}
                  height={44}
                  onClick={() => {
                    setPaymentType("KAKAO")
                  }}
                >
                  {t("카카오페이")}
                </Button> */}
              </div>
            </div>
          </div>
          <div className="md:w-[480px]">
            <div className="w-full md:bg-gray3 md:p-[40px] md:pt-[32px]">
              <div className="flex items-center justify-between border-b border-b-solid md:border-none pb-[32px] md:pb-[0px] md:mb-[60px]">
                <p className="text-[18px] font-pretendard700 text-primary leading-none">
                  {t("결제 금액")}
                </p>
              </div>

              <div className="flex items-center mb-[8px] md:mb-[24px] justify-between mt-[24px] md:mt-[40px]">
                <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                  {t("총 상품 금액")}
                </p>
                <p className="text-[12px] md:text-[14px] font-pretendard700 text-primary leading-none">
                  {MoneyConvert(
                    Number(voucherDetail?.voucherPrice) * quantity,
                  ) || 0}
                  {t("원")}
                </p>
              </div>
              {voucherDetail?.productType === "PRODUCT" && (
                <div className="flex items-center mb-[8px] md:mb-[24px] justify-between ">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("Shipping information")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard700 text-primary leading-none">
                    {formatPrice(
                      Number(voucherDetail?.shippingCost || 0) +
                        (ADDRESS_JEJU
                          ? Number(voucherDetail?.costsForJeju || 0)
                          : 0),
                    )}
                    원
                  </p>
                </div>
              )}

              <div className="flex items-center pb-[16px] md:pb-[24px] justify-between border-b border-b-solid md:border-none">
                <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                  {t("할인율")}
                </p>
                <p className="text-[12px] md:text-[14px] font-pretendard700 text-[#C70039] leading-none">
                  -
                  {formatPrice(
                    100 -
                      (Number(voucherDetail?.price) /
                        Number(voucherDetail?.voucherPrice)) *
                        100,
                  )}
                  {t("%")}
                </p>
              </div>

              <div className="flex items-center justify-between mt-[15px] md:mt-[0] pb-[40px] border-b border-b-2 border-b-solid border-primary">
                <p className="text-[16px] font-pretendard700 text-primary leading-none">
                  {t("총 결제 금액")}
                </p>
                <p className="text-[16px] md:text-[24px] font-pretendard700 text-primary leading-none">
                  {formatPrice(PRICE_END)}
                  {t("원")}
                </p>
              </div>

              <div className="mt-[24px] md:mt-[38px]">
                <div className="mb-[15px] pb-[19px] md:pb-[0] border-b border-b-solid md:border-none">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange({
                        seenTerms: data.already,
                        seenInfo: data.already,
                      })
                    }}
                    selectOption={[
                      {
                        label: t(
                          "주문 내용을 확인 했으며, 아래 내용에 모두 동의합니다.",
                        ),
                        name: "already",
                        defaultChecked: Object.values(selectionData).every(
                          (item) => !!item,
                        ),
                      },
                    ]}
                  />
                </div>
                <div className="mb-1">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange(data)
                    }}
                    selectOption={[
                      {
                        label: (
                          <>
                            {t("(필수) 이용약관 동의")}{" "}
                            <Link
                              to={"/terms-of-use"}
                              className="!underline hover:font-pretendard700 hover:text-primary"
                              target={isMobile ? "_self" : "_blank"}
                            >
                              {t("보기")}
                            </Link>
                          </>
                        ),
                        name: "seenTerms",
                        defaultChecked: selectionData.seenTerms,
                      },
                    ]}
                  />
                </div>

                <div className="mb-1">
                  <Selection
                    width={isMobile ? 16 : 23}
                    height={isMobile ? 16 : 23}
                    checkmarkSize={isMobile ? 12 : 16}
                    labelStyle={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000",
                      fontFamily: "Pretendard-400",
                    }}
                    onSelectChange={(data) => {
                      handleOnSelectChange(data)
                    }}
                    selectOption={[
                      {
                        label: (
                          <>
                            {t("(필수) 개인정보 수집/이용 동의")}{" "}
                            <Link
                              to={"/privacy-policy"}
                              className="!underline hover:font-pretendard700 hover:text-primary"
                              target={isMobile ? "_self" : "_blank"}
                            >
                              {t("보기")}
                            </Link>
                          </>
                        ),
                        name: "seenInfo",
                        defaultChecked: selectionData.seenInfo,
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex font-pretendard500 gap-x-[30px] pt-[32px] fixed left-0 bottom-0 md:relative w-full">
                <Button
                  onClick={onFinish}
                  className="flex bg-black text-white text-[20px] md:text-[18px] w-full font-pretendard700 h-[70px] md:h-[56px] px-[16px] disabled:text-gray2 disabled:bg-gray6"
                  disabled={checkDisabledBtnSubmit()}
                >
                  {t("원 결제하기", {
                    voucherPrice: formatPrice(PRICE_END),
                  })}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <form
          ref={ref}
          action={`${import.meta.env.VITE_API_URL}/payment/request-product-pay`}
          method="post"
          hidden
        >
          <input type="text" name="accessToken" value={user?.accessToken} />
          <input type="text" name="orderId" value={voucherDetail?.id} />

          <input type="text" name="quantity" value={quantity} />
          <input
            type="text"
            name="itemName"
            value={voucherDetail?.voucherName}
          />
          <input type="text" name="totalAmount" value={PRICE_END} />

          <input
            type="text"
            name="phoneNumber"
            value={
              dataModalChangeAddress?.phone ||
              voucherDetail?.userPhoneNumber ||
              ""
            }
          />
          <input type="text" name="name" value={voucherDetail?.userName} />
          <input
            type="text"
            name="addressCode"
            value={dataModalChangeAddress?.sidoEnglish}
          />
          <input
            type="text"
            name="addressName"
            value={
              dataModalChangeAddress?.address || voucherDetail?.address || ""
            }
          />
          <input
            type="text"
            name="addressDescription"
            value={
              dataModalChangeAddress?.addressDescription ||
              voucherDetail?.addressDescription ||
              ""
            }
          />
          <input type="text" name="noteToSeller" value={contentNoteSeller} />
          <input
            type="text"
            name="shippingFee"
            value={voucherDetail?.shippingCost || 0}
          />
        </form>

        <ModalChangeAddress
          isOpen={isModalChangeAddress}
          handleClose={() => setIsModalChangeAddress(false)}
          getDataModalChangeAddress={(e) => setdataModalChangeAddress(e)}
          data={voucherDetail}
        />
        <CreatePayInicis params={paramsInicis} refMobile={mobilePurchaseRef} />
        <EvenEnd
          isOpen={openEvenEnd}
          handleClose={() => {
            setOpenEvenEnd(false)
            navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${id}`)
          }}
        />
        <MaximumPurchaseLimit
          isOpen={openMaximumPurchaseLimit}
          handleClose={() => {
            setOpenMaximumPurchaseLimit(false)
            navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${id}`)
          }}
        />
        <MaxQtyModal
          isOpen={openMaxQtyModal}
          handleClose={() => {
            setOpenMaxQtyModal(false)
            navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${id}`)
          }}
        />
      </div>
    </>
  )
}

export default BrandShopOrder
