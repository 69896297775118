import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"
import { AxiosRequestConfig } from "axios"

export const brandApi = {
  subcribeBrand(payload: { brandId: string | number }) {
    return api.request({
      url: ApiUrl.SUBCRIBE_BRAND,
      method: "POST",
      data: payload,
      tokenRequired: true,
    })
  },
}

export const getListFeed = async (params: {
  page?: number
  size?: number
  haveThumbnail?: string
  onlyOnePerBrand?: string
  notIncludesId?: number
  brandId?: number
  notIncludesBrandId?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_FEED,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const toggleLike = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.TOGGLE_LIKE,
    method: "POST",
    // tokenRequired: true,
    data: data,
  })
}
export const toggleLikeCommunity = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_TOGGLE_LIKE,
    method: "POST",
    // tokenRequired: true,
    data: data,
  })
}

export const toggleFollow = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.TOGGLE_FOLLOW,
    method: "POST",
    // tokenRequired: true,
    data: data,
  })
}

export const getDetailFeed = async (params: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_FEED,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const sucriberKakao = async (data: {
  brandId?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.SUCRIBER_KAKAO,
    method: "POST",
    // tokenRequired: true,
    data: data,
  })
}

export const brandMappingData = async (params: {
  k?: number | string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.BRAND_MAPPING_DATA,
    method: "GET",
    // tokenRequired: true,
    params: params,
  })
}

export const getMembershipDetailApi = async (params: {
  brandId?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.MEMBERSHIP_DETAIL,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const cancelRenewalsApi = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CANCEL_RENEWALS,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const cancelMembershipNormalApi = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CANCEL_MEMBERSHIP_NORMAL,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const cancelMembershipNormalNewApi = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CANCEL_MEMBERSHIP_NORMAL_NEW,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const getCustomerIdToss = async (params: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CUSTOMER_ID_TOSS,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const checkNormalMembership = async (params: {
  brandName?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CHECK_NORMAL_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const ListBannerBrand = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_BANNER_BRAND,
    method: "GET",
  })
}

export const registerMembershipFree = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.REGISTER_MEMBERSHIP_FREE,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}
