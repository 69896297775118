import { useTranslation } from "react-i18next"
import "react-tooltip/dist/react-tooltip.css"
import "../style.scss"
import bannerDefault from "@assets/images/brand_detail/banner_default.png"
import bannerDefaultPlaceholder from "@assets/images/brand_detail/banner_default_placeholder.png"
import HeartCheck from "@assets/icons/brand_detail/heart_check.svg"
import Notifications from "@assets/icons/brand_detail/notifications.svg"
import { useEffect, useState } from "react"
import RegisterMembership from "src/components/Modal/Brand/RegisterMembership"
import CloseIcon from "@assets/icons/close-popup-new.svg"
import {
  getMembershipDetailApi,
  registerMembershipFree,
  toggleFollow,
} from "src/api/brandApi"
import InfoRegisterMembership from "src/components/Modal/Brand/InfoRegisterMembership"
import Modal3rdPartMembership from "src/components/Modal/Brand/Modal3rdPartMembership"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import { useAppSelector } from "src/app/hooks"
import { useAuth } from "src/customHook/useAuth"

interface BrandHeaderProps {
  id?: number
  logoImage: string
  banner: string
  brandName: string
  brandSlogan: string
  data: any
  getNewDataMembershipDetail: () => void
}

const BrandHeader: React.FC<BrandHeaderProps> = ({
  id,
  logoImage,
  banner,
  brandName,
  brandSlogan,
  data,
  getNewDataMembershipDetail,
}) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false)
  const [openModal3rd, setOpenModal3rd] = useState<boolean>(false)
  const [isFollow, setIsFollow] = useState(false)
  const [membershipDetail, setMembershipDetail] = useState<any>({})
  const navigate = useNavigate()
  const user: any = useAppSelector((state) => state.user)
  const { state } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isLogined } = useAuth()
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (data && searchParams.get("type") === "isRegisterMembership") {
      getDataMembershipDetail()
      setOpenModalInfo(true)
    }
  }, [searchParams, data])

  const getDataMembershipDetail = async () => {
    try {
      const resp: any = await getMembershipDetailApi({
        brandId: data?.id,
      })

      if (resp.result) {
        setMembershipDetail(resp.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (data) {
      setIsFollow(data?.doFollow)
    }
  }, [data])

  const handleFollow = async () => {
    try {
      const res: any = await toggleFollow({
        id: data?.id,
      })

      if (res.result) {
        setIsFollow((prev: boolean) => !prev)
      }
    } catch (error) {}
  }

  console.log("membershipDetail", membershipDetail)

  const handleRegisterMembershipFree = async () => {
    try {
      const resp: any = await registerMembershipFree({
        id: membershipDetail?.id,
      })
      if (resp?.result) {
        const dataRegister = resp?.data
        setOpenModalInfo(false)

        searchParams.set("status", "SUCCESS")
        searchParams.set("brandName", dataRegister?.brandName)
        searchParams.set("logoImage", dataRegister?.logoImage)
        setSearchParams(searchParams)

        getNewDataMembershipDetail()
      }
    } catch (error) {}
  }

  return (
    <div className="relative">
      <div className="w-full relative h-imgRatio_16_9">
        {banner && (
          <img
            src={bannerDefault}
            alt="default img"
            className="w-full h-full absolute top-0 left-0"
          />
        )}
        <img
          src={banner || bannerDefaultPlaceholder}
          alt="cover"
          className="object-contain w-full max-h-imgRatio_16_9"
        />
        <div className="absolute bg-primaryWhite left-[24px] md:left-[41px] bottom-0 translate-y-[50%] w-[96px] h-[96px] md:w-[160px] md:h-[160px] flex items-center justify-center overflow-hidden border-[1px] border-gray5">
          {logoImage ? (
            <img src={logoImage} alt="logoBrand" className="object-cover" />
          ) : (
            <p className="text-[14px]">{brandName}</p>
          )}
        </div>
      </div>
      <div className="md:absolute md:top-[74px] md:left-[40px] md:mt-0 mt-[80px] px-[24px] md:px-0">
        <p className="font-pretendard700 md:text-[48px] text-[20px] md:text-primaryWhite md:leading-[48px] leading-[20px] md:mb-[32px] mb-[16px]">
          {brandName}
        </p>
        {brandSlogan && (
          <p className="md:text-[16px] text-[12px] md:text-primaryWhite md:leading-[27.2px] leading-[19.2px] md:mb-[43px] mb-[24px] whitespace-pre-line">
            {/* <p className="max-w-[600px] md:text-[16px] text-[12px] md:text-primaryWhite md:leading-[27.2px] leading-[19.2px] md:mb-[43px] mb-[24px] whitespace-pre-line"> */}
            {brandSlogan}
          </p>
        )}
        <div className="flex gap-[16px] grp-btn">
          <button
            className={`h-[32px] ${
              isFollow ? "pl-[10px]" : "pl-[16px]"
            } pr-[16px] border-[1px] md:border-primaryWhite border-primary md:text-primaryWhite text-[14px] flex items-center justify-center gap-[8px]`}
            onClick={() => {
              if (!isLogined) {
                navigate({
                  pathname: "/auth",
                  search: `backUrl=${pathname}${search}`,
                })
              } else {
                handleFollow()
              }
            }}
          >
            {isFollow ? (
              <>
                <Notifications /> {t("구독중")}
              </>
            ) : (
              <>{t("구독")}</>
            )}
          </button>
          <button
            onClick={() => {
              if (!isLogined) {
                navigate({
                  pathname: "/auth",
                  search: `backUrl=${pathname}${search}`,
                })
              }

              if (!data?.subscriberId) {
                setOpenModalInfo(true)

                getDataMembershipDetail()
              }
              if (data?.subscriberId) {
                navigate(
                  `${UrlInternal.MY_MEMBERSHIP}/${
                    data?.subscriberId ?? data?.id
                  }`,
                  {
                    state: {
                      activeTab: 1,
                      subscriberId: data?.subscriberId,
                    },
                  },
                )
              }
            }}
            className={`h-[32px] ${
              data?.subscriberId ? "pl-[10px]" : "pl-[16px]"
            } pr-[16px] border-[1px] md:border-primaryWhite border-primary md:text-primaryWhite text-[14px] flex items-center justify-center gap-[8px] 
            sm:disabled:bg-[#ffffff4d] disabled:bg-[#eee] disabled:border-none sm:disabled:text-[#ffffff4d] disabled:text-[#fff]`}
            disabled={
              (!data?.subscriberId && !data?.hasNormalMembership) ||
              user?.currentUser?.userRole === "BRAND"
            }
          >
            {data?.subscriberId || data?.isMine ? (
              <>
                <HeartCheck /> {t("MY 멤버십")}
              </>
            ) : (
              "맴버십 가입"
            )}
          </button>
        </div>
      </div>

      <InfoRegisterMembership
        open={openModalInfo}
        data={membershipDetail}
        handleClose={() => setOpenModalInfo(false)}
        handleConfirm={() => {
          if (Number(membershipDetail?.sellingPrice) === 0) {
            handleRegisterMembershipFree()
          } else {
            setOpenModalInfo(false)
            setOpenModal(true)
          }
        }}
      />
      <RegisterMembership
        open={openModal}
        handleClose={() => setOpenModal(false)}
        data={membershipDetail}
        handleOpenModal3rdPart={() => setOpenModal3rd(true)}
      />
      <Modal3rdPartMembership
        data={membershipDetail}
        open={openModal3rd}
        handleClose={() => {
          setOpenModal3rd(false)
          setOpenModal(true)
        }}
      />
    </div>
  )
}

export default BrandHeader
