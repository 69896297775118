import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { getListFeed } from "src/api/brandApi"
import LikeFeed from "src/components/LikeFeed"
import { UrlInternal } from "src/constant/endpointRoute"
import useWindowSize from "src/customHook/useWindowSize"
import { handleDateNew } from "src/util/handleDate"
import ShowMoreText from "react-show-more-text"
import CntComment from "src/components/CntComment"
import lock from "@assets/images/brand_detail/lock.png"

interface Props {
  title?: string
  isHome?: boolean
}

function FeatureFeed({ title, isHome }: Props) {
  const { width }: any = useWindowSize()
  const isMobile = width < 640
  const [dataListFeedBig, setDataListFeedBig] = useState<any>({})
  const [dataListFeedSmall, setDataListFeedSmall] = useState<any>([])
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { state, pathname } = useLocation()

  console.log("state", state)

  useEffect(() => {
    if (state?.isHome) {
      // if ("scrollRestoration" in window.history) {
      //   window.history.scrollRestoration = "manual"
      // }
      // document.body.style.height = "2000px"
      // window.focus()
      // window.scrollTo(0, state?.positionScrollY)
      // document.documentElement.scrollTop = state?.positionScrollY
      // window.scrollTo(0, state?.positionScrollY)
    }
  }, [state])

  const getDataListFeed = async () => {
    try {
      const resFeedBig = await getListFeed({
        page: 1,
        size: 1,
        haveThumbnail: "1",
      })

      if (resFeedBig.data) {
        setDataListFeedBig(resFeedBig?.data?.datas?.[0])

        const resFeedSmall = await getListFeed({
          page: 1,
          size: 6,
          notIncludesId: resFeedBig?.data?.datas?.[0].id,
          onlyOnePerBrand: "1",
          notIncludesBrandId: resFeedBig?.data?.datas?.[0].brandId,
        })

        if (resFeedSmall.data) {
          setDataListFeedSmall(resFeedSmall?.data?.datas)

          const positionScrollY = sessionStorage.getItem("positionScrollY")
          if (positionScrollY && state?.currentPage) {
            setTimeout(() => {
              window.scrollTo(
                0,
                Number(sessionStorage.getItem("positionScrollY")),
              )
            }, 0)
          }
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDataListFeed()
  }, [])

  const handleCLickDetail = (data: any) => {
    if (!(data?.onlyMembership && !data?.content)) {
      navigate(`/${data?.brandId}`, {
        state: data,
      })
      // if (isMobile) {
      //   const scrollPosition = window.scrollY || window.pageYOffset
      //   sessionStorage.setItem("positionScrollY", scrollPosition.toString())

      //   navigate(`/${data.brandId}/${UrlInternal.FEED_DETAIL}/${data.id}`, {
      //     state: {
      //       currentPage: pathname,
      //     },
      //   })
      // } else {
      //   navigate(`/${data?.brandId}`, {
      //     state: data,
      //   })
      // }
    } else {
      navigate(`/${data?.brandId}`)
    }
    // if (!(data?.onlyMembership && !data?.content)) {
    //   if (isMobile) {
    //     navigate(`/${data.brandId}/${UrlInternal.FEED_DETAIL}/${data.id}`)
    //   } else {
    //     navigate(`/${data?.brandId}`, {
    //       state: data,
    //     })
    //   }
    // }
  }

  const handleReplaceContent = (content: string) => {
    return content
      ?.replaceAll(/<img[^>]*>/g, "")
      .replaceAll(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, "")
      .replaceAll(/\s*class="[^"]*"/g, "")
      .replaceAll(/\s*id="[^"]*"/g, "")
  }

  return (
    <div>
      <div className="sm:border-b-2 sm:mb-[48px] sm:pb-[22px] pb-[25px] flex justify-between border-b-[#000] items-end">
        <div className="sm:text-[24px] font-pretendard700 text-[18px]">
          {title ? t(title ?? "") : t("브랜드 소식")}
        </div>
      </div>

      <div className="lg:flex-row flex-col flex gap-[30px]">
        {dataListFeedBig && (
          <div className="lg:max-w-[380px] w-[100%] flex flex-col border border-[#eee] lg:border-none">
            <img
              src={
                dataListFeedBig?.thumbnail ?? dataListFeedBig?.brandThumbnail
              }
              alt="thumb"
              className="max-w-[100%] aspect-square w-[100%] object-cover cursor-pointer lg:border border-[#eee]"
              onClick={() => handleCLickDetail(dataListFeedBig)}
            />
            <div className="flex flex-col flex-1 px-[10px] pb-[10px] lg:p-0">
              <div className="lg:pt-[13px] pt-[16px] flex justify-between pb-[8px] items-center">
                <div
                  className="font-pretendard700 cursor-pointer"
                  onClick={() => handleCLickDetail(dataListFeedBig)}
                >
                  {dataListFeedBig?.brandName?.length > 30
                    ? `${dataListFeedBig?.brandName?.substring(0, 30)}...`
                    : dataListFeedBig?.brandName}
                </div>
                <div className="lg:block hidden text-[#757575] text-[14px]">
                  {handleDateNew(dataListFeedBig?.publishAt, i18n.language)}
                </div>
              </div>
              {dataListFeedBig?.onlyMembership && !dataListFeedBig?.content ? (
                <div className="flex items-center lg:mb-[15px] mb-[8px]">
                  <img
                    src={lock}
                    alt="lock"
                    className="inline-block pr-[5px]"
                  />
                  멤버십 전용 콘텐츠입니다.
                </div>
              ) : (
                <>
                  {dataListFeedBig?.content && (
                    <ShowMoreText
                      lines={3}
                      more={
                        <span style={{ color: "#757575" }}>{t("더 보기")}</span>
                      }
                      className="lg:mb-[15px] mb-[8px] md:text-[16px] text-[12px] break-words"
                      onClick={() => handleCLickDetail(dataListFeedBig)}
                      expanded={false}
                      truncatedEndingComponent={" ..."}
                      // keepNewLines
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: handleReplaceContent(
                            dataListFeedBig?.content,
                          ),
                        }}
                      ></div>
                      {/* {handleReplaceContent(dataListFeedBig?.content)} */}
                    </ShowMoreText>
                  )}
                </>
              )}
              <div className="lg:hidden block text-[#757575] text-[14px] pb-[10px]">
                {handleDateNew(dataListFeedBig?.publishAt, i18n.language)}
              </div>
              <div className="mt-auto leading-[10px] flex gap-[20px] items-center">
                <LikeFeed
                  count={dataListFeedBig?.cntLike}
                  active={dataListFeedBig?.doLike}
                  id={dataListFeedBig?.id}
                />
                <CntComment
                  count={dataListFeedBig?.cntComment}
                  noPointer={isHome}
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex-1">
          {/* <input
            autoFocus={state?.currentPage ? true : false}
            // ref={brandSiteRef}
            className="h-0 w-0"
          /> */}

          <div className="lg:gap-x-[20px] gap-x-[7px] flex flex-wrap gap-y-[36px]">
            {dataListFeedSmall?.map((dt: any, i: number) => (
              <div
                key={i}
                className={`flex md:gap-[20px] gap-[16px] md:flex-row flex-col
                ${
                  dataListFeedSmall.length !== i + 1 &&
                  dataListFeedSmall.length - 1 !== i + 1 &&
                  "lg:pb-[36px] lg:border-b-[#D4D4D4] lg:border-b"
                } 
                lg:w-[calc(50%_-_10px)] w-[calc(50%_-_3.5px)] max-md:border max-md:border-[#eee]`}
                // lg:w-[calc(50%_-_10px)] w-[calc(50%_-_3.5px)] border border-[#eee] lg:border-t-[#fff] lg:border-x-[#fff]`}
              >
                <div className="md:w-[124px] md:h-[124px] w-[100%] aspect-square lg:border border-[#eee]">
                  <img
                    src={dt?.thumbnail ?? dt?.brandThumbnail}
                    alt="thumb"
                    className="max-w-[100%] aspect-square object-cover cursor-pointer"
                    onClick={() => handleCLickDetail(dt)}
                  />
                </div>
                <div className="flex-1 flex flex-col md:w-[calc(100%-240px)] px-[10px] pb-[10px] lg:p-0">
                  <div className="flex justify-between">
                    <div
                      className="md:text-[16px] text-[12px] font-pretendard700 cursor-pointer"
                      onClick={() => handleCLickDetail(dt)}
                    >
                      {dt?.brandName?.length > 15
                        ? `${dt?.brandName?.substring(0, 15)}...`
                        : dt?.brandName}
                    </div>
                    <div className="lg:block hidden text-[14px] text-[#757575]">
                      {handleDateNew(dt?.publishAt, i18n.language)}
                    </div>
                  </div>
                  {dt?.onlyMembership && !dt?.content ? (
                    <div className="flex items-center md:mb-[15px] md:mt-[6px] my-[8px]">
                      <img
                        src={lock}
                        alt="lock"
                        className="inline-block pr-[5px] sm:h-auto h-[13px]"
                      />
                      <div className="sm:text-[16px] text-[12px]">
                        멤버십 전용 콘텐츠입니다.
                      </div>
                    </div>
                  ) : (
                    <>
                      <ShowMoreText
                        lines={3}
                        more={
                          <span style={{ color: "#757575" }}>
                            {t("더 보기")}
                          </span>
                        }
                        className="md:mb-[15px] md:mt-[6px] my-[8px] md:text-[14px] text-[12px] break-words"
                        onClick={() => handleCLickDetail(dt)}
                        expanded={false}
                        truncatedEndingComponent={" ..."}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: handleReplaceContent(dt?.content),
                          }}
                        ></div>
                      </ShowMoreText>
                    </>
                  )}
                  <div className="lg:hidden block text-[12px] text-[#757575] pb-[10px] mt-auto">
                    {handleDateNew(dt?.publishAt, i18n.language)}
                  </div>
                  <div className="sm:mt-auto mt-0 leading-[10px] flex gap-[20px] items-center">
                    <LikeFeed
                      count={dt?.cntLike}
                      active={dt?.doLike}
                      id={dt?.id}
                    />
                    <CntComment count={dt?.cntComment} noPointer={isHome} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  )
}

export default FeatureFeed
