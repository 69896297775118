import MainTitle from "src/components/MainTitle"
import BrandSlide from "./components/BrandSlide"
import Banner from "@assets/images/brand_page/banner_PC.jpg"
import BannerMobile from "@assets/images/brand_page/banner_mb.jpg"
import Banner2 from "@assets/images/brand_page/banner_2_PC.jpg"
import Banner2Mobile from "@assets/images/brand_page/banner_2_mb.jpg"
// import Banner from "@assets/images/brand_page/banner.png"
// import BannerMobile from "@assets/images/brand_page/banner-mobile.png"
// import Banner2 from "@assets/images/brand_page/banner2.png"
// import Banner2Mobile from "@assets/images/brand_page/banner2-mobile.png"
import FeatureFeed from "./components/FeatureFeed"
import HighlightProducts from "./components/HighLightProduct"
import useWindowSize from "src/customHook/useWindowSize"
import ListBrand from "./components/ListBrand"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import { useEffect, useState } from "react"
import FilterAllBrand from "./components/FilterAllBrand"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ListBannerBrand } from "src/api/brandApi"

const Brand = () => {
  const { width }: any = useWindowSize()
  const isMobile = width <= 475
  const isMobiles = width <= 1023
  const [isFilterAllBrand, setIsFilterAllBrand] = useState(false)
  const [dataFilterAllBrand, setDataFilterAllBrand] = useState({})
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dataBanner, setDataBanner] = useState([])

  useEffect(() => {
    ;(async function getDataBanner() {
      const { data } = await ListBannerBrand()

      if (data) {
        setDataBanner(data)
      }
    })()
  }, [])

  return (
    <div>
      {isMobiles && (
        <NavBarMb pageName={t("BRAND PAGE")} csGoback={() => navigate("/")} />
      )}
      <MainTitle>{t("BRAND PAGE")}</MainTitle>
      {!isFilterAllBrand && (
        <div className="lg:mt-[52px]">
          <BrandSlide data={dataBanner} />
        </div>
      )}
      <div>
        <ListBrand
          handleFilter={(e: any) => {
            setIsFilterAllBrand(true)
            setDataFilterAllBrand(e)
          }}
          isFilterAllBrand={isFilterAllBrand}
        />
      </div>
      {isFilterAllBrand ? (
        <FilterAllBrand
          dataFilter={dataFilterAllBrand}
          setIsFilterAllBrand={(e) => setIsFilterAllBrand(e)}
        />
      ) : (
        <div>
          <div>
            <img
              src={isMobile ? BannerMobile : Banner}
              alt="Banner"
              className="w-full cursor-pointer"
              onClick={() => window.open("https://pf.kakao.com/_QxoLtb")}
            />
          </div>
          <div className="container">
            <div className="sm:mt-[90px] sm:mb-[103px] mt-[50px] mb-[39px]">
              <FeatureFeed isHome />
            </div>
          </div>
          <img
            src={isMobile ? Banner2Mobile : Banner2}
            alt="Banner2"
            className="w-full cursor-pointer"
            onClick={() => window.open("https://themmt.me")}
          />
          <div className="container">
            <div className="sm:mt-[92px] sm:mb-[90px] mt-[60px] mb-[43px]">
              <HighlightProducts />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Brand
