import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { orderApi } from "src/api/orderApi"
import CommonModal from "src/components/Modal"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import {
  getBrandHistoryMembership,
  getBrandHistoryNormalMembership,
} from "src/services/Brand"
import { MoneyConvert } from "src/util/format"
import { formatPrice } from "src/util/formatPrice"

import SortIcon from "@assets/images/history_order/sort.png"
import { message } from "antd"
import Button from "src/components/Button"
import { UrlInternal } from "src/constant/endpointRoute"
import { convertStatusTextColor } from "src/util/convertStatusPayment"
import "./style.scss"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

const tabType = [
  {
    label: "프리미엄",
    value: "프리미엄",
  },
  {
    label: "일반",
    value: "일반",
  },
]

function HistoryOrderMembership() {
  const { state } = useLocation()
  const { t } = useTranslation()
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    timeFilter: string
    brandId: string
    totalValueSort: string
    paymentStatus: string
    membershipNameSort: string
    type: string
  }>({
    page: 1,
    size: 10,
    timeFilter: "",
    brandId: state?.brandId ?? "",
    totalValueSort: "",
    paymentStatus: "",
    membershipNameSort: "",
    type: "",
  })
  const [pageable, setPageable] = useState<any>({})
  const { isMobile } = useIsMobile(768)
  const [transactionHistory, setTransactionHistory] = useState<any>([])

  const [dataListBrand, setDataListBrand] = useState<any>([])
  const [isModalDetail, setIsModalDetail] = useState<any>({
    isVisible: false,
    record: {},
  })
  const navigate = useNavigate()
  const [isModalCancelOrder, setIsModalCancelOrder] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get("type")
  const [activeTab, setActiveTab] = useState(
    type
      ? type === "premium"
        ? tabType[0].value
        : tabType[1].value
      : state?.type
      ? state?.type === "order"
        ? tabType[0].value
        : tabType[1].value
      : tabType[0].value,
  )

  const getDataBrand = async () => {
    try {
      const { data }: any =
        activeTab === tabType[0].value
          ? await getBrandHistoryMembership({
              querySearch: "",
              filter: [],
              page: 1,
              size: 999999,
            })
          : await getBrandHistoryNormalMembership({
              querySearch: "",
              filter: [],
              page: 1,
              size: 999999,
            })

      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.datas.length; i++) {
          dataResp.push({
            value: data.datas[i].id,
            label: data.datas[i].brandName,
          })
        }

        setDataListBrand(dataResp)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDataBrand()
  }, [activeTab])

  const handleGetTransactionHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      let params = {
        // brandId: state?.brandId ?? null,
        // filterInOut: "OUT",
        ...queryTrans,
        page: page ?? queryTrans.page,
      }
      const { data }: any =
        activeTab === tabType[0].value
          ? await orderApi.getListOrder({ ...params, isGb: true })
          : await orderApi.getListOrderNormalMembership(params)
      if (data) {
        let dataResp = data.datas
        if (isLoadmore) {
          setTransactionHistory([...transactionHistory, ...dataResp])
        } else {
          setTransactionHistory(dataResp)
        }
        setPageable(data.pageable)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionHistory()
  }, [queryTrans, activeTab])

  const allTime = [
    { value: "", label: t("All") },
    { value: "1M", label: t("1개월") },
    { value: "3M", label: t("3개월") },
    { value: "6M", label: t("6개월") },
    { value: "1Y", label: t("1년") },
  ]

  const columns1: ColumnsType<any> = [
    {
      title: "구매일",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
    {
      title: "브랜드명",
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "12%",
    },
    {
      title: "형태",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {text === "GIFT" ? "선물발송" : "구매"}
          </p>
        )
      },
      width: "8%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>상품명</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setQueryTrans((prev) => ({
                    ...prev,
                    membershipNameSort:
                      queryTrans.membershipNameSort === "desc" ? "asc" : "desc",
                  }))
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "20%",
    },
    {
      title: "수량",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {text}
          </p>
        )
      },
      width: "6%",
    },
    {
      title: "결제상태",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            } ${convertStatusTextColor(text)}`}
          >
            {t(text)}
          </p>
        )
      },
      width: "9%",
    },
    {
      title: "결제방식",
      dataIndex: "결제방식",
      key: "결제방식",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "9%",
    },
    {
      // title: "결제 금액",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>결제 금액</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setQueryTrans((prev) => ({
                    ...prev,
                    totalValueSort:
                      queryTrans.totalValueSort === "desc" ? "asc" : "desc",
                  }))
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "totalValue",
      key: "totalValue",
      align: "center",
      render: (text, record) => (
        <p className=" text-primary font-pretendard400">
          {MoneyConvert(text)}원
        </p>
      ),
      width: "10%",
    },
    {
      title: "사용 시작일",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
    {
      title: "사용 종료일",
      dataIndex: "expiredTime",
      key: "expiredTime",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {moment(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "10%",
    },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: "구매일",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p
            className={` text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
    },
    {
      title: "브랜드명",
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>상품명</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setQueryTrans((prev) => ({
                    ...prev,
                    membershipNameSort:
                      queryTrans.membershipNameSort === "desc" ? "asc" : "desc",
                  }))
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {activeTab === tabType[0].value ? text : record?.membershipName}
          </p>
        )
      },
    },

    {
      title: "결제상태",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      align: "center",
      render: (text, record) => (
        <div className={`${convertStatusTextColor(text)} font-pretendard700`}>
          {t(text)}
        </div>
      ),
    },
    {
      // title: "결제 금액",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>결제 금액</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setQueryTrans((prev) => ({
                    ...prev,
                    totalValueSort:
                      queryTrans.totalValueSort === "desc" ? "asc" : "desc",
                  }))
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: "totalValue",
      key: "totalValue",
      align: "center",
      render: (text, record) => (
        <p className=" text-primary font-pretendard400">
          {MoneyConvert(
            activeTab === tabType[0].value ? text : record?.sellingPrice,
          )}
          원
        </p>
      ),
      // sorter: (a, b) => a.totalValue - b.totalValue,
      // sortOrder: orderDirection,
      // showSorterTooltip: false,
    },
  ]

  const dataSelectStatusPremium = [
    { value: "", label: t("All") },
    { value: "PAID", label: t("PAID") },
    { value: "OWNED", label: t("OWNED") },
    { value: "REQUESTED", label: t("REQUESTED") },
    { value: "REFUNDED", label: t("REFUNDED") },
  ]

  const dataSelectStatusNormal = [
    { value: "", label: t("All") },
    { value: "PAID", label: t("PAID") },
    // { value: "OWNED", label: t("OWNED") },
    { value: "REQUESTED", label: t("REQUESTED") },
    { value: "REFUNDED", label: t("REFUNDED") },
    // { value: "CANCEL", label: t("CANCEL") },
    { value: "CLOSE", label: t("CLOSE") },
    { value: "USED", label: t("USED") },
  ]

  const dataSelectStatus =
    activeTab === tabType[0].value
      ? dataSelectStatusPremium
      : dataSelectStatusNormal

  const dataSelectGift = [
    { value: "", label: t("All") },
    { value: "GB", label: t("구매") },
    { value: "GIFT", label: t("선물발송") },
  ]

  const convertStatus = (status: string) => {
    switch (status) {
      case "PAID":
        return "결제완료"
      case "OWNED":
        return "구매확정"
      case "REQUESTED":
        return "환불요청"
      case "REFUNDED":
        return "환불완료"

      default:
        return ""
    }
  }

  const getDetailOrderHistory = async (id: number) => {
    try {
      navigate(
        `/my-page/order-detail/${id}?type=${
          activeTab === tabType[0].value ? "order" : "normalMembership"
        }`,
      )
      // const { data } = await orderApi.getOrderDetail(id)
      // if (data) {
      //   setIsModalDetail({
      //     isVisible: true,
      //     record: data,
      //   })
      // }
    } catch (error) {}
  }

  const handleCancelOrder = async () => {
    try {
      const resp: any = await orderApi.cancelOrder(isModalDetail?.record?.id)
      console.log("resp", resp)

      setIsModalCancelOrder(false)

      if (resp.result) {
      } else {
        message.error(resp.error.message)
      }
    } catch (error) {}
  }

  return (
    <>
      {!isMobile ? (
        <>
          <CommonModal
            onCancel={() => {
              setIsModalCancelOrder(false)
            }}
            isOpen={isModalCancelOrder}
            className="modal-custom-cancel text-[#000] max-w-[380px]"
          >
            <div className="text-[20px] font-pretendard500">환불 요청</div>
            <div className="font-pretendard500 text-[16px] pt-[20px] pb-[9px]">
              환불 정책
            </div>
            <div className="text-[#757575]">
              <div>결제 후 14일 이내 브랜드가 결제 취소 가능</div>
              <div>결제 당일에 취소하면 월렛으로 적립금 즉시 환불</div>
              <div>결제일+1일부터 취소하는 건에 대해서는 적립금이 결</div>
              <div>제취소일+1일 오전 9시에 환불됨</div>
            </div>
            <div className="font-pretendard500 text-[15px] pt-[40px] pb-[10px] text-[#2D2D2D] text-center">
              환불을 요청하시겠어요?
            </div>
            <div className="flex gap-[10px]">
              <div
                className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
                onClick={() => setIsModalCancelOrder(false)}
              >
                취소
              </div>
              <div
                className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
                onClick={handleCancelOrder}
              >
                확인
              </div>
            </div>
          </CommonModal>

          <CommonModal
            onCancel={() => {
              setIsModalDetail({
                isVisible: false,
                record: {},
              })
            }}
            isOpen={isModalDetail.isVisible}
            className="modal-custom text-[#000] max-w-[380px]"
          >
            <div className="font-pretendard700 text-[20px] text-center">
              주문상세조회
            </div>
            <div className="py-[19px] text-[16px] font-pretendard700">
              주문 상품 정보
            </div>
            <div className="flex gap-[20px] pb-[30px] border-b border-b-[#D4D4D4]">
              <img
                src={isModalDetail?.record?.voucherImage}
                alt="img"
                className="w-[80px] h-[80px]"
              />
              <div className="flex-1">
                <div className="flex justify-between">
                  <div className="text-[#666] text-[12px]">
                    {isModalDetail?.record?.brandName}
                  </div>
                  <div
                    className={`font-pretendard700 text-[11px] text-center text-[#fff] h-[20px] leading-[20px] px-[5.5px]  ${
                      isModalDetail?.record?.status === "PAID" ||
                      isModalDetail?.record?.status === "OWNED"
                        ? "bg-[#539165]"
                        : "bg-[#C70039]"
                    }`}
                  >
                    {convertStatus(isModalDetail?.record?.status)}
                  </div>
                </div>
                <div className="pt-[2px] pb-[13px] text-[16px]">
                  {isModalDetail?.record?.voucherName}
                </div>
                <div className="pb-[8px] font-pretendard700">
                  {formatPrice(isModalDetail?.record?.totalValue)}원
                </div>
                <div>수량 {isModalDetail?.record?.gbQuantity}개</div>
              </div>
            </div>

            <div className="flex flex-col gap-[17px] py-[30px] border-b border-b-[#D4D4D4]">
              <div className="flex justify-between">
                <div className="text-[#484848]">멤버십 구분</div>
                <div className="font-pretendard700">
                  {isModalDetail?.record?.type}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[#484848]">멤버십 No.</div>
                <div className="font-pretendard700">
                  {isModalDetail?.record?.membershipNo?.length > 1 ? (
                    <>
                      {isModalDetail?.record?.membershipNo[0]}~
                      {
                        isModalDetail?.record?.membershipNo[
                          isModalDetail?.record?.membershipNo.length - 1
                        ]
                      }
                    </>
                  ) : (
                    isModalDetail?.record?.membershipNo?.[0]
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[#484848]">유효 기간</div>
                <div className="font-pretendard700">
                  {dayjs()
                    .subtract(TIME_NUMBER[queryTrans.timeFilter] || 12, "month")
                    .format("YYYY.MM.DD")}{" "}
                  ~ {dayjs().format("YYYY.MM.DD")}
                </div>
              </div>
            </div>
            <div className="py-[30px] border-b border-b-[#D4D4D4]">
              <div className="pb-[18px] text-[16px] font-pretendard700">
                구매자 정보
              </div>
              <div className="flex flex-col gap-[13px]">
                <div className="flex justify-between">
                  <div className="text-[#484848]">주문자</div>
                  <div className="font-pretendard700">
                    {isModalDetail?.record?.displayName}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-[#484848]">이메일주소</div>
                  <div className="font-pretendard700">
                    {isModalDetail?.record?.email}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-[#484848]">휴대전화번호</div>
                  <div className="font-pretendard700">
                    {isModalDetail?.record?.phoneNumber}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-[29px] pb-[48px]">
              <div className="pb-[20px] text-[16px] font-pretendard700">
                결제 정보
              </div>
              <div className="flex flex-col gap-[13px]">
                <div className="flex justify-between">
                  <div className="text-[#484848]">총 상품 금액</div>
                  <div className="font-pretendard700">
                    {isModalDetail?.record?.quantity}
                  </div>
                </div>
                {isModalDetail?.record?.discountPercentage && (
                  <div className="flex justify-between">
                    <div className="text-[#484848]">할인 금액</div>
                    <div className="font-pretendard700">
                      {formatPrice(isModalDetail?.record?.discountPercentage)}원
                    </div>
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="text-[16px] font-pretendard500">
                    총 결제 금액
                  </div>
                  <div className="font-pretendard700 text-[#C70039] text-[24px]">
                    {formatPrice(isModalDetail?.record?.totalValue)}원
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-[#484848]">결제 방법</div>
                  <div className="font-pretendard700">
                    {isModalDetail?.record?.paymentMethod}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="text-[#484848]">결제 일시</div>
                  <div className="font-pretendard700">
                    {moment(isModalDetail?.record?.createdTime).format(
                      "YYYY.MM.DD HH:mm",
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              theme="main"
              variant="outline"
              height={44}
              // width={155}
              onClick={() => {
                setIsModalCancelOrder(true)

                setIsModalDetail({
                  ...isModalDetail,
                  isVisible: false,
                })
              }}
              disabled={!isModalDetail?.record?.canCancel}
            >
              {t("환불 요청")}
            </Button>

            <div className="mt-[15px]">
              <div className="flex gap-[8px]">
                <div className="w-[3px] h-[3px] aspect-square bg-[#666666] rounded mt-[8px]"></div>
                <div className="text-[#666666] text-[12px]">
                  주문 취소 및 환불 신청은 결제 후 7일 이내에만 가능합니다.
                </div>
              </div>
              <div className="flex gap-[8px]">
                <div className="w-[3px] h-[3px] aspect-square bg-[#666666] rounded mt-[8px]"></div>
                <div className="text-[#666666] text-[12px]">
                  주문 취소 접수 후 결제 카드 승인취소까지 영업일 기준 약 3~7일
                  소요됩니다.
                </div>
              </div>
            </div>
          </CommonModal>
          <div className="md:container mt-[63px]">
            <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary">
              {t("멤버십 주문 내역")}
            </div>

            <div className="flex mt-[40px] mb-[39px]">
              {tabType.map((item, index) => (
                <div
                  className={`text-[#000] text-[18px] leading-[18px] cursor-pointer ${
                    index === 0
                      ? "pr-[16px] border-r border-r-[#D4D4D4]"
                      : "pl-[16px]"
                  } ${item.value === activeTab && "font-pretendard700"}`}
                  key={index}
                  onClick={() => {
                    setSearchParams((params) => {
                      params.set("type", index !== 0 ? "normal" : "premium")
                      return params
                    })
                    setActiveTab(item.value)
                    // setQueryTrans({
                    //   page: 1,
                    //   size: 10,
                    //   timeFilter: "",
                    //   brandId: state?.brandId ?? "",
                    //   totalValueSort: "",
                    //   paymentStatus: "",
                    //   membershipNameSort: "",
                    //   type: "",
                    // })
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>

            <div className="flex justify-between  mb-[17px]">
              <div className="flex gap-[22px]">
                {!state?.brandId && (
                  <div className="flex gap-[8px] items-center">
                    <div className="text-[14px]">브랜드</div>
                    <SelectCustom
                      placeholder={t("All status")}
                      value={queryTrans.brandId}
                      onChange={(e) => {
                        setQueryTrans((prev) => ({
                          ...prev,
                          brandId: e,
                        }))
                      }}
                      options={dataListBrand}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
                <div className="flex gap-[8px] items-center">
                  <div className="text-[14px]">기간</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.timeFilter}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: 1,
                        timeFilter: e,
                      }))
                    }}
                    options={allTime}
                    height={40}
                    width={155}
                  />
                </div>
                {activeTab === tabType[0].value && (
                  <div className="flex gap-[8px] items-center">
                    <div className="text-[14px]">형태</div>
                    <SelectCustom
                      placeholder={t("All status")}
                      value={queryTrans.type}
                      onChange={(e) => {
                        setQueryTrans((prev) => ({
                          ...prev,
                          page: 1,
                          type: e,
                        }))
                      }}
                      options={dataSelectGift}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
                <div className="flex gap-[8px] items-center">
                  <div className="text-[14px]">결제상태</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.paymentStatus}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: 1,
                        paymentStatus: e,
                      }))
                    }}
                    options={dataSelectStatus}
                    height={40}
                    width={155}
                  />
                </div>
              </div>
              <div className="flex gap-[28px]">
                <div className="text-[14px]">
                  {t("총 N건", {
                    number: pageable?.totalElement ?? 0,
                  })}
                </div>
                <div className="text-[14px]">
                  {!queryTrans.timeFilter ? (
                    t("All")
                  ) : (
                    <>
                      {dayjs()
                        .subtract(
                          TIME_NUMBER[queryTrans.timeFilter] || 12,
                          "month",
                        )
                        .format("YYYY.MM.DD")}{" "}
                      ~ {dayjs().format("YYYY.MM.DD")}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px]">
              <CsTable
                className="mb-[34px] table-history-order"
                columns={activeTab === tabType[0].value ? columns1 : columns2}
                dataSource={transactionHistory}
                // showHeader={false}
                onRow={(record, rowIndex) => {
                  console.log("record", record)
                  return {
                    onClick: () => {
                      getDetailOrderHistory(record?.id)
                    },
                  }
                }}
              />
              {transactionHistory && transactionHistory?.length > 0 && (
                <Pagination
                  showSizeChanger={false}
                  rootClassName="flex justify-center"
                  className="custom-pagination"
                  total={pageable?.totalElement}
                  pageSize={queryTrans?.size}
                  current={queryTrans?.page}
                  onChange={(page) => {
                    setQueryTrans((prev) => ({ ...prev, page }))
                  }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <NavBarMb
            pageName="멤버십 주문 내역"
            csGoback={() => navigate("/my-page")}
            // csGoback={() => navigate("/my-membership")}
          />
          <div className="flex mt-[17px]">
            {tabType.map((item, index) => (
              <div
                className={`text-[#000] text-[16px] flex-1 text-center border-b border-b-[#E5E5E5] pb-[12px] ${
                  item.value === activeTab &&
                  "font-pretendard700 border-b-[3px] border-b-[#000000]"
                }`}
                key={index}
                onClick={() => {
                  setSearchParams((params) => {
                    params.set("type", index !== 0 ? "normal" : "premium")
                    return params
                  })
                  setActiveTab(item.value)
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          <div className="px-[24px] mt-[20px] mb-[9px]">
            <div className="flex gap-[16px]">
              {!state?.brandId && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">브랜드</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.brandId}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        brandId: e,
                      }))
                    }}
                    options={dataListBrand}
                    height={40}
                  />
                </div>
              )}
              <div className="flex-1">
                <div className="pb-[10px] text-[14px]">기간</div>
                <SelectCustom
                  placeholder={t("All status")}
                  value={queryTrans.timeFilter}
                  onChange={(e) => {
                    setQueryTrans((prev) => ({
                      ...prev,
                      page: 1,
                      timeFilter: e,
                    }))
                  }}
                  options={allTime}
                  height={40}
                />
              </div>
            </div>

            <div className="flex gap-[16px] mt-[20px]">
              {activeTab === tabType[0].value && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">형태</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.type}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: 1,
                        type: e,
                      }))
                    }}
                    options={dataSelectGift}
                    height={40}
                  />
                </div>
              )}
              <div className="flex-1">
                <div className="pb-[10px] text-[14px]">결제상태</div>
                <SelectCustom
                  placeholder={t("All status")}
                  value={queryTrans.paymentStatus}
                  onChange={(e) => {
                    setQueryTrans((prev) => ({
                      ...prev,
                      page: 1,
                      paymentStatus: e,
                    }))
                  }}
                  options={dataSelectStatus}
                  height={40}
                />
              </div>
            </div>

            <div className="flex justify-between mt-[39px]">
              <div className="text-[13px] text-[#444444]">
                {t("총 N건", {
                  number: pageable?.totalElement ?? 0,
                })}
              </div>
              <div className="text-[13px] text-[#444444]">
                {!queryTrans.timeFilter ? (
                  t("All")
                ) : (
                  <>
                    {dayjs()
                      .subtract(
                        TIME_NUMBER[queryTrans.timeFilter] || 12,
                        "month",
                      )
                      .format("YYYY.MM.DD")}{" "}
                    ~ {dayjs().format("YYYY.MM.DD")}
                  </>
                )}
              </div>
            </div>
          </div>

          {transactionHistory &&
            transactionHistory?.length > 0 &&
            transactionHistory?.map((item: any, i: number) => (
              <div className="pb-[45px]" key={i}>
                <div
                  className="px-[24px] py-[17px] font-pretendard700 bg-[#F5F5F5] mb-[14px]"
                  onClick={
                    () => {
                      getDetailOrderHistory(item?.id)
                    }
                    // navigate(
                    //   `${UrlInternal.DETAIL_HISTORY_ORDER_MEMBERSHIP}/${
                    //     item?.id
                    //   }?type=${
                    //     activeTab === tabType[0].value
                    //       ? "order"
                    //       : "normalMembership"
                    //   }`,
                    // )
                  }
                >
                  {item?.brandName}
                </div>
                {activeTab !== tabType[0].value ? (
                  <div className="flex gap-[35px] px-[24px]">
                    <div className="flex flex-col gap-[15px]">
                      <div className="text-[14px] text-[#666666]">
                        {t("브랜드명")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("상품명")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("구매일")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("결제상태")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("결제 금액")}
                      </div>
                    </div>
                    <div className="flex flex-col gap-[15px]">
                      <div className="font-pretendard500 text-[14px]">
                        {item?.brandName}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {item?.membershipName}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {moment(item?.createdTime).format("YYYY.MM.DD HH:mm")}
                      </div>
                      <div
                        className={`font-pretendard500 text-[14px] min-h-[21px] ${convertStatusTextColor(
                          item?.paymentStatus,
                        )}`}
                      >
                        {t(item?.paymentStatus)}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {formatPrice(item?.sellingPrice)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-[35px] px-[24px]">
                    <div className="flex flex-col gap-[15px]">
                      <div className="text-[14px] text-[#666666]">
                        {t("구매일")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("브랜드명")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("형태")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("상품명")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("수량")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("결제상태")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("결제 금액")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("사용 시작일")}
                      </div>
                      <div className="text-[14px] text-[#666666]">
                        {t("사용 종료일")}
                      </div>
                    </div>
                    <div className="flex flex-col gap-[15px]">
                      <div className="font-pretendard500 text-[14px]">
                        {moment(item?.createdTime).format("YYYY.MM.DD HH:mm")}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {item?.brandName}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {item?.type === "GIFT" ? "선물발송" : "구매"}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {item?.voucherName}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {item?.quantity}
                      </div>
                      <div
                        className={`font-pretendard500 text-[14px] ${convertStatusTextColor(
                          item?.status,
                        )}`}
                      >
                        {t(item?.status)}
                      </div>
                      <div className="font-pretendard500 text-[14px]">
                        {formatPrice(item?.totalValue)}
                      </div>
                      <div className="font-pretendard500 text-[14px] min-h-[21px]">
                        {moment(item?.endTime).format("YYYY.MM.DD")}
                      </div>
                      <div className="font-pretendard500 text-[14px] min-h-[21px]">
                        {moment(item?.expiredTime).format("YYYY.MM.DD")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}

          {transactionHistory?.length >= 10 && pageable?.hasNext && (
            <div className="w-full bg-[#fff]">
              <div
                className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff] mx-[24px]"
                onClick={() => {
                  if (pageable?.hasNext) {
                    handleGetTransactionHistory(true, pageable.page + 1)
                  }
                }}
              >
                더 보기
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default HistoryOrderMembership
