import { Col, Row, message } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { orderApi } from "src/api/orderApi"
import Button from "src/components/Button"
import useIsMobile from "src/customHook/useIsMobile"
import { convertStatusBg } from "src/util/convertStatusPayment"
import { MoneyConvert } from "src/util/format"
import { formatPrice } from "src/util/formatPrice"

function DetailHistoryReceivedGift() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState<any>()
  const [isCanceled, setIsCanceled] = useState<boolean>(false)
  const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 })
  const [searchParams, setSearchParams] = useSearchParams()

  const orderId = params?.id as string
  const getOrderDetail = async () => {
    try {
      const { data, result }: any = await orderApi.getOrderDetail(orderId)
      if (result) {
        setOrderDetail(data)
      } else {
        // navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getOrderDetail()
  }, [orderId])

  const convertPayment = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "CREDIT":
        return "신용/체크카드"
      case "KAKAO":
        return "카카오페이"
      case "NAVER":
        return "네이버페이"
      default:
        break
    }
  }

  return (
    <div className="">
      <div className="relative z-10">
        <div className="lg:gap-[20px] gap-[16px]">
          <div className="hidden md:flex justify-between font-pretendard700 text-[24px] leading-[100%] pb-[24px] border-b-[1px] border-b-primary mb-[32px]">
            <span className="leading-[100%]">{t("선물받는 내역")}</span>
          </div>

          <Row gutter={[59, 40]}>
            <Col flex={1}>
              <p className="text-[18px] font-pretendard700 text-primary mb-[32px] leading-none">
                {t("주문 상품 정보")}
              </p>
              <div className="mb-[30px] md:mb-[32px] border-b-solid border-b md:border-b border-gray5 flex md:flex-row md:justify-normal">
                <div className="mr-[18px] mb-[36px]">
                  <div className="border border-solid w-[80px] h-[80px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700">
                    <img
                      className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] object-cover aspect-square"
                      src={orderDetail?.logoImage}
                      alt=""
                    />
                  </div>
                </div>
                <div className="block flex-1">
                  <div className="flex">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none mb-[8px] md:mb-[11px]">
                      {orderDetail?.brandName}
                    </p>
                    <div className="flex justify-end flex-1 ml-[20px]">
                      <div className="bg-black  h-[24px]">
                        <Button
                          onClick={() => {}}
                          className={`flex justify-center ${convertStatusBg(
                            orderDetail?.status,
                          )} text-white text-[10px] w-full font-pretendard700 h-full px-[5px] disabled:text-gray2`}
                        >
                          {t(orderDetail?.status)}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className="text-[16px] md:text-[18px] font-pretendard700 text-primary mb-[12px]  leading-none">
                    {orderDetail?.voucherName}
                  </p>

                  <div className="text-[#767676] text-[13px] line-through pb-[7px]">
                    {formatPrice(orderDetail?.usageValue)}원
                  </div>

                  <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                    <span className="text-primaryRed">
                      [{orderDetail?.discountPercentage}
                      %]{" "}
                    </span>
                    <span className="font-pretendard700">
                      {MoneyConvert(
                        Number(orderDetail?.voucherPrice) *
                          Number(orderDetail?.quantity),
                      ) || 0}
                      {t("원")}{" "}
                    </span>
                    / 수량 {orderDetail?.quantity}개
                  </span>
                </div>
              </div>
              <div className="border-b-solid border-b-[16px] md:border-b-[16px] border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("멤버십 No.")}
                  </p>
                  <div>
                    {orderDetail?.membershipNo?.map((item: any, index: any) => {
                      return (
                        <p
                          key={index}
                          className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]"
                        >
                          {item}
                        </p>
                      )
                    })}
                  </div>
                </div>

                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[40px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("유효 기간")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {dayjs(orderDetail?.endTime).format("YYYY.MM.DD")} ~{" "}
                    {dayjs(orderDetail?.expiredTime).format("YYYY.MM.DD")}
                  </p>
                </div>
              </div>
              <div className="pt-[32px] pb-[32px] md:pb-[60px] md:pb-0 border-b-solid border-b-[16px] md:border-none border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                <div className="flex mb-[32px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("구매자 정보")}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("주문자")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.displayName}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("휴대전화번호")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.phoneNumber}
                  </p>
                </div>
              </div>
            </Col>
            <Col flex={isMobile ? 1 : "530px"}>
              <div className="w-full md:bg-gray3 md:p-[40px] md:pt-[32px] mb-[20px]">
                <div className="flex items-center justify-between pb-[12px] md:pb-[0px] md:mb-[60px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("결제 정보")}
                  </p>
                </div>

                <div className="flex items-center mb-[24px] justify-between mt-[24px] md:mt-[40px]">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("총 상품 금액")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {MoneyConvert(Number(orderDetail?.usageValue)) || 0}원
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("할인율")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {formatPrice(orderDetail?.discountPercentage)}
                    {t("%")}
                  </p>
                </div>

                <div className="flex items-center justify-between md:mt-[0] pb-[24px]">
                  <p className="text-[16px] font-pretendard700 text-primary leading-none">
                    {t("총 결제 금액")}
                  </p>
                  <p className="text-[16px] md:text-[24px] font-pretendard700 text-[#C70039] leading-none">
                    {MoneyConvert(Number(orderDetail?.totalValue)) || 0}
                    {t("원")}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("결제 방법")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {convertPayment(orderDetail?.paymentMethod)}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("결제 일시")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {dayjs(orderDetail?.createdTime).format("YYYY.MM.DD HH:mm")}
                  </p>
                </div>
              </div>
              {orderDetail?.canViewHistory && (
                <Button
                  theme="main"
                  height={48}
                  onClick={() =>
                    navigate(`/my-membership/${orderDetail?.brandId}`)
                  }
                >
                  {t("멤버십 이용 내역 보기")}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>

      {contextHolder}
    </div>
  )
}

export default DetailHistoryReceivedGift
