import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleOk: () => void
}

function PopupCancelOrder({ handleClose, handleOk, isOpen }: Props) {
  const { t } = useTranslation()
  return (
    <CommonModal
      onCancel={handleClose}
      className="modal-membership text-[#000] w-[100vh] h-[100vh] modal-fullpage font-pretendard400"
      isOpen={isOpen}
      isCloseOutside
    >
      <div className="px-[16px] mt-[34px]">
        <div className="font-pretendard700 text-[20px]">
          {t("Order Cancellation2")}
        </div>
        <div className="mt-[20px] text-[#757575]">
          {t("Are you sure you want to cancel order?2")}
        </div>

        <div className="mt-[10px] flex gap-[10px]">
          <Button
            onClick={handleClose}
            className="flex justify-center border border-solid border-primary text-primary text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
          >
            취소
          </Button>
          <Button
            onClick={handleOk}
            className="flex justify-center bg-[#000] text-[#fff] text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
          >
            확인
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default PopupCancelOrder
