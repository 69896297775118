import { Col, Row } from "antd"
import { useTranslation } from "react-i18next"

type Props = {
  data?: any
}

function Service({ data }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <p className="text-[20px] font-pretendard700 text-primary mb-[19px] leading-none">
        {t("판매 정보")}
      </p>
      <div>
        <Row gutter={52}>
          <Col lg={24} sm={24} xs={24}>
            <div className="border border-solid border-[#E5E5E5] mb-[20px]">
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("발행자")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.issuer}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("이용가능 매장")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.availableStores}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("예약 조건")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.reservationConditions}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("유효 기간")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.validityPeriod}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("취소/환불 기준")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.cancelRefundPolicy}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("잔액 환급 조건")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.balanceRefundConditions}
                  </span>
                </div>
              </div>

              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("환불 방법")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.refundMethod}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span
                    className="text-[13px] font-pretendard400 text-primary leading-[200%]"
                    dangerouslySetInnerHTML={{
                      __html: t("소비자상담 연락처"),
                    }}
                  />
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.consumerConsultationContact}
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <div className="border border-solid border-[#E5E5E5]">
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("판매자")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    모아프렌즈
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("대표자")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    김동욱
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("연락처")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    info@moafriends.com
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("사업자번호")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    625-87-02322
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="w-[150px]  min-w-[150px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("사업장 소재지")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    충남 천안시 서북구 천안천 4길 32. 3층
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Service
