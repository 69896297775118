import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
import CommonModal, { CommonModalProps } from "src/components/Modal"
import ImgCard from "@assets/images/mymembership/card.png"
import { useState } from "react"
import Selection from "@components/Selection"
import moment from "moment"

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  handleMaintainIncentives?: () => void
  handleCancelTheOffer?: () => void
  data?: any
}

function ModalCancelMembership({
  open,
  handleClose,
  handleMaintainIncentives,
  handleCancelTheOffer,
  data,
  ...props
}: Props) {
  const { isMobile } = useIsMobile(475)

  console.log("data", data)

  return (
    <CommonModal
      width={375}
      onCancel={() => {
        handleClose && handleClose()
      }}
      open={open}
      className="text-[#000] modal-3rd-part-membership modal-fullpage"
      {...props}
    >
      {isMobile && (
        <>
          <NavBarMb
            pageName="멤버십 해지"
            className="mx-[-24px]"
            csGoback={() => handleClose?.()}
          />
        </>
      )}

      <h2 className="text-[18px] font-pretendard700 leading-normal mt-[-25px] text-center xs:block hidden">
        멤버십 해지
      </h2>

      <div className="border border-[#D9D9D9] p-[20px] pb-[24px] mt-[42px]">
        <div className="w-[80px] h-[80px] aspect-square">
          <img src={data?.logoImage} alt="img" />
        </div>
        <div className="mt-[28px] mb-[12px] text-[16px] font-pretendard700">
          {data?.brandName} 멤버십 혜택
        </div>

        <div className="flex flex-col gap-[12px]">
          {data?.preferential?.map((item: any, index: number) => (
            <div className="flex gap-[10px]" key={index}>
              <div className="min-w-[3px] h-[3px] bg-[#000000] rounded-full mt-[6px]"></div>
              <div className="text-[#000000] leading-[100%]">{item}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="text-center mt-[29px] mb-[22px]">
        이용기간이 아직{" "}
        <span className="font-pretendard700">
          {moment(data?.expiredTime).diff(moment(), "days")}
        </span>
        일 더 남았어요! <br />
        해지하면 멤버십 혜택을 더이상 받지 못해요.
      </div>

      <div className="mb-[65px]">
        {data?.images?.map((item: any, index: number) => (
          <img src={item} alt="img" key={index} />
        ))}
      </div>

      <div className="flex mx-[-24px] xs:mb-[-39px] xs:static fixed left-0 right-0 bottom-0">
        <div
          className={`flex-1 cursor-pointer bg-[#ECECEC] h-[48px] leading-[48px] text-center text-[16px] ${
            !data?.isExtend && "!cursor-not-allowed text-[#a1a3a8]"
          }`}
          onClick={() => {
            if (data?.isExtend) {
              handleCancelTheOffer?.()
            }
          }}
        >
          해지하기
        </div>
        <div
          className="flex-1 cursor-pointer bg-[#000000] h-[48px] leading-[48px] text-center text-[16px] text-[#fff]"
          onClick={handleMaintainIncentives}
        >
          혜택 유지하기
        </div>
      </div>
    </CommonModal>
  )
}

export default ModalCancelMembership
