import { Pagination } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import SortIcon from "@assets/images/history_order/sort.png"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import moment from "moment"
import { formatPrice } from "src/util/formatPrice"
import { getDetailVoucherUsageHistory } from "src/api/voucherUsagehistoryApi"
import { UrlInternal } from "src/constant/endpointRoute"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

const tabType = [
  {
    label: "Voucher",
    value: "VOUCHER_NEW",
  },
  {
    label: "Coupon",
    value: "COUPON",
  },
]

function DetailVoucherUsageHistory() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [pageable, setPageable] = useState<any>({})
  const params = useParams()
  const { id } = params
  const query = {
    page: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
    size: 8,
    transactionType: searchParams.get("transactionType") || "VOUCHER_NEW",
    brandId: Number(id),
    timeFilter: searchParams.get("timeFilter") || "",
    totalValueSort: searchParams.get("totalValueSort") || "",
  }

  const { isMobile } = useIsMobile(768)
  const [dataSource, setDataSource] = useState<any>([])
  const [dataBrandInfo, setDataBrandInfo] = useState<any>([])
  const [dataAdd, setDataAdd] = useState<any>({})
  const navigate = useNavigate()

  const getDataVoucherUsageHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      const resp: any = await getDetailVoucherUsageHistory({
        ...query,
        page: page ?? query.page,
      })
      if (resp.result) {
        let dataResp = resp.data.datas

        if (isLoadmore) {
          const dt = [...dataSource, ...dataResp]

          setDataSource([...dataSource, ...dataResp])
        } else {
          setDataSource(dataResp)
        }

        setPageable(resp.data.pageable)
        setDataBrandInfo(resp.data.dataAdd.brandInfo)
        setDataAdd(resp.data.dataAdd)
        // setIsCheckMembership(
        //   !resp.data.dataAdd.isSubscribed &&
        //     resp.data.dataAdd.brandInfo.hasNormalMembership,
        // )
      } else {
        setDataSource([])
        setPageable({})
        setDataBrandInfo({})
        setDataAdd({})
      }
    } catch (error) {
      setDataSource([])
      setPageable({})
      setDataBrandInfo({})
      setDataAdd({})
    }
  }

  useEffect(() => {
    getDataVoucherUsageHistory()
  }, [searchParams])

  const columns: ColumnsType<any> = [
    {
      title: t("Order ID"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "8%",
    },
    {
      title: t("Payment time"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        )
      },
      width: "12%",
    },
    {
      title: t("Branch"),
      dataIndex: "branchName",
      key: "branchName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "10%",
    },
    {
      title: t("상품명"),
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {`${text}`}
            {record.endTime && (
              <span>
                [판매종료 {moment(record.endTime).format("MM월 DD일")}/사용 기간{" "}
                {moment(record.voucherExpiredTime).format("MM월 DD일")}]
              </span>
            )}
          </p>
        )
      },
      width: "20%",
    },
    {
      title: t("선불권 코드"),
      dataIndex: "voucherCode",
      key: "voucherCode",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "20%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>{t("잔액/총액")}</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setSearchParams((params) => {
                    params.set(
                      "totalValueSort",
                      searchParams.get("totalValueSort") === "desc"
                        ? "asc"
                        : "desc",
                    )
                    return params
                  })
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: t("amount"),
      key: "amount",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {formatPrice(text)}원
            {query.transactionType !== "COUPON" && (
              <span className="text-[#666666]">
                /{formatPrice(record?.rootAmount)}원
              </span>
            )}
          </p>
        )
      },
      width: "20%",
    },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: t("Order ID"),
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "10%",
    },
    {
      title: t("Payment time"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        )
      },
      width: "20%",
    },
    {
      title: t("Branch"),
      dataIndex: "branchName",
      key: "branchName",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "15%",
    },
    {
      title: t("Coupon’s name"),
      dataIndex: "voucherCode",
      key: "voucherCode",
      align: "center",
      render: (text, record) => {
        return <p className=" text-primary font-pretendard400">{text}</p>
      },
      width: "45%",
    },
    {
      // title: "상품명",
      title: ({ sortColumns }) => {
        return (
          <div className="flex items-center gap-[5px] justify-center">
            <div>{t("시중가")}</div>
            <div className="cursor-pointer">
              <img
                src={SortIcon}
                alt="sort"
                onClick={() =>
                  setSearchParams((params) => {
                    params.set(
                      "totalValueSort",
                      searchParams.get("totalValueSort") === "desc"
                        ? "asc"
                        : "desc",
                    )
                    return params
                  })
                }
              />
            </div>
          </div>
        )
      },
      dataIndex: t("amount"),
      key: "amount",
      align: "center",
      render: (text, record) => {
        return (
          <p className=" text-primary font-pretendard400">
            {formatPrice(text)}원
            {query.transactionType !== "COUPON" && (
              <span className="text-[#666666]">
                /{formatPrice(record?.rootAmount)}원
              </span>
            )}
          </p>
        )
      },
      width: "20%",
    },
  ]

  const allTime = [
    { value: "", label: t("All") },
    { value: "1M", label: t("1개월") },
    { value: "3M", label: t("3개월") },
    { value: "6M", label: t("6개월") },
    { value: "1Y", label: t("1년") },
  ]

  return (
    <>
      {isMobile && <NavBarMb pageName="Voucher usage history" />}

      <div className="container md:mt-[63px] mt-[12px]">
        <div className="text-[24px] font-pretendard700 pb-[16px] border-b border-[#000000] mb-[50px] md:block hidden">
          {t("Voucher usage history")}
        </div>

        <div className="flex gap-[16px] pb-[30px] border-b border-[#D9D9D9]">
          <div className="w-[72px] h-[72px] border border-[#D7D7D7]">
            <img
              src={dataBrandInfo?.logoImage}
              alt="img"
              className="w-full object-cover aspect-square"
            />
          </div>
          <div>
            <div className="font-pretendard700">{dataBrandInfo?.brandName}</div>
            <div className="text-[12px] mt-[8px] mb-[3px]">
              {dataBrandInfo?.slogan}
            </div>
            {!dataAdd?.isSubscribed && dataBrandInfo?.hasNormalMembership && (
              <div className="text-[#666666] text-[12px]">
                You are not a member yet,{" "}
                <span
                  className="text-[#15A0A1] cursor-pointer"
                  onClick={() =>
                    navigate(`/${dataBrandInfo?.id}?type=isRegisterMembership`)
                  }
                >
                  Join membership
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between md:mt-[24px] mt-[30px] md:mb-[22px] mb-[28px] gap-[12px]">
          <div className="flex items-center">
            {tabType.map((item, index) => (
              <div
                className={`text-[#000] text-[18px] leading-[18px] cursor-pointer ${
                  index === 0
                    ? "pr-[16px] border-r border-r-[#D4D4D4]"
                    : "pl-[16px]"
                } ${
                  (item.value === query.transactionType ||
                    (index === 0 && !query.transactionType)) &&
                  "font-pretendard700"
                }`}
                key={index}
                onClick={() => {
                  setSearchParams((params) => {
                    params.set("transactionType", item.value)
                    return params
                  })
                }}
              >
                {t(item.label)}
              </div>
            ))}
          </div>

          <div className="flex flex-col md:flex-row md:gap-[14.5px] gap-[8px] md:items-center">
            <div className="text-[14px]">기간</div>
            <SelectCustom
              placeholder={t("All")}
              onChange={(e) => {
                setSearchParams((params) => {
                  params.set("timeFilter", e)
                  return params
                })
              }}
              options={allTime}
              height={40}
              width={isMobile ? null : 155}
            />
          </div>
        </div>

        <div className="flex gap-[28px] md:justify-end justify-between mb-[14px]">
          <div className="text-[14px]">
            {t("총 N건", {
              number: pageable?.totalElement ?? 0,
            })}
          </div>
          <div className="text-[14px]">
            {!query.timeFilter ? (
              t("All")
            ) : (
              <>
                {dayjs()
                  .subtract(TIME_NUMBER[query.timeFilter] || 12, "month")
                  .format("YYYY.MM.DD")}{" "}
                ~ {dayjs().format("YYYY.MM.DD")}
              </>
            )}
          </div>
        </div>

        <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px] md:block hidden">
          <CsTable
            className="mb-[34px] table-history-order"
            columns={query.transactionType === "COUPON" ? columns2 : columns}
            dataSource={dataSource}
          />
          {dataSource && pageable?.totalElement?.length > 8 && (
            <Pagination
              showSizeChanger={false}
              rootClassName="flex justify-center"
              className="custom-pagination"
              total={pageable?.totalElement}
              pageSize={query?.size}
              current={query?.page}
              onChange={(page) => {
                searchParams.set("page", String(page))
                setSearchParams(searchParams)
              }}
            />
          )}
        </div>

        <div className="md:hidden mx-[-24px]">
          {dataSource &&
            dataSource?.length > 0 &&
            dataSource?.map((item: any, i: number) => (
              <div className="pb-[45px]" key={i}>
                <div className="px-[24px] py-[17px] font-pretendard700 bg-[#F5F5F5] mb-[14px]">
                  {item?.branchName}
                </div>

                <div className="flex gap-[35px] px-[24px]">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-[14px] text-[#666666]">
                      {t("Voucher’s name")}
                    </div>
                    <div className="text-[14px] text-[#666666]">
                      {t("Payment time")}
                    </div>
                    <div className="text-[14px] text-[#666666]">
                      {t("잔액/총액")}
                    </div>
                  </div>
                  <div className="flex flex-col gap-[15px]">
                    <div className="font-pretendard500 text-[14px]">
                      {item?.voucherName}
                    </div>
                    <div className="font-pretendard500 text-[14px]">
                      {moment(item?.createdTime).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                    <div className="font-pretendard500 text-[14px]">
                      {formatPrice(item?.amount)}원
                      {query.transactionType !== "COUPON" && (
                        <span className="text-[#666666]">
                          /{formatPrice(item?.rootAmount)}원
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {dataSource?.length > 0 && pageable?.hasNext && (
            <div className="w-full bg-[#fff]">
              <div
                className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff] mx-[24px]"
                onClick={() => {
                  if (pageable?.hasNext) {
                    getDataVoucherUsageHistory(true, pageable.page + 1)
                  }
                }}
              >
                더 보기
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DetailVoucherUsageHistory
