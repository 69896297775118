import { Form, Input, SelectProps, message } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { customerApi } from "src/api/customerApi"
import CommonModal, { CommonModalProps } from "."
import Button from "../Button"
import CSInput from "../Input/Index"
import { useTranslation } from "react-i18next"
import SelectCustom from "../SelectCustom"

const { TextArea } = Input

type CreateInquiryProps = {
  createSuccess?: () => void
} & CommonModalProps

const InquiryTypeOptions: SelectProps["options"] = [
  {
    label: "멤버십이용",
    value: "MEMBERSHIP_USAGE",
  },
  {
    label: "주문결제",
    value: "ORDER_PAYMENT",
  },
  {
    label: "취소/환불",
    value: "CANCELATION_REFUND",
  },
  {
    label: "기타",
    value: "OTHER",
  },
]

const CreateInquiry: React.FC<CreateInquiryProps> = ({
  createSuccess,
  ...props
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 2,
  })
  const [isOpenSuccess, setOpenSuccess] = useState<boolean>(false)

  const onFinish = async (values: any) => {
    try {
      const { result, data }: any = await customerApi.createInquiry(values)
      if (result && data) {
        form.resetFields()
        props.handleClose && props.handleClose()
        setOpenSuccess(true)
        createSuccess && createSuccess()
        // props.onRefresh && props.onRefresh()
        navigate({
          pathname: ".",
        })
      } else {
        throw data
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: t("Something went wrong. Please try again!"),
      })
    }
  }
  const [submittable, setSubmittable] = useState<boolean>(false)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      },
    )
  }, [form, values])
  return (
    <>
      {contextHolder}
      <CommonModal
        maskStyle={{ background: "rgba(5, 23, 50, 0.80)" }}
        onCancel={() => {
          props.handleClose && props.handleClose()
          form.resetFields()
        }}
        {...props}
      >
        <p className="font-pretendard700 text-[18px] leading-[100%] text-primary mb-[16px]">
          {t("1:1 문의")}
        </p>
        <p className="font-pretendard400 text-[14px] leading-[100%] text-gray1 leading-[20px]">
          {t(
            "Give us your concern about Membership. We will reply you as soon as possible.",
          )}
        </p>
        <div className="mt-4">
          <Form
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="form-content"
            form={form}
            requiredMark={"optional"}
          >
            <Form.Item name="type">
              <SelectCustom
                options={InquiryTypeOptions}
                height={48}
                placeholder={t("Choose inquiry type")}
                onChange={(value) => {
                  form.setFieldValue("type", value)
                }}
              />
            </Form.Item>
            <CSInput
              textSize="14px"
              name="subject"
              // label="Subject"
              borderColor="#D4D4D4"
              type="text"
              inputHeight={48}
              placeholder={t("제목입력하세요")}
              // style={{ color: "#2D2D2D" }}
              rules={[
                { required: true, message: t("Please add your subject!") },
                {
                  min: 3,
                  message: t("Subject contain at least 3 character long."),
                },
                {
                  max: 152,
                  message: t("Subject contain at max 152 character long."),
                },
              ]}
            ></CSInput>
            <Form.Item
              name="description"
              label={
                <p className="text-[13px] font-pretendard500 text-textColor6 leading-[normal] mb-[4px]">
                  {t("Description")}
                  <span className="text-errorColor">*</span>
                </p>
              }
              rules={[
                { required: true, message: "Please add your description!" },
                {
                  min: 3,
                  message: "Description contain at least 3 character long.",
                },
              ]}
            >
              <TextArea
                className="rounded-[0px] border-secondary6 px-[15px] py-[11px] font-pretendard500 text-[14px]"
                placeholder={t("Write more detail here")}
                rows={6}
                style={{ color: "#2D2D2D", height: 160 }}
              />
              {/* <Editor /> */}
            </Form.Item>
            <div className="flex justify-center">
              <Button
                type="submit"
                theme="main"
                width={200}
                height={48}
                disabled={!submittable || !form.getFieldValue("subject")}
              >
                {t("Submit")}
              </Button>
            </div>
          </Form>
        </div>
      </CommonModal>
      <CommonModal
        isOpen={isOpenSuccess}
        handleClose={() => setOpenSuccess(false)}
      >
        <div>
          <p className="lg:text-[22px] text-[20px] font-pretendard500 text-primary whitespace-nowrap">
            {t("Submit question successfully.")}
          </p>
        </div>
      </CommonModal>
    </>
  )
}

export default CreateInquiry
