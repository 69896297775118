import imgDeals from "@assets/images/image175.png"
import Countdown from "react-countdown"
import Button from "src/components/Button"
import { MoneyConvert } from "src/util/format"
import dayjs from "dayjs"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import NextIcon from "@assets/icons/NextIcon20.svg"
import QrcodeIcon from "@assets/icons/QrcodeIcon.svg"
import ShareIcon from "@assets/icons/header/icon-share.svg"
import groupBuy from "@assets/images/GroupBuy_temp.png"
import { useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import OrderInput from "src/components/Input/OrderInput"
import TabsComp from "src/components/Tabs"
import { Voucher } from "src/models"
import Service from "src/pages/GroupBuying/VoucherDetail/Service"
import { copyLink } from "src/util/copyLink"
import { Tooltip } from "react-tooltip"
import useIsMobile from "src/customHook/useIsMobile"
import NoData from "src/components/NoData"
import { homeAPi } from "src/api/homeApi"
import EvenEnd from "src/components/Modal/PaymentModal/EventEnd"
import MaximumPurchaseLimit from "src/components/Modal/PaymentModal/MaximumPurchaseLimit"
import MaxQtyModal from "src/components/Modal/PaymentModal/MaxQtyModal"

import GiftImg from "@assets/images/group_sale/gift.png"
import moment from "moment"
import { useAuth } from "src/customHook/useAuth"
import { formatPrice } from "src/util/formatPrice"

type Props = {
  showTitle?: boolean
  mainColor?: string
  colorButton?: string
  titleEx?: boolean
  data?: any
  brandId?: number
  onComplete?: () => void
}

function GroupBuy({ showTitle = true, data, onComplete }: Props) {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(1)
  const [openEvenEnd, setOpenEvenEnd] = useState<boolean>(false)
  const [openMaximumPurchaseLimit, setOpenMaximumPurchaseLimit] =
    useState<boolean>(false)
  const [openMaxQtyModal, setOpenMaxQtyModal] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const { isMobile } = useIsMobile(768)
  const [isTypeGift, setIsTypeGift] = useState(false)
  const { isLogined } = useAuth()
  const { pathname } = useLocation()
  // console.log("data", data)

  const checkOrder = async (isGift?: boolean) => {
    if (!isLogined) {
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}`,
      })
    } else {
      try {
        const resp: any = await homeAPi.checkGb(data.id, "GB_DETAIL", quantity)
        // console.log(resp)
        if (resp?.result) {
          navigate(
            `/group-buying/order/${data.id}?type=${isGift ? "gift" : "normal"}`,
            {
              state: {
                quantity: quantity,
                isGift: isGift,
              },
            },
          )
        } else {
          if (resp?.errorCode === "ENDED_PURCHASE") {
            setOpenEvenEnd(true)
            return
          }
          if (
            resp?.errorCode === "OUT_OF_PURCHASE" ||
            resp?.errorCode === "MORE_THAN_SPECIFIED_QUANTITY"
          ) {
            setOpenMaximumPurchaseLimit(true)
            return
          }
          if (resp?.errorCode === "NOT_ENOUGH_VOUCHER") {
            setOpenMaxQtyModal(true)
            return
          }
        }
      } catch (error) {
        console.log("checkOrder error", error)
      }
    }
  }

  function onDismiss() {
    setOpen(false)
  }

  if (!data) {
    return <></>
  }
  const voucherData = new Voucher(data)

  const items = [
    {
      key: "1",
      label: t("DETAIL"),
      children: (
        <div className="xs:my-[32px] my-[8px] mx-[25px] xs:mx-[40px]">
          {voucherData?.voucherDetail?.length > 0 ? (
            voucherData?.voucherDetail?.map((item: any, index: number) => (
              <img
                key={index}
                className="object-cover w-full mb-5"
                src={item?.url}
                alt="detail"
              />
            ))
          ) : (
            <div className="pt-[50px]">{/* <NoData /> */}</div>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: t("INFORMATION"),
      children: (
        <div className="xs:my-[32px] my-[8px] mx-[25px] xs:mx-[40px]">
          <Service data={data} />
        </div>
      ),
    },
  ]

  const renderStatus = () => {
    return (
      <div className="flex absolute bottom-[0px] bg-lightColor text-lightColor">
        {voucherData.status === "INCOMING" && (
          <div className="px-[15px] py-[1px] bg-primaryGreen">
            <span className="font-pretendard400 text-primaryWhite text-[12px] leading-none uppercase">
              {t("Upcoming")}
            </span>
          </div>
        )}
        {(voucherData.status === "FINISHED" ||
          voucherData?.quantity === voucherData?.totalSold) && (
          <div className="px-[15px] py-[1px] bg-primaryRed">
            <span className="font-pretendard400 text-primaryWhite text-[12px] leading-none uppercase">
              {t("SOLD OUT")}
            </span>
          </div>
        )}
      </div>
    )
  }

  console.log("data113", data)

  const handleClickMobile = (isGift: boolean) => {
    if (!isLogined) {
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}`,
      })
    } else {
      setIsTypeGift(isGift)
      setOpen(true)
    }
  }

  return (
    <>
      <div>
        <div className={`flex flex-col md:flex-row gap-x-12 gap-y-6 flex-wrap`}>
          <div
            className={`relative overflow-hidden cursor-pointer flex-1 w-full h-full aspect-square ${
              !showTitle && "container"
            } lg:px-0`}
          >
            <img
              src={voucherData.voucherImage || imgDeals}
              alt=""
              className="w-full h-full object-cover"
            />
            {renderStatus()}
          </div>
          <div className="flex-1 xs:px-0 px-[24px]">
            <div className="md:mb-[32px] md:border-b border-b-solid border-gray5 flex">
              <div className="mr-[18px] md:mb-[36px]">
                <div className="hidden md:flex border border-solid w-[72px] h-[72px] flex justify-center items-center text-primaryGreen font-pretendard700 mb-[9px]">
                  {voucherData.logoImage ? (
                    <img
                      src={voucherData.logoImage || groupBuy}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    voucherData.brandName
                  )}
                </div>
                <Link to={`/${data.brandId}`} className="">
                  <div className="md:border border-solid md:min-w-[72px] h-[24px] flex md:justify-end items-center font-pretendard400 text-[12px]">
                    {isMobile ? (
                      <>
                        {voucherData.brandName} <NextIcon />
                      </>
                    ) : (
                      <>
                        {t("브랜드홈")} <NextIcon />
                      </>
                    )}
                  </div>
                </Link>
              </div>
              <div className="hidden md:block">
                <p className="text-[16px] font-pretendard700 leading-4 mb-[8px]">
                  {voucherData.brandName}
                </p>
                <span className="text-[12px] font-pretendard400 leading-[150%] line-clamp-3">
                  {voucherData.slogan}
                </span>
              </div>
            </div>
            <div className="mt-[12px]">
              <div className="flex items-start">
                <p className="text-[16px] md:text-[20px] font-pretendard700 text-primary leading-normal cursor-pointer flex-1">
                  {voucherData.voucherName}
                </p>
                <div
                  data-tooltip-id="copyTooltip"
                  data-tooltip-content="Copied"
                  className="ml-4 w-[24px] h-[24px] min-w-[24px] md:w-[36px] md:h-[36px] md:min-w-[36px] icon-full cursor-pointer"
                  onClick={() => {
                    copyLink()
                  }}
                >
                  <ShareIcon />
                </div>
                <Tooltip id="copyTooltip" events={["click"]} />
              </div>
              {/* <p className="text-textColor6 font-pretendard500 text-[16px] leading-[27px] mt-[6px] line-clamp-4 lg:line-clamp-3 max-w-[500px]">
              {voucherData.description}
            </p> */}
              <p className="text-[16px] font-pretendard400 text-gray1 mt-[18px] md:mt-[32px] leading-none line-through">
                {MoneyConvert(voucherData.rootPrice || 0)}원
              </p>
              <p className="text-[18px] md:text-[24px] font-pretendard700 text-primary mt-[8px] mb-[24px] md:mb-[32px] leading-none">
                <span className="text-[red]">
                  {voucherData?.discountPercentage
                    ? voucherData?.discountPercentage + "%"
                    : ""}{" "}
                </span>
                {MoneyConvert(voucherData?.voucherPrice) || 0}원
              </p>

              <div className="flex items-center mb-[14px]">
                {/* <p
                className={`${
                  voucherData.status === "FINISHED"
                    ? "w-[150px] md:w-[175px]"
                    : "w-[125px]"
                } text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none`}
              > */}
                <p
                  className={`${
                    voucherData.status === "FINISHED"
                      ? "min-w-[150px] md:min-w-[175px]"
                      : "min-w-[125px]"
                  } text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none`}
                >
                  {t("잔여 수량")}
                </p>
                <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                  {voucherData?.quantity === voucherData?.totalSold ? (
                    <span className="text-[red] font-pretendard700">매진</span>
                  ) : (
                    MoneyConvert(
                      Number(voucherData.quantity) -
                        Number(voucherData.totalSold) || 0,
                    )
                  )}
                </p>
              </div>

              {voucherData.status === "FINISHED" && (
                <>
                  <div className="flex items-center mb-[14px]">
                    <p className="min-w-[150px] md:min-w-[175px] text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none">
                      {t("Group buying value")}
                    </p>
                    <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                      {MoneyConvert(voucherData.totalGroupBuyingValue || 0)}
                    </p>
                  </div>

                  <div className="flex items-center mb-[14px]">
                    <p className="min-w-[150px] md:min-w-[175px] text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none">
                      {t("Duration")}
                    </p>
                    <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                      {dayjs(voucherData?.startTime).format("YYYY/MM/DD")} -{" "}
                      {dayjs(voucherData?.endTime).format("YYYY/MM/DD")}
                    </p>
                  </div>
                </>
              )}

              {voucherData.status !== "FINISHED" && (
                <>
                  <div className="flex items-center">
                    <p className="min-w-[125px] text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none">
                      {voucherData.status === "INCOMING"
                        ? "Open in"
                        : t("구매 가능 기간")}
                    </p>

                    <Countdown
                      // overtime
                      date={voucherData.getCountTime()}
                      onComplete={onComplete}
                      renderer={({
                        formatted: { days, hours, minutes, seconds },
                      }) => {
                        return (
                          <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                            {Number(days) > 0 ? days : "0"}일 {hours}시간{" "}
                            {minutes}분 {seconds}초 남음
                          </p>
                        )
                      }}
                    />
                  </div>

                  <div className="mb-[36px] mt-[14px]">
                    프리미엄 멤버십 사용 기간{" "}
                    <div className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                      {moment(voucherData.endTime)
                        .add(1, "minute")
                        .format("YYYY년 MM월 DD일 HH시 mm분")}
                      ~
                      {moment(voucherData.expiredTime).format(
                        "YYYY년 MM월 DD일 HH시 mm분",
                      )}
                    </div>
                  </div>

                  {voucherData?.maxPurchaseQuantity &&
                    voucherData?.maxPurchaseFrequency && (
                      <div className="hidden md:flex bg-[#F5F5F5CC] px-[16px] py-[15px] h-[44px] mb-[16px]">
                        <span className="text-[14px] font-pretendard400 text-gray1 leading-none">
                          한 계정당{" "}
                          {formatPrice(voucherData?.maxPurchaseFrequency || 0)}
                          회,{" "}
                          {formatPrice(voucherData?.maxPurchaseQuantity || 0)}
                          개씩 구매 가능합니다.
                        </span>
                      </div>
                    )}

                  <div className="hidden md:flex">
                    <OrderInput
                      defaultValue={quantity}
                      disabled={
                        voucherData?.isLimited ||
                        voucherData?.quantity === voucherData?.totalSold ||
                        voucherData?.status === "INCOMING"
                      }
                      available={Math.min(
                        Number(voucherData?.quantity) -
                          Number(voucherData?.totalSold),
                        Number(voucherData?.maxPurchaseQuantity || 100000000),
                      )}
                      className="w-[132px]"
                      onChange={setQuantity}
                    />
                  </div>
                </>
              )}
            </div>
            {voucherData.status === "FINISHED" && (
              <div className="mt-[30px] md:block hidden">
                <Button theme="main" disabled={true}>
                  {t("판매가 종료된 상품입니다")}
                </Button>
              </div>
            )}
            {voucherData.status !== "FINISHED" && (
              <>
                <div className="hidden md:flex font-pretendard500 gap-x-[20px] pt-[32px]">
                  {!Number(import.meta.env.VITE_IS_SHOW_GIFT) && (
                    <Button
                      theme="main"
                      variant="outline"
                      onClick={() => {
                        // navigate(`/group-buying/order/${data.id}`, {
                        //   state: {
                        //     quantity: quantity,
                        //   },
                        // })
                        checkOrder(true)
                      }}
                      disabled={
                        voucherData?.quantity === voucherData?.totalSold ||
                        Boolean(voucherData?.progress === "INCOMING")
                      }
                    >
                      <div className="flex gap-[15px] items-center">
                        <img
                          src={GiftImg}
                          alt="gift"
                          className={
                            voucherData?.quantity === voucherData?.totalSold ||
                            Boolean(voucherData?.progress === "INCOMING")
                              ? "contrast-[0.1]"
                              : ""
                          }
                        />
                        <div>{t("선물하기")}</div>
                      </div>
                    </Button>
                  )}
                  <Button
                    theme="main"
                    onClick={() => {
                      // navigate(`/group-buying/order/${data.id}`, {
                      //   state: {
                      //     quantity: quantity,
                      //   },
                      // })
                      checkOrder()
                    }}
                    disabled={
                      voucherData?.quantity === voucherData?.totalSold ||
                      Boolean(voucherData?.progress === "INCOMING")
                    }
                  >
                    {t("구매하기")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[8px] md:mt-[63px]">
          <TabsComp theme="detailTab" items={items} />
        </div>

        {params.id
          ? !open && (
              <>
                {voucherData.status === "FINISHED" && (
                  <div className="mt-[30px] flex items-center  md:hidden fixed z-20 h-[80px] left-0 bottom-0 gap-x-[8px] w-full bg-[#fff] px-[15px] py-[12px]">
                    <Button theme="main" disabled={true}>
                      {t("판매가 종료된 상품입니다")}
                    </Button>
                  </div>
                )}
                {voucherData.status !== "FINISHED" && (
                  <div className="flex items-center  md:hidden fixed z-20 h-[80px] left-0 bottom-0 gap-x-[8px] w-full bg-[#fff] px-[15px] py-[12px]">
                    {!Number(import.meta.env.VITE_IS_SHOW_GIFT) && (
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleClickMobile(true)
                        }}
                        disabled={
                          voucherData?.quantity === voucherData?.totalSold ||
                          Boolean(voucherData?.progress === "INCOMING") ||
                          Boolean(voucherData?.progress === "FINISHED")
                        }
                        className="flex w-full text-primary text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2 bg-[#fff] border border-black"
                      >
                        <div className="flex gap-[15px] items-center">
                          <img
                            src={GiftImg}
                            alt="gift"
                            className={
                              voucherData?.quantity ===
                                voucherData?.totalSold ||
                              Boolean(voucherData?.progress === "INCOMING")
                                ? "contrast-[0.1]"
                                : ""
                            }
                          />
                          <div className="text-primary">{t("선물하기")}</div>
                        </div>
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        handleClickMobile(false)
                      }}
                      disabled={
                        voucherData?.quantity === voucherData?.totalSold ||
                        Boolean(voucherData?.progress === "INCOMING") ||
                        Boolean(voucherData?.progress === "FINISHED")
                      }
                      className="flex w-full text-white text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2 bg-black"
                    >
                      {t("구매하기")}
                    </Button>
                  </div>
                )}
              </>
            )
          : !open && (
              <div className="flex justify-between items-center bg-black md:hidden fixed z-20 h-[70px] left-0 bottom-0 gap-x-[30px] w-full">
                <div
                  className="px-[16px] h-full flex justify-between items-center"
                  onClick={() => {
                    navigate(`/qr-code`)
                  }}
                >
                  <QrcodeIcon />
                </div>
                <Button
                  onClick={() => setOpen(true)}
                  disabled={
                    voucherData?.quantity === voucherData?.totalSold ||
                    Boolean(voucherData?.progress === "INCOMING") ||
                    Boolean(voucherData?.progress === "FINISHED")
                  }
                  className="flex text-white text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2"
                >
                  {t("구매하기")}
                </Button>
              </div>
            )}
        {open && (
          <div
            className="bg-bottomSheetOverlay fixed w-[100vw] h-[100vh] left-0 top-0"
            onClick={onDismiss}
          ></div>
        )}
        <BottomSheet
          open={open}
          onDismiss={onDismiss}
          snapPoints={({ minHeight }) => minHeight}
          className="relative z-10"
          blocking={false}
        >
          <div>
            <div className="px-[24px]">
              <span className="text-[12px] font-pretendard400 text-gray1 leading-none block my-[16px]">
                {t("X회 최대 Y개 수량만 구매 가능합니다.", {
                  purchasesNumber: "1",
                  quantity: voucherData?.maxPurchaseQuantity || 0,
                })}
              </span>
              <OrderInput
                defaultValue={quantity}
                disabled={
                  voucherData?.isLimited ||
                  voucherData?.quantity === voucherData?.totalSold ||
                  voucherData?.status === "INCOMING"
                }
                available={Math.min(
                  Number(voucherData?.quantity) -
                    Number(voucherData?.totalSold),
                  Number(voucherData?.maxPurchaseQuantity || 100000000),
                )}
                className="w-full"
                onChange={setQuantity}
              />

              <div className="flex items-center mb-[12px] justify-between mt-[40px]">
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {t("총 상품 금액")}
                </p>
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {MoneyConvert(Number(voucherData?.rootPrice || 0) * quantity)}
                  원
                </p>
              </div>

              <div className="flex items-center mb-[20px] justify-between">
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {t("할인 금액")}
                </p>
                <p className="text-[14px] font-pretendard400 text-primary leading-none">
                  {MoneyConvert(
                    (Number(voucherData?.discountPercentage) / 100) *
                      Number(voucherData?.rootPrice) *
                      quantity || 0,
                  )}
                  원
                </p>
              </div>

              <div className="flex items-center justify-between pt-[24px] border-t-2 border-t-solid border-primary">
                <p className="text-[16px] font-pretendard700 text-primary leading-none">
                  {t("구매 가능 금액")}
                </p>
                <p className="text-[20px] font-pretendard800 text-primary leading-none">
                  {MoneyConvert(
                    Number(voucherData.voucherPrice) * quantity || 0,
                  )}
                  {t("원")}
                </p>
              </div>
            </div>
            <div className="flex font-pretendard500 pt-[32px] gap-[8px] mx-[24px] mb-[20px]">
              {isTypeGift ? (
                !Number(import.meta.env.VITE_IS_SHOW_GIFT) && (
                  <Button
                    // theme="main"
                    variant="outline"
                    onClick={() => {
                      // navigate(`/group-buying/order/${data.id}`, {
                      //   state: {
                      //     quantity: quantity,
                      //   },
                      // })
                      checkOrder(true)
                    }}
                    disabled={
                      voucherData?.quantity === voucherData?.totalSold ||
                      Boolean(voucherData?.progress === "INCOMING")
                    }
                    className="flex justify-center border border-black text-white text-[18px] w-full font-pretendard700 h-[56px] px-[16px] disabled:text-gray2"
                  >
                    <div className="flex gap-[15px] items-center">
                      <img
                        src={GiftImg}
                        alt="gift"
                        className={
                          voucherData?.quantity === voucherData?.totalSold ||
                          Boolean(voucherData?.progress === "INCOMING")
                            ? "contrast-[0.1]"
                            : ""
                        }
                      />
                      <div className="text-primary">{t("선물하기")}</div>
                    </div>
                  </Button>
                )
              ) : (
                <Button
                  onClick={() => {
                    // navigate(`/group-buying/order/${data.id}`, {
                    //   state: {
                    //     quantity: quantity,
                    //   },
                    // })
                    checkOrder()
                  }}
                  disabled={
                    voucherData?.quantity === voucherData?.totalSold ||
                    Boolean(voucherData?.progress === "INCOMING")
                  }
                  className="flex justify-center bg-black text-white text-[18px] w-full font-pretendard700 h-[56px] px-[16px] disabled:text-gray2"
                >
                  {t("구매하기")}
                </Button>
              )}
            </div>
          </div>
        </BottomSheet>
      </div>
      <EvenEnd
        isOpen={openEvenEnd}
        handleClose={() => {
          setOpenEvenEnd(false)
          navigate(`/group-buying/detail/${data.id}`)
        }}
      />
      <MaximumPurchaseLimit
        isOpen={openMaximumPurchaseLimit}
        handleClose={() => {
          setOpenMaximumPurchaseLimit(false)
          // console.log("123213")
          navigate(`/group-buying/detail/${data.id}`)
        }}
      />
      <MaxQtyModal
        isOpen={openMaxQtyModal}
        handleClose={() => {
          setOpenMaxQtyModal(false)
          navigate(`/group-buying/detail/${data.id}`)
        }}
      />
    </>
  )
}

export default GroupBuy
