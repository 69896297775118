import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import { formatPrice } from "src/util/formatPrice"
import IconLock from "@assets/images/shop/lock.png"
import { useAuth } from "src/customHook/useAuth"

import ImgEnd from "@assets/images/brand_shop/end_bg.png"
import ImgSoldOut from "@assets/images/brand_shop/soldout_bg.png"
import ImgMembership from "@assets/images/brand_shop/membership_bg.png"

export type DealProps = {
  data?: any
  className?: string
  isHome?: boolean
}

function ShopCard({ data, className, isHome }: DealProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isLogined } = useAuth()
  const isLock = !data?.isSubscribed && data?.onlyMembership && !data?.isPremium
  const { pathname } = useLocation()
  const percent = 100 - (data?.price / data?.voucherPrice) * 100

  const handleProgress = (data: string) => {
    if (data === "INCOMING") {
      return (
        <div className="flex absolute top-[0px] bg-lightColor text-lightColor z-10">
          <div className="px-[15px] py-[1px] bg-[#fff]">
            <span className="font-pretendard700 text-[10px] leading-none text-[#C70039]">
              {t("UPCOMING")}
            </span>
          </div>
        </div>
      )
    }
    if (data === "IN_PROGRESS") {
      return (
        <div className="flex absolute top-[0px] bg-lightColor text-lightColor z-10">
          <div className="px-[15px] py-[1px] bg-[#FC8644]">
            <span className="font-pretendard700 text-primaryWhite text-[10px] leading-none">
              {t("SHOP_INPROGRESS")}
            </span>
          </div>
        </div>
      )
    }
    if (data === "FINISHED") {
      return (
        <div className="flex absolute top-[0px] bg-lightColor text-lightColor z-10">
          <div className="px-[15px] py-[1px] bg-[#B1B1B1]">
            <span className="font-pretendard700 text-primaryWhite text-[10px] leading-none">
              {t("SHOP_FINISHED")}
            </span>
          </div>
        </div>
      )
    }

    return
  }

  const handleTypeMembership = (data: string) => {
    // if (data === "NONE") {
    //   return (
    //     <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#A8A8A8] inline-block">
    //       Non Membership
    //     </div>
    //   )
    // }
    if (data === "PREMIUM") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#000000] inline-block">
          Premium
        </div>
      )
    }
    if (data === "NORMAL") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#FA6969] inline-block">
          Membership
        </div>
      )
    }

    return
  }

  const handleProductType = (productType: string, voucherType?: string) => {
    if (productType === "VOUCHER" && voucherType === "VOUCHER_NEW") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#EEBA00] inline-block">
          {t("VOUCHER")}
        </div>
      )
    }
    if (productType === "VOUCHER" && voucherType === "COUPON") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#40C003] inline-block">
          {t("COUPON")}
        </div>
      )
    }
    if (productType === "PRODUCT") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#0098EE] inline-block">
          {t("PRODUCT")}
        </div>
      )
    }
    if (productType === "E_CONTENT") {
      return (
        <div className="py-[2.5px] px-[4px] uppercase text-[#fff] text-[10px] bg-[#7B61FF] inline-block">
          {t("E-CONTENT")}
        </div>
      )
    }

    return
  }

  const renderCheckThumbBg = () => {
    if (
      data?.progress === "FINISHED" ||
      data?.isSoldOut ||
      (!data?.isSubscribed && data?.onlyMembership && !data?.isPremium)
    ) {
      if (data?.progress === "FINISHED") {
        return (
          <div className="absolute inset-0  flex items-center justify-center">
            <img src={ImgEnd} alt="lock" />
          </div>
        )
      } else if (data?.isSoldOut) {
        return (
          <div className="absolute inset-0  flex items-center justify-center">
            <img src={ImgSoldOut} alt="lock" />
          </div>
        )
      } else if (
        !data?.isSubscribed &&
        data?.onlyMembership &&
        !data?.isPremium
      ) {
        return (
          <div className="absolute inset-0  flex items-center justify-center">
            <img src={ImgMembership} alt="lock" />
          </div>
        )
      }
    }
  }

  return (
    <div className={`flex-1 flex flex-col border border-gray12 ${className}`}>
      <div className="relative overflow-hidden">
        <img
          src={data?.image}
          alt="img"
          className="object-cover w-full h-full aspect-square cursor-pointer"
          onClick={() => {
            const scrollPosition = window.scrollY || window.pageYOffset
            sessionStorage.setItem("positionScrollY", scrollPosition.toString())
            navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${data?.id}`, {
              state: {
                currentPage: pathname,
              },
            })
          }}
        />
        {handleProgress(data?.isSoldOut ? "FINISHED" : data?.progress)}
        {/* {data?.isSoldOut && (
          <div className="absolute inset-0 bg-[#000000a6] flex items-center justify-center">
            <div className="border border-[#ffffffcc] w-[213px] h-[52px] leading-[52px] text-[30px] text-[#ffffffcc] font-pretendard900 text-center -rotate-[18deg]">
              SOLD OUT!
            </div>
          </div>
        )} */}
        {renderCheckThumbBg()}
        {/* {isLock && (
          <div className="absolute inset-0 bg-[#000000a6] flex items-center justify-center">
            <img src={IconLock} alt="lock" />
          </div>
        )} */}
      </div>
      <div className="relative flex flex-col flex-1 border border-solid	border-t-0 border-primaryWhite px-[12px] pt-[19px] pb-[23px] leading-[100%]">
        <div className="flex gap-[2px]">
          {isLogined && <div>{handleTypeMembership(data?.tag)}</div>}
          <div>{handleProductType(data?.productType, data?.voucherType)}</div>
        </div>

        <div className="font-pretendard700 text-[16px] mt-[10px] mb-[11px]">
          {data?.voucherName}
        </div>

        {isLock ? (
          <div className="text-[15px]">멤버십 전용 상품입니다</div>
        ) : (
          <>
            {percent > 0 && (
              <div className="line-through mt-[16px] mb-[7px] text-[#767676] text-[13px]">
                {formatPrice(data?.voucherPrice)}원
              </div>
            )}

            <div className="mt-auto">
              <div className={isHome ? "md:block flex flex-col gap-[5px]" : ""}>
                {percent > 0 && (
                  <span className="text-[#D32F2F]">
                    [{formatPrice(percent, 0)}
                    %]
                  </span>
                )}{" "}
                <span className="font-pretendard700">
                  {formatPrice(data?.price)}원
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ShopCard
