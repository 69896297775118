import IconCopy from "@assets/images/my_page/copy.png"
import { message, Spin } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { orderApi } from "src/api/orderApi"
import Button from "src/components/Button"
import ConvertStatusPaymentHtml from "src/components/ConvertStatusPaymentHtml"
import useIsMobile from "src/customHook/useIsMobile"
import { convertStatusBg } from "src/util/convertStatusPayment"
import { formatPrice } from "src/util/formatPrice"
import PopupCancelOrder from "./PopupCancelOrder"
import PopupRefund from "./PopupRefund"
import PopupRefundSuccess from "./PopupRefundSuccess"
import moment from "moment"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import PopupCompleteOrder from "./PopupCompleteOrder"

function OrderProductDetail() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const isMobiles = useIsMobile(540)
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState<any>()
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 })
  const [searchParams, setSearchParams] = useSearchParams()

  const [isPopupCancelOrder, setIsPopupCancelOrder] = useState(false)
  const [isModalRequestRefund, setIsModalRequestRefund] = useState(false)
  const [isModalRequestRefundSuccess, setIsModalRequestRefundSuccess] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isPopupCompleteOrder, setIsPopupCompleteOrder] = useState(false)

  const orderId = params?.id as string
  const getOrderDetail = async () => {
    try {
      const { data, result }: any = await orderApi.getOrderDetail(orderId)
      if (result) {
        setOrderDetail(data)
      } else {
        // navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getOrderDetail()
  }, [orderId])

  const convertPayment = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "CREDIT":
        return "신용/체크카드"
      case "KAKAO":
        return "카카오페이"
      case "NAVER":
        return "네이버페이"
      default:
        return paymentMethod
    }
  }

  const handleCancelOrder = async () => {
    const { result, data, error }: any = await orderApi.cancelOrderProduct(
      Number(orderId),
    )

    if (error) {
      messageApi.open({
        type: "error",
        content: error?.message,
      })
    }

    setIsPopupCancelOrder(false)

    if (result) {
      getOrderDetail()
    } else {
      throw data
    }
  }

  const handleRequestRefund = async (dt: any) => {
    setIsLoading(true)
    console.log("agaqg", dt)
    try {
      const dataMedias = []
      if (dt?.fileList?.length > 0) {
        for (let i = 0; i < dt.fileList.length; i++) {
          const formData = new FormData()
          formData.append("file", dt.fileList[i].originFileObj)
          const respUpload: any = await uploadSingleFile(formData)
          if (respUpload) {
            dataMedias.push(respUpload.imageUrl)
          }
        }
      }

      const { result, data, error }: any =
        await orderApi.cancelRefundRequestProduct({
          id: orderDetail?.id,
          reason: dt?.content,
          refundAmount: orderDetail?.refundAmount || 0,
          refundImage: dataMedias,
          refundMethod: orderDetail?.refundMethod || "",
        })

      if (result) {
        setIsLoading(false)
        setIsModalRequestRefundSuccess(true)

        getOrderDetail()
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleCompleteOrder = async () => {
    try {
      const { result, data, error }: any = await orderApi.completeOrderProduct(
        Number(orderId),
      )

      if (result) {
        getOrderDetail()
        setIsPopupCompleteOrder(false)
      }
    } catch (error) {}
  }

  const handleTextSolution = (solution: string) => {
    switch (solution) {
      case "REFUND_ONE_WAY_FEE":
        return "편도 택배비 제외 환불"
      case "REFUND_TWO_WAY_FEE":
        return "왕복 택배비(편도 택배비의 2배) 제외 환불"
      case "REFUND_ONE_WAY_AND_RETURN_FEE":
        return "편도 택배비+반품 택배비 제외 환불"
      case "REFUND_BRAND_MISTAKE":
        return "판매자 귀책 등으로 인한 전액 환불"

      default:
        return
    }
  }

  function popupWindow(
    url: string,
    windowName: string,
    win: any,
    w: number,
    h: number,
  ) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2
    return win.open(
      url,
      windowName,
      isMobiles.isMobile
        ? ""
        : `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
    )
  }

  const handleTypeContent = () => {
    switch (orderDetail?.membershipType) {
      case "NONE":
        return "일반 회원"
      case "PREMIUM":
        return "프리미엄"
      case "NORMAL":
        return "멤버십"

      default:
        return ""
    }
  }

  const handleRequestCancelRefund = async () => {
    const { result, data, error }: any =
      await orderApi.cancelRequestRefundOrder(orderId)
    if (result) {
      getOrderDetail()
    }
  }

  return (
    <Spin spinning={isLoading}>
      <div className="">
        <div className="relative z-10">
          <div className="lg:gap-[20px] gap-[16px]">
            <div className="hidden md:flex justify-between font-pretendard700 text-[24px] leading-[100%] pb-[24px] border-b-[1px] border-b-primary mb-[32px]">
              <span className="leading-[100%]">{t("주문 상세 조회")}</span>
            </div>

            <div className="flex lg:gap-[59px] gap-[32px] xl:flex-row flex-col">
              <div className="flex-1">
                <p className="text-[18px] font-pretendard700 text-primary mb-[32px] leading-none">
                  {t("주문 상품 정보")}
                </p>
                <div className="mb-[30px] md:mb-[38px] border-b-solid border-b md:border-b border-gray5 flex md:flex-row md:justify-normal">
                  <div className="xs:mr-[18px] mr-[10px] mb-[36px]">
                    <div className="border border-solid w-[80px] h-[80px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700">
                      <img
                        className="w-full h-full object-cover aspect-square cursor-pointer"
                        src={orderDetail?.voucherImage}
                        alt="img"
                        onClick={() => navigate(`/${orderDetail?.brandId}`)}
                      />
                    </div>
                  </div>
                  <div className="block flex-1">
                    <div className="flex mb-[8px] md:mb-[11px] items-center">
                      <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                        {orderDetail?.brandName}
                      </p>
                      <div className="flex justify-end flex-1 md:ml-[20px] ml-[10px]">
                        <div className="inline-block md:text-[13px] xs:text-[10px] text-[9px]">
                          <ConvertStatusPaymentHtml
                            status={orderDetail?.orderProductStatus}
                          />
                        </div>
                        <div className="bg-black h-[24px]">
                          <Button
                            onClick={() => {}}
                            className={`flex justify-center ${convertStatusBg(
                              orderDetail?.status,
                            )} text-white xs:text-[10px] text-[9px] w-full font-pretendard700 h-full px-[5px] disabled:text-gray2 cursor-default`}
                          >
                            {t(orderDetail?.status)}
                          </Button>
                        </div>
                      </div>
                    </div>

                    <p className="text-[16px] md:text-[18px] font-pretendard700 text-primary leading-none">
                      {orderDetail?.voucherName}
                    </p>

                    <div className="line-through text-[#767676] text-[13px] pb-[10px] pt-[17px]">
                      {formatPrice(
                        Number(orderDetail?.voucherPrice) *
                          orderDetail?.quantity,
                      )}
                      원
                    </div>

                    <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                      <span className="font-pretendard700">
                        {formatPrice(
                          Number(
                            orderDetail?.totalPayment -
                              Number(orderDetail?.shippingFee || 0) -
                              Number(orderDetail?.costsForJeju || 0),
                          ) / orderDetail?.quantity,
                        ) || 0}
                        {t("원")}{" "}
                      </span>
                      / 수량 {orderDetail?.quantity}개
                    </span>
                  </div>
                </div>
                <div className="lg:border-b border-b-[16px] border-[#F5F5F5] lg:border-[#D4D4D4] mx-[-16px] px-[16px] md:mx-0 md:px-0">
                  <div className="flex justify-between items-baseline mb-[16px] md:mb-[38px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("멤버십 구분")}
                    </p>
                    <div className="font-pretendard700">
                      {handleTypeContent()}
                    </div>
                  </div>
                </div>

                {(orderDetail?.orderProductStatus !== "WAITING_FOR_DELIVERY" ||
                  orderDetail?.orderProductStatus !==
                    "WAITING_FOR_CONFIRMATION" ||
                  orderDetail?.orderProductStatus !== "CANCEL_ORDER" ||
                  orderDetail?.orderProductStatus !==
                    "CANCEL_ORDER_AFTER_CONFIRM" ||
                  orderDetail?.orderProductStatus !==
                    "CANCEL_ORDER_AFTER_DELIVERING" ||
                  orderDetail?.orderProductStatus !==
                    "CANCEL_ORDER_AFTER_CONFIRM" ||
                  orderDetail?.orderProductStatus !==
                    "CANCEL_ORDER_AFTER_DELIVERING") && (
                  <div className="py-[40px] border-b-solid border-b-[16px] border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                    <div className="flex mb-[32px]">
                      <p className="text-[18px] font-pretendard700 text-primary leading-none">
                        {t("Shipping information")}
                      </p>
                    </div>
                    <div
                      className={`flex justify-between items-baseline ${
                        !(
                          orderDetail?.orderProductStatus ===
                            "WAITING_FOR_DELIVERY" ||
                          orderDetail?.orderProductStatus ===
                            "WAITING_FOR_CONFIRMATION"
                        ) && "mb-[20px] md:mb-[24px]"
                      }`}
                    >
                      <p className="text-[14px] font-pretendard400 text-primary leading-none">
                        {t("Shipping fee")}
                      </p>
                      <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                        {formatPrice(orderDetail?.shippingFee)}원
                      </p>
                    </div>
                    {orderDetail?.costsForJeju && (
                      <div
                        className={`flex justify-between items-baseline my-[20px] md:my-[24px]`}
                      >
                        <p className="text-[14px] font-pretendard400 text-primary leading-none">
                          {t("Additional costs for Jeju Island")}
                        </p>
                        <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                          {formatPrice(Number(orderDetail?.costsForJeju || 0))}
                          원
                        </p>
                      </div>
                    )}

                    {!(
                      orderDetail?.orderProductStatus ===
                        "WAITING_FOR_DELIVERY" ||
                      orderDetail?.orderProductStatus ===
                        "WAITING_FOR_CONFIRMATION"
                    ) && (
                      <>
                        <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                          <p className="text-[14px] font-pretendard400 text-primary leading-none">
                            {t("택배사")}
                          </p>
                          <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                            {orderDetail?.shippingUnit}
                          </p>
                        </div>
                        <div className="flex justify-between mb-[20px]  md:mb-[24px]">
                          <p className="text-[14px] font-pretendard400 text-primary leading-none">
                            {t("운송장 번호")}
                          </p>
                          <div className="mb-[8px]">
                            {orderDetail?.blNo && (
                              <div className="flex gap-[8px] mb-[12px] justify-end">
                                <p className="text-[14px] font-pretendard700 text-primary leading-none ">
                                  {orderDetail?.blNo}
                                </p>
                                <CopyToClipboard
                                  text={orderDetail?.blNo}
                                  onCopy={() => message.success("Copied")}
                                  options={{
                                    message: "Copied: " + orderDetail?.blNo,
                                  }}
                                >
                                  <img
                                    src={IconCopy}
                                    alt="copy"
                                    className="cursor-pointer"
                                  />
                                </CopyToClipboard>
                              </div>
                            )}

                            <div
                              className="cursor-pointer text-[#fff] bg-[#000] w-[100px] h-[32px] text-center ml-auto  font-pretendard400 leading-[32px]"
                              onClick={
                                () => {
                                  popupWindow(
                                    "https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%ED%83%9D%EB%B0%B0+%EC%A1%B0%ED%9A%8C",
                                    "",
                                    window,
                                    500,
                                    720,
                                  )
                                }
                                // window.open(
                                //   "https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%ED%83%9D%EB%B0%B0+%EC%A1%B0%ED%9A%8C",
                                //   "",
                                //   isMobiles.isMobile
                                //     ? ""
                                //     : "width=700,height=700",
                                // )
                              }
                            >
                              택배배송 조회
                            </div>
                          </div>
                        </div>

                        <div className="text-[#757575] text-[13px] py-[10px] px-[12px] border border-[#EAEAEA] bg-[#F7F7F7] font-pretendard400">
                          위에 기재된 택배회사를 선택 후, 운송장 번호를 복사하여
                          하단에 붙여넣기 하세요.
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div className="pt-[32px] pb-[32px] md:pb-0 border-b-solid border-b-[16px] md:border-none border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                  <div className="flex mb-[32px]">
                    <p className="text-[18px] font-pretendard700 text-primary leading-none">
                      {t("구매자 정보")}
                    </p>
                  </div>
                  <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("주문자")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.displayName}
                    </p>
                  </div>
                  <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Your address")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.addressName}{" "}
                      {orderDetail?.addressDescription}
                    </p>
                  </div>
                  {/* <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("이메일주소")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.email}
                    </p>
                  </div> */}
                  <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("휴대전화번호")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.phoneNumber}
                    </p>
                  </div>
                  <div>
                    <p className="text-[14px] font-pretendard400 text-primary leading-none mb-[10px]">
                      {t("Notes to seller")}
                    </p>
                    {orderDetail?.noteToSeller && (
                      <div className="text-[#757575] text-[14px] py-[15px] px-[20px] border border-[#EAEAEA] bg-[#F7F7F7]  font-pretendard400 whitespace-pre-line">
                        {orderDetail?.noteToSeller}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="xl:w-[480px]">
                <div className="w-full lg:bg-gray3 lg:p-[40px] md:pt-[32px] mb-[20px] bg-[#fff] px-0 pt-[0] md:border-t-[16px] border-[#F5F5F5]">
                  <div className="flex items-center justify-between pb-[12px] lg:pb-[0px] lg:mb-[60px]">
                    <p className="text-[18px] font-pretendard700 text-primary leading-none">
                      {t("결제 정보")}
                    </p>
                  </div>

                  <div className="flex items-center mb-[24px] justify-between mt-[28px] lg:mt-[40px]">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Cost price")}
                    </p>
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {formatPrice(
                        Number(orderDetail?.voucherPrice) *
                          orderDetail?.quantity,
                      ) || 0}
                      원
                    </p>
                  </div>

                  <div className="flex items-center pb-[24px] justify-between">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Discount")}
                    </p>
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {`${formatPrice(
                        100 -
                          ((Number(orderDetail?.totalPayment) -
                            Number(orderDetail?.shippingFee || 0) -
                            Number(orderDetail?.costsForJeju || 0)) /
                            orderDetail?.quantity /
                            Number(orderDetail?.voucherPrice)) *
                            100,
                      )}${t("%")}`}
                    </p>
                  </div>
                  <div className="flex items-center pb-[24px] justify-between">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Shipping fee")}
                    </p>
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {formatPrice(
                        Number(orderDetail?.shippingFee || 0) +
                          Number(orderDetail?.costsForJeju || 0),
                      )}
                      원
                    </p>
                  </div>

                  <div className="flex items-center justify-between md:mt-[0] pb-[24px]">
                    <p className="text-[16px] font-pretendard700 text-primary leading-none">
                      {t("Total payment")}
                    </p>
                    <p className="text-[16px] md:text-[24px] font-pretendard700 text-[#C70039] leading-none">
                      {formatPrice(Number(orderDetail?.totalPayment)) || 0}
                      {t("원")}
                    </p>
                  </div>

                  <div className="flex items-center pb-[24px] justify-between">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Payment Method")}
                    </p>
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {convertPayment(orderDetail?.paymentMethod)}
                    </p>
                  </div>

                  <div className="flex items-center pb-[24px] justify-between">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {t("Payment date and time")}
                    </p>
                    <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                      {dayjs(orderDetail?.createdTime).format(
                        "YYYY.MM.DD HH:mm",
                      )}
                    </p>
                  </div>

                  {(orderDetail?.orderProductStatus ===
                    "WAITING_FOR_CONFIRMATION" ||
                    orderDetail?.orderProductStatus === "CUSTOMER") && (
                    <Button
                      onClick={() => setIsPopupCancelOrder(true)}
                      className="bg-[#fff] flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                    >
                      {t("주문 취소")}
                    </Button>
                  )}
                  {(orderDetail?.orderProductStatus ===
                    "WAITING_FOR_DELIVERY" ||
                    orderDetail?.orderProductStatus === "DELIVERING" ||
                    orderDetail?.orderProductStatus === "DELIVERED") && (
                    <div className="my-[20px] flex gap-[20px]">
                      <Button
                        // disabled={orderDetail?.status === "REQUESTED"}
                        // onClick={() => setIsModalRequestRefund(true)}
                        onClick={() => {
                          if (
                            orderDetail?.status === "REQUESTED" &&
                            orderDetail?.status !== "REFUNDED"
                          ) {
                            handleRequestCancelRefund()
                          } else {
                            setIsModalRequestRefund(true)
                          }
                        }}
                        className="flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                      >
                        {orderDetail?.status === "REQUESTED"
                          ? "환불 요청 취소"
                          : "환불 요청"}
                      </Button>
                      <Button
                        onClick={() => setIsPopupCompleteOrder(true)}
                        className="flex justify-center bg-[#000] text-[#fff] text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                      >
                        {t("구매 확정하기")}
                      </Button>
                    </div>
                  )}

                  {(orderDetail?.orderProductStatus === "REFUND_REQUEST" ||
                    orderDetail?.orderProductStatus === "REFUND_REJECTED" ||
                    orderDetail?.orderProductStatus === "REFUND_APPROVED") && (
                    <Button
                      disabled
                      className="flex justify-center text-[#fff] text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 border-[#000000] border"
                    >
                      {orderDetail?.orderProductStatus === "REFUND_REQUEST" &&
                        "Refund order submitted"}
                      {orderDetail?.orderProductStatus === "REFUND_REJECTED" &&
                        "Refund order rejected"}
                      {orderDetail?.orderProductStatus === "REFUND_APPROVED" &&
                        "Refund order approved"}
                    </Button>
                  )}

                  {(orderDetail?.orderProductStatus ===
                    "WAITING_FOR_CONFIRMATION" ||
                    orderDetail?.orderProductStatus ===
                      "WAITING_FOR_DELIVERY" ||
                    orderDetail?.orderProductStatus === "DELIVERED" ||
                    orderDetail?.orderProductStatus === "DELIVERING") && (
                    <ul className="mt-[26px] list-disc pl-4">
                      <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                        {t(
                          "After the order is confirmed, refund orders require confirmation from the brand.",
                        )}
                      </li>
                      <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                        {t(
                          "‘Deliveried’ will be automatically confirmed at 0:00 on the 8th day after ‘Delivering’ (excluding Delivery Delay Days)",
                        )}
                      </li>
                      {i18n.language === "en" && (
                        <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                          {t(
                            "After pressing Complete Purchase, the order will be completed. You will not be able to request a refund after this.",
                          )}
                        </li>
                      )}
                    </ul>
                  )}
                </div>

                {(orderDetail?.orderProductStatus === "CANCEL_ORDER" ||
                  orderDetail?.orderProductStatus ===
                    "CANCEL_ORDER_AFTER_CONFIRM" ||
                  orderDetail?.orderProductStatus ===
                    "CANCEL_ORDER_AFTER_DELIVERING") && (
                  <div className="border border-[#D4D4D4] lg:py-[30px] lg:px-[40px] py-[24px] px-[20px] leading-none flex flex-col gap-[16px] lg:bg-[#fff] bg-[#F7F7F7] font-pretendard400">
                    <div className="flex justify-between text-[14px]">
                      <div className="text-[#757575]">{t("Cancel by")}</div>
                      <div className="font-pretendard700">
                        {t(orderDetail?.refundBy)}
                      </div>
                    </div>
                    <div className="flex justify-between text-[14px]">
                      <div className="text-[#757575]">{t("Submit Time")}</div>
                      <div className="font-pretendard700">
                        {moment(orderDetail?.refundedAt).format(
                          "HH시mm분 YYYY-MM-DD",
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between text-[14px]">
                      <div className="text-[#757575]">
                        {t("Refund Method2")}
                      </div>
                      <div className="font-pretendard700">
                        {orderDetail?.refundMethod}
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-[18px] font-pretendard700">
                        {t("Refund Amount2")}
                      </div>
                      <div className="font-pretendard700 text-[#539165] text-[18px]">
                        {formatPrice(orderDetail?.refundAmount)}원
                      </div>
                    </div>
                  </div>
                )}

                {(orderDetail?.orderProductStatus === "REFUND_REQUEST" ||
                  orderDetail?.orderProductStatus === "REFUND_REJECTED" ||
                  orderDetail?.orderProductStatus === "REFUND_APPROVED") && (
                  <div className="border border-[#D4D4D4] lg:py-[30px] lg:px-[40px] py-[24px] px-[20px] lg:bg-[#fff] bg-[#F7F7F7]">
                    <div className="lg:mb-[28px] mb-[16px] font-pretendard700 text-[18px]">
                      {t("환불 정보")}
                    </div>
                    <div className="flex justify-between text-[14px]">
                      <div>{t("Reason")}</div>
                      <div>{orderDetail?.refundReason}</div>
                    </div>
                    <div className="grid grid-cols-4 gap-[8.8px] pt-[16px] pb-[36px] lg:border-b-[16px] border-b lg:border-[#F5F5F5] border-[#D4D4D4]">
                      {orderDetail?.refundImages?.map(
                        (item: any, index: number) => (
                          <img
                            src={item}
                            alt="img"
                            className="w-full h-full object-cover aspect-square"
                            key={index}
                          />
                        ),
                      )}
                    </div>

                    <div className="flex justify-between mt-[36px] mb-[16px]">
                      <div className="text-[14px] text-[#757575]">
                        {t("Submit Time")}
                      </div>
                      <div className="text-[14px] font-pretendard700">
                        {orderDetail?.orderProductStatus === "REFUND_REQUEST" &&
                          moment(orderDetail?.refundRequestTime).format(
                            "HH시mm분 YYYY-MM-DD",
                          )}
                        {orderDetail?.orderProductStatus ===
                          "REFUND_REJECTED" &&
                          moment(orderDetail?.rejectedTime).format(
                            "HH시mm분 YYYY-MM-DD",
                          )}
                        {orderDetail?.orderProductStatus ===
                          "REFUND_APPROVED" &&
                          moment(orderDetail?.refundedAt).format(
                            "HH시mm분 YYYY-MM-DD",
                          )}
                      </div>
                    </div>
                    <div className="flex justify-between lg:mb-[28px] mb-[36px]">
                      <div className="text-[14px] text-[#757575]">
                        {t("Refund Method")}
                      </div>
                      <div className="text-[14px] font-pretendard700">
                        {orderDetail?.refundMethod}
                      </div>
                    </div>
                    {orderDetail?.orderProductStatus === "REFUND_REQUEST" && (
                      <div className="text-[#EE3900] text-[16px]">
                        {t("Your refund order is being reviewed.")}
                      </div>
                    )}

                    {orderDetail?.orderProductStatus === "REFUND_REJECTED" && (
                      <div className="lg:border-t-[16px] border-t lg:border-[#F5F5F5] border-t-[#D4D4D4]">
                        <div className="flex justify-between mt-[36px]">
                          <div className="text-[14px] text-[#757575]">
                            {t("Result Time")}
                          </div>
                          <div className="text-[14px] font-pretendard700">
                            {moment(orderDetail?.rejectedTime).format(
                              "HH시mm분 YYYY-MM-DD",
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between my-[16px]">
                          <div className="text-[14px] text-[#757575]">
                            {t("Result")}
                          </div>
                          <div className="text-[14px] font-pretendard700 text-[#C70039]">
                            {t("Rejected")}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="text-[14px] text-[#757575]">
                            {t("Reject reason")}
                          </div>
                          <div className="text-[14px] font-pretendard700">
                            {orderDetail?.rejectReason}
                          </div>
                        </div>
                      </div>
                    )}

                    {orderDetail?.orderProductStatus === "REFUND_APPROVED" && (
                      <div className="border-t-[16px] border-[#F5F5F5] flex flex-col gap-[16px]">
                        <div className="flex justify-between  mt-[36px]">
                          <div className="text-[14px] text-[#757575]">
                            {t("Result Time")}
                          </div>
                          <div className="text-[14px] font-pretendard700">
                            {moment(orderDetail?.refundedAt).format(
                              "HH시mm분 YYYY-MM-DD",
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="text-[14px] text-[#757575]">
                            {t("Result")}
                          </div>
                          <div className="text-[14px] font-pretendard700 text-[#539165]">
                            {t("Accepted")}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="text-[14px] text-[#757575]">
                            {t("Refund Solution")}
                          </div>
                          <div className="text-[14px] font-pretendard700">
                            {handleTextSolution(orderDetail?.solution)}
                          </div>
                        </div>
                        {orderDetail?.solution !== "REFUND_BRAND_MISTAKE" && (
                          <div className="flex justify-between">
                            <div className="text-[14px] text-[#757575]">
                              {t("Return Fee")}
                            </div>
                            <div className="text-[14px] font-pretendard700">
                              {formatPrice(orderDetail?.shippingFee)}원
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <div className="text-[18px] text-[#757575] font-pretendard700">
                            {t("Refund Amount")}
                          </div>
                          <div className="font-pretendard700 text-[#539165] text-[18px]">
                            {formatPrice(orderDetail?.refundAmount)}원
                          </div>
                        </div>

                        {orderDetail?.solution !== "REFUND_BRAND_MISTAKE" && (
                          <div className="text-[#EE3900] mt-[12px] text-[16px]">
                            {t(
                              "Your refund order has been approved. Staff member from the brand will soon contact you through phone number to instruct on how to return the product.",
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <PopupCompleteOrder
          isOpen={isPopupCompleteOrder}
          handleClose={() => setIsPopupCompleteOrder(false)}
          handleOk={handleCompleteOrder}
        />
        <PopupCancelOrder
          isOpen={isPopupCancelOrder}
          handleClose={() => setIsPopupCancelOrder(false)}
          handleOk={handleCancelOrder}
        />
        <PopupRefund
          isOpen={isModalRequestRefund}
          handleClose={() => setIsModalRequestRefund(false)}
          handleOk={handleRequestRefund}
          orderDetail={orderDetail}
        />
        <PopupRefundSuccess
          isOpen={isModalRequestRefundSuccess}
          handleClose={() => setIsModalRequestRefundSuccess(false)}
          handleOk={() => {
            getOrderDetail()
            setIsModalRequestRefundSuccess(false)
          }}
        />
        {contextHolder}
      </div>
    </Spin>
  )
}

export default OrderProductDetail
