import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import { formatPrice } from "src/util/formatPrice"

interface Props {
  isOpen: boolean
  handleClose: () => void
  data?: any
  price?: number
  onSubmit?: () => void
}

function ModalInfoTransfer({
  isOpen,
  handleClose,
  data,
  price,
  onSubmit,
}: Props) {
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseOutside
      className="modal-transfer-payment font-pretendard400 text-[#000] "
      width={450}
    >
      <div className="leading-none">
        <div className="text-[20px] text-center font-pretendard700">
          입금 정보
        </div>
        <div className="pt-[26px] pb-[32px] text-[15px]">
          <div>주문 완료를 위해 아래 계좌로 입금해주세요.</div>
          <div className="pt-[5px]">
            영업일 기준 1~3일 이내 입금 확인 후, 주문 완료가 됩니다.
          </div>
        </div>

        <div className="py-[20px] border-y border-[#D9D9D9] flex justify-between items-center">
          <div className="text-[15px]">입금할 금액</div>
          <div className="text-[20px] font-pretendard700">
            {formatPrice(price || 0)}
            {"원"}
          </div>
        </div>

        <div className="flex flex-col gap-[23px] pt-[27px] pb-[40px]">
          <div className="flex items-center justify-between">
            <div className="text-[15px]">은행명</div>
            <div className="text-[15px] font-pretendard700">
              {data?.bankAccountName}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[15px]">예금주</div>
            <div className="text-[15px] font-pretendard700">
              {data?.bankName}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[15px]">계좌번호</div>
            <div className="text-[15px] font-pretendard700">
              {data?.bankAccountNumber}
            </div>
          </div>
        </div>

        <Button
          theme="main"
          variant={"primary"}
          labelSize={16}
          height={56}
          onClick={onSubmit}
        >
          확인
        </Button>
      </div>
    </CommonModal>
  )
}

export default ModalInfoTransfer
